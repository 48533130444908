import { ControlsActions } from 'calls/enums/index.js';
import translate from 'i18n-translations/translate.jsx';

const healthCareCdnUrl = 'https://static.solaborate.com/healthcare-system/';

export const CallTypes = {
	AUDIO: 1,
	VIDEO: 2,
	SCREENSHARE: 3,
	SECURITY_CAM: 4,
	LIVEBROADCAST: 5,
	MONITORING: 6,
	FIRST_RESPONDER: 7,
	AMBIENT_AND_VIDEO: 97,
	AMBIENT: 98,
	QUEUEING: 99,
};

export const ScreenTypes = {
	LAPTOP: { type: 1, isSmall: false },
	TABLET: { type: 2, isSmall: true },
	MOBILE: { type: 3, isSmall: true },
};

export const WindowSize = {
	LAPTOP: 1024,
	TABLET: 768,
	MOBILE: 425,
};

export const SectorTypes = {
	HEALTH_SYSTEM: 'healthSystem',
	REGION: 'region',
	HOSPITAL: 'hospital',
	DEPARTMENT: 'department',
	FLOOR: 'floor',
	ROOM: 'room',
	COMPANY: 'company',
};

export const PresenceStatusType = {
	AVAILABLE: 1,
	UNAVAILABLE: 2,
};

export const HarkEvents = {
	STOPPED_SPEAKING: 'stopped_speaking',
	VOLUME_CHANGE: 'volume_change',
};

export const CallStatuses = {
	ACCEPTED: 0,
	DECLINED: 0,
};

export const MediaInputs = {
	AUDIO_INPUT: 'audioinput',
	VIDEO_INPUT: 'videoinput',
};

export const MediaTypes = {
	CAMERA: 'camera',
	MICROPHONE: 'microphone',
};

export const MediaPermissions = {
	DENIED: 'denied',
	GRANTED: 'granted',
	PROMPT: 'prompt',
};

export const UserTypes = {
	DOCTOR: 1,
	NURSE: 2,
	PATIENT: 3,
	ADMIN: 4,
	SUPER_USER: 5,
	SUPER_ADMIN: 6,
	OWNER: 7,
	DIGITAL_CLINICIAN: 8,
	VISITOR: 9,
	VIRTUAL_SITTER: 10,
	ROOM_SIGN: 97,
	SUPPORT: 98,
	GUEST: 99,
};

export const CompanyRoles = {
	OWNER: 1,
	ADMIN: 2,
	MEMBER: 3,
	GUEST: 4,
	SUPPORT: 5,
	VIRTUAL_CARE_PROVIDER: 6,
	VIRTUAL_SITTER: 7,
	SUPER_USER: 8,
	SUPER_ADMIN: 9,
	DIGITAL_CLINICIAN: 10,
	DOCTOR: 11,
	VISITOR: 12,
	PATIENT: 13,
	VIRTUAL_PATIENT: 14,
};

export const UserRoles = {
	DOCTOR: 'Doctor',
	NURSE: 'Nurse',
	PATIENT: 'Patient',
	ADMIN: 'Admin',
	SUPER_USER: 'SuperUser',
	SUPER_ADMIN: 'SuperAdmin',
	OWNER: 'Owner',
	DIGITAL_CLINICIAN: 'DigitalClinician',
	GUEST: 'Guest',
	VIRTUAL_SITTER: 'VirtualSitter',
	VISITOR: 'Visitor',
	ROOM_SIGN: 'RoomSign',
};

export const UserRoleIds = {
	USER: 1,
	GUEST: 2,
	ADMIN: 3,
	VCP: 4,
	VIRTUAL_SITTER: 5,
	SUPER_USER: 6,
	ACTIVE_DIRECTORY_INVITE: 7,
	SUPER_ADMIN: 8,
	DIGITAL_CLINICIAN: 9,
	DOCTOR: 10,
};

export const CompanyRoleObjects = [
	{ id: CompanyRoles.DOCTOR, role: UserRoles.DOCTOR },
	{ id: CompanyRoles.VIRTUAL_CARE_PROVIDER, role: UserRoles.NURSE },
	{ id: CompanyRoles.DIGITAL_CLINICIAN, role: UserRoles.DIGITAL_CLINICIAN },
	{ id: CompanyRoles.PATIENT, role: UserRoles.PATIENT },
	{ id: CompanyRoles.ADMIN, role: UserRoles.ADMIN },
	{ id: CompanyRoles.SUPER_USER, role: UserRoles.SUPER_USER },
	{ id: CompanyRoles.SUPER_ADMIN, role: UserRoles.SUPER_ADMIN },
	{ id: CompanyRoles.DIGITAL_CLINICIAN, role: UserRoles.DIGITAL_CLINICIAN },
	{ id: CompanyRoles.VIRTUAL_SITTER, role: UserRoles.VIRTUAL_SITTER },
	{ id: CompanyRoles.VISITOR, role: UserRoles.VISITOR },
	{ id: CompanyRoles.GUEST, role: UserRoles.GUEST },
];

export const Roles = [
	{ id: UserTypes.DOCTOR, role: UserRoles.DOCTOR },
	{ id: UserTypes.NURSE, role: UserRoles.NURSE },
	{ id: UserTypes.DIGITAL_CLINICIAN, role: UserRoles.DIGITAL_CLINICIAN },
	{ id: UserTypes.PATIENT, role: UserRoles.PATIENT },
	{ id: UserTypes.ADMIN, role: UserRoles.ADMIN },
	{ id: UserTypes.SUPER_USER, role: UserRoles.SUPER_USER },
	{ id: UserTypes.SUPER_ADMIN, role: UserRoles.SUPER_ADMIN },
	{ id: UserTypes.OWNER, role: UserRoles.OWNER },
	{ id: UserTypes.DIGITAL_CLINICIAN, role: UserRoles.DIGITAL_CLINICIAN },
	{ id: UserTypes.VIRTUAL_SITTER, role: UserRoles.VIRTUAL_SITTER },
	{ id: UserTypes.VISITOR, role: UserRoles.VISITOR },
	{ id: UserTypes.GUEST, role: UserRoles.GUEST },
];

export const InvitedRoles = [
	{ id: UserRoleIds.DOCTOR, role: UserRoles.DOCTOR },
	{ id: UserRoleIds.NURSE, role: UserRoles.NURSE },
	{ id: UserRoleIds.DIGITAL_CLINICIAN, role: UserRoles.DIGITAL_CLINICIAN },
	{ id: UserRoleIds.PATIENT, role: UserRoles.PATIENT },
	{ id: UserRoleIds.ADMIN, role: UserRoles.ADMIN },
	{ id: UserRoleIds.SUPER_USER, role: UserRoles.SUPER_USER },
	{ id: UserRoleIds.SUPER_ADMIN, role: UserRoles.SUPER_ADMIN },
	{ id: UserRoleIds.OWNER, role: UserRoles.OWNER },
];

export const ParticipantState = {
	CONNECTING: {
		type: 0,
		message: 'Connecting',
	},
	CONNECTED: {
		type: 1,
		message: 'Connected',
	},
	BUSY: {
		type: 2,
		message: 'Busy',
	},
	DECLINED: {
		type: 3,
		message: 'Declined',
	},
	OFFLINE: {
		type: 4,
		message: 'Offline',
	},
	LEFT_CALL: {
		type: 5,
		message: 'Left the call',
	},
	NOT_ANSWERING: {
		type: 6,
		message: 'Not answering',
	},
	CANT_CONNECT: {
		type: 7,
		message: 'Cant connect',
	},
	DISRUPTED: {
		type: 8,
		message: 'Disrupted',
	},
	RECONNECTING: {
		type: 9,
		message: 'Reconnecting',
	},
	DISCONNECTED: {
		type: 10,
		message: 'Disconnected',
	},
	REMOVED: {
		type: 11,
		message: 'Removed',
	},
	FAILED_TO_GET_INFO: {
		type: 98,
		message: 'Failed to get info',
	},
	INVITE_DENIED: {
		type: 99,
		message: 'Invite denied',
	},
};

export const CallStatus = {
	UNDEFINED: {
		type: 0,
		message: 'Undefined',
	},
	SUCCESSFUL: {
		type: 1,
		message: 'Successful',
	},
	PARTIALLY_SUCCESSFUL: {
		type: 2,
		message: 'PartiallySuccessful',
	},
	FAILED: {
		type: 3,
		message: 'Failed',
	},
	DISRUPTED: {
		type: 4,
		message: 'Disrupted',
	},
	PARTIALLY_DISRUPTED: {
		type: 5,
		message: 'Partially Disrupted',
	},
	MISSED: {
		type: 6,
		message: 'Missed',
	},
	ABORTED: {
		type: 7,
		message: 'Aborted',
	},
	DROPPED: {
		type: 8,
		message: 'Dropped',
	},
};

export const SerialTVCommands = {
	INITIAL_TV_POWER: 0,
	POWER: {
		POWER_ON: 1,
		POWER_OFF: 2,
	},
	HDMI: {
		SWITCH_HDMI1: 3,
		SWITCH_HDMI2: 4,
		SWITCH_HDMI3: 5,
	},
};

export const ObjectType = {
	USER: 0,
	HELLO_DEVICE: 1,
	COMPANION_DEVICE: 2,
	COMPANY: 3,
	SYSTEM: 5,
	SIP_USER: 999,
};

export const ZoomDirection = {
	INCREASE: '+',
	DECREASE: '-',
	RESET: 0,
};

export const CameraTiltDirection = {
	UP: '1',
	DOWN: '2',
	RIGHT: '3',
	LEFT: '4',
};

export const CameraTiltAction = {
	START: 'start',
	STOP: 'stop',
};

export const StreamError = {
	SCREEN_SHARE_CANCELED: {
		type: 0,
		message: 'Screen share canceled',
	},
	CANT_ACCESS_MEDIA_STREAM: {
		type: 1,
		message: `Can't access media stream`,
	},
	BROWSER_NOT_SUPPORTED: {
		type: 2,
		message: 'Browser not supported',
	},
	EXTENSION_NOT_INSTALLED: {
		type: 3,
		message: 'Extension not installed',
	},
	DEVICE_NOT_FOUND: {
		type: 4,
		message: 'Device not found',
	},
	STREAM_NOT_ALLOWED: {
		type: 5,
		message: 'Stream not allowed',
	},
	PERMISSION_DISMISSED: {
		type: 6,
		message: 'Permission dismissed',
	},
	NOT_READABLE_ERROR: {
		type: 7,
		message: 'Not readable error',
	},
	ONLY_SCREEN_FOUND: {
		type: 8,
		message: 'Only screen found',
	},
	SCREEN_SHARE_NOT_SUPPORTED: {
		type: 9,
		message: 'Screen share not supported',
	},
	CAMERA_BLOCKED: {
		type: 10,
		message: 'Camera permission is blocked',
	},
	MICROPHONE_BLOCKED: {
		type: 11,
		message: 'Microphone permission is blocked',
	},
	CAM_AND_MIC_BLOCKED: {
		type: 12,
		message: 'Camera and microphone permission is blocked',
	},
	MICROPHONE_NOT_FOUND: {
		type: 13,
		message: 'Microphone not found',
	},
	CAMERA_NOT_FOUND: {
		type: 14,
		message: 'Camera not found',
	},
	MICROPHONE_BLOCKED_GENERIC: {
		type: 15,
		message: 'Microphone permission is blocked',
	},
	CAMERA_BLOCKED_GENERIC: {
		type: 16,
		message: 'Camera permission is blocked',
	},
};

export const SocketState = {
	CONNECTED: {
		type: 0,
		message: 'Connected',
	},
	RECONNECTING: {
		type: 1,
		message: 'Reconnecting...',
	},
	DISCONNECTED: {
		type: 2,
		message: 'Connection is lost',
	},
};

export const DeviceCommands = {
	REBOOT: 0,
	SHUTDOWN: 1,
	ENABLE_METRICS: 2,
	FACTORY_RESET: 3,
	FORCE_UPDATE: 4,
	PULL_LOGS: 5,
	REBOOT_HUDDLE_CAM: 6,
	SWITCH_AUDIO_OUTPUT: 7 /* We don't care about this for now */,
	PAIR_UNAPIR_REMOTE: 8,
	CLEAR_DATA: 9,
	REQUEST_ALLOWLIST: 10,
};

export const CameraType = {
	HELLO: 'Hello',
	HUDDLE: 'Huddle',
};

export const CameraZoomLevels = {
	NO_ZOOM: 1,
	HELLO_MAX: 100,
	HUDDLE_MAX: 400,
};

export const CameraEventTypes = {
	ZOOM: 'zoom',
	SWITCH: 'switch',
	TILT: 'tilt',
	HUDDLE_CONNECTED_STATE: 'huddleCamConnectionState',
	NIGHT_VISION: 'nightVision',
	CAMERA_PRIVACY_STATE: 'helloCameraPrivacyState',
	HELLO_MIC_PRIVACY_STATE: 'helloMicPrivacyState',
};

export const DeviceStatus = {
	ONLINE: 'online',
	OFFLINE: 'offline',
};

export const DeviceAvailability = {
	ONLINE: 1,
	OFFLINE: 0,
};

export const DeviceListLevel = {
	HEALTH_SYSTEM: 0,
	REGION: 1,
	HOSPITAL: 2,
	DEPARTMENT: 3,
	FLOOR: 4,
	ROOM: 5,
	ORGANIZATION: 6,
};

export const DeviceStatusMessages = {
	deviceOnCall: translate('deviceOnCall'),
	deviceOnCallWithNurse: translate('deviceOnCallWithNurse'),
	deviceOffline: translate('deviceOffline'),
	initiatingMonitoring: translate('initiatingMonitoring'),
	notAnswering: translate('notAnswering'),
	leftCall: translate('leftCall'),
	privacyModeOn: translate('privacyModeOn'),
	disconnected: translate('disconnected'),
	reconnecting: translate('reconnecting'),
	removed: translate('sessionEndedByAdmin'),
	disconnectedByCall: translate('disconnectedByCall'),
	inviteDenied: translate('conferenceEndedParticipantInviteDenied'),
	maximumNumberOfMonitoringSessions: translate('maximumNumberOfMonitoringSessions'),
	failedToGetInfo: translate('failedToGetParticipantInfo'),
};

export const FeedColors = {
	red: '#e84118',
	blue: '#5390FF',
	orange: '#ff9f43',
	yellow: '#fbc531',
	pink: '#f368e0',
	black: '#000',
	cyan: '#6BDCFF',
	purple: '#852FC8',
	darkBlue: '#5e75aa',
	gray: '#d3d3d3',
	lightBlue: '#add8e6',
};

// messenger

export const AttachmentType = {
	DOCUMENT: 0,
	PICTURE: 1,
	VIDEO: 2,
};

export const ConversationItemsType = {
	MESSAGE: 0,
	EVENT: 5,
	EVENTCALL: 3,
	UPCOMING_APPOINTMENT: 18,
	WALKIE_TALKIE: 19,
	ADAPTIVE_CARD: 20,
};

export const DetectionModes = {
	BABY: 'baby',
	PERSON: 'person',
	COMBINE: 'combine',
};

export const AiDetectionTypes = {
	OBJECT: 3,
	BABY: 4,
	PATIENT: 5,
	RAILS: 6,
	SKELETON: 7,
	SURGICAL_TOOLS: 8,
	ACTION_RECOGNITION: 9,
	PRIVACY: 10,
	FALL_ALERT: 11,
	_FaceDetection: 12, // Used By Hello
	_IoTMeasurements: 13, // Used By Hello
	_ScreamingIt: 14, // Used By Hello
	_Beamforming: 15, // Used By Hello
	PRIVACY_AND_SKELETON: 16,
	GETTING_OUT_OF_BED: 17,
	PERSON_FACE_DOWN: 18,
};

export const AiDetectionsEnum = {
	[ControlsActions.TOGGLE_AI_OBJECT_DETECTION]: 3,
	[ControlsActions.TOGGLE_AI_BABY_DETECTION]: 4,
	[ControlsActions.TOGGLE_AI_PERSON_DETECTION]: 5,
	[ControlsActions.TOGGLE_AI_RAILS_DETECTION]: 6,
	[ControlsActions.TOGGLE_AI_SKELETON_DETECTION]: 7,
	[ControlsActions.TOGGLE_MULTI_SKELETON_DETECTION]: 9,
	[ControlsActions.TOGGLE_PRIVATE_MODE]: 10,
};

export const RecurrenceType = {
	DAILY: 'DAILY',
	WEEKLY: 'WEEKLY',
	MONTHLY: 'MONTHLY',
	YEARLY: 'YEARLY',
	WORKDAYS: 'WORKDAYS',
};

export const AlertTypes = {
	DANGER: 'danger',
	WARNING: 'warning',
	SUCCESS: 'success',
	INFORMATION: 'information',
};

export const InviteStatuses = {
	SENT: {
		type: 1,
		message: 'Sent',
	},
	ACCEPTED: {
		type: 2,
		message: 'Accepted',
	},
	PENDING: {
		type: 3,
		message: 'Pending',
	},
	CANCELLED: {
		type: 4,
		message: 'Cancelled',
	},
	FAILED: {
		type: 5,
		message: 'Failed',
	},
};

export const InviteTypes = {
	USER: {
		type: 1,
		message: 'user',
	},
	GUEST: {
		type: 2,
		message: 'guest',
	},
	ADMIN: {
		type: 3,
		message: 'adminOnList',
	},
	VIRTUAL_CARE_PROVIDER: {
		type: 4,
		message: 'virtualCareProvider',
	},
	VIRTUAL_SITTER: {
		type: 5,
		message: 'virtualSitterOnList',
	},
	SUPER_USER: {
		type: 6,
		message: 'superUserOnList',
	},
	ACTIVE_DIRECTORY_INVITE: {
		type: 7,
		message: 'activeDirectoryInvite',
	},
	SUPER_ADMIN: {
		type: 8,
		message: 'superAdminOnList',
	},
	DIGITAL_CLINICIAN: {
		type: 9,
		message: 'digitalClinicianOnList',
	},
	DOCTOR: {
		type: 10,
		message: 'doctorOnList',
	},
	FAMILY_MEMBER: {
		type: 11,
		message: 'familyMember',
	},
	VISITOR: {
		type: 12,
		message: 'visitor',
	},
};

export const Specialties = {
	NURSE_PRACTITIONER: 1,
	EMERGENCY_MEDICINE_PHYSICIAN: 2,
	FAMILY_PHYSICIAN: 3,
	HOSPITALIST: 4,
	INTERNIST: 5,
	PEDIATRICIAN: 6,
	PSYCHIATRIST: 7,
	CHILD_PSYCHOLOGIST: 8,
	COUNSELOR: 9,
	PSYCHOLOGIST: 10,
	PSYCHOTHERAPIST: 11,
	OTHER: 12,
};

export const NotificationTypes = {
	ASSIGNED_REQUEST: 21,
	REQUEST_ACCEPTED: 22,
	REQUEST_REJECTED: 23,
	REQUEST_CANCELED: 24,
	APPOINTMENT_CREATED: 25,
	APPOINTMENT_UPDATED: 26,
	APPOINTMENT_RESCHEDULED: 27,
	APPOINTMENT_STATUS_CHANGED: 28,
	APPOINTMENT_REMINDER: 29,
	PATIENT_PRESCRIPTION_CREATED: 30,
	PATIENT_PRESCRIPTION_UPDATED: 31,
	PATIENT_PRESCRIPTION_DELETED: 32,
	PATIENT_PRESCRIPTION_DISABLED: 33,
	PATIENT_PRESCRIPTION_MISSED: 34,
};

export const MeasurementTypes = {
	HEART_RATE: 'heartRate',
	OXYGEN: 'oxygen',
	PI: 'pi',
	DIABETES: 'diabetes',
	BLOOD_PRESSURE: 'bloodPressure',
	WEIGHT: 'weight',
	TEMPERATURE: 'temperature',
	SLEEP: 'sleepAnalysis',
	ELECTROCARDIOGRAPHY: 'electrocardiography', // not defined yet
	BLOOD_GLUCOSE: 'bloodGlucose',
	STETHOSCOPE: 'stethoscope',
	DISTANCE_WALKING_RUNNING: 'distanceWalkingRunning',
	FLIGHTS_CLIMBED: 'flightsClimbed',
	STEPS: 'steps',
	LEAN_BODY_MASS: 'leanBodyMass',
	BODY_FAT: 'bodyFat',
	BODY_MASS_INDEX: 'bodyMassIndex',
	FALLEN_TIMES: 'numberOfTimesFallen',
	RESPIRATORY_RATE: 'respiratoryRate',
	HEIGHT: 'height',
	STRESS_LEVEL: 'stressLevel',
	HRV_INDEX: 'hrv',
	BILIRUBIN: 'bilirubin',
	MUSCLE_MASS: 'muscleMass',
	VISCERAL_FAT_GRADE: 'visceralFatGrade',
	BODY_WATER: 'bodyWater',
	HDL_CHOLESTEROL: 'hdlCholesterol',
	TOTAL_CHOLESTEROL: 'totalCholesterol',
	WAIST_CIRCUMFERENCE: 'waistCircumference',
	STETHO: 'stetho',
	EAR: 'ear',
	LUNGS: 'lungs',
	HEART: 'heart',
	THROAT: 'throat',
	SKIN: 'skin',
	ECG: 'ecg',
	ABDOMEN: 'abdomen',
	FACIAL_SKIN_AGE: 'facialSkinAge',
	CARDIAC_WORKLOAD: 'cardiacWorkload',
	HEART_ATTACK_RISK: 'heartAttackRisk',
	STROKE_RISK: 'strokeRisk',
	STROKE_VOLUME: 'strokeVolume',
	SYSTEMIC_VASCULAR_RESISTANCE: 'systemicVascularResistance',
	CARDIAC_OUTPUT: 'cardiacOutput',
	CARDIAC_INDEX: 'cardiacIndex',
	SPIROMETER: 'spirometry',
	SKIN_TEMPERATURE: 'skinTemperature',
	OXYGEN_SATURATION: 'oxygenSaturation',
	HEART_LUNGS_AUSCULTATION: 'heartLungsAuscultation',
	OTOSCOPE: 'otoscope',
	DERMATOSCOPE: 'dermatoscope',
};

export const WarningIndexes = {
	LOW: 1,
	EXTREMELY_LOW: 2,
	HIGH: 3,
	EXTREMELY_HIGH: 4,
};

export const AiAnalyticsType = {
	LYING_FRONT: 'lyingFront',
	LYING_SIDE: 'lyingSide',
	LYING_DOWN: 'lyingDown',
	SITTING: 'sitting',
	WALKING: 'walking',
	IV_BAG_FLUID_LEVEL: 'ivBagFluidLevel',
};

export const AiAnalyticsTypeId = {
	BED_LYING_FRONT: 1,
	BED_LYING_SIDE: 2,
	BED_LYING_DOWN: 3,
	BED_SITTING: 4,
	WALKING: 5,
	IV_BAG_FLUID_LEVEL: 6,
	BED_LYING_LEFT: 7,
	BED_LYING_RIGHT: 8,
	CHAIR_LYING_FRONT: 9,
	CHAIR_LYING_SIDE: 10,
	CHAIR_LYING_DOWN: 11,
	CHAIR_SITTING: 12,
	CHAIR_LYING_LEFT: 13,
	CHAIR_LYING_RIGHT: 14,
};

export const AiAlertType = {
	RAILS: 'railDown',
	PATIENT_SITTING: 'patientSitting',
	PATIENT_STANDING: 'patientStanding',
	PATIENT_FACE_DOWN: 'patientFaceDown',
	PATIENT_LYING_SIDE: 'patientLyingSide',
	PATIENT_LYING_FRONT: 'patientLyingFront',
	PATIENT_LYING_RIGHT_SIDE: 'patientLyingRightSide',
	FALL_DETECTION: 'fallDetected',
	PATIENT_INACTIVE: 'patientInactive',
	PATIENT_GETTING_OUT_OF_BED: 'patientGettingOutOfBed',
	PATIENT_MOBILITY: 'patientStandingWalking',
	PATIENT_LYING_LEFT_SIDE: 'patientLyingLeftSide',
	HANDS_DISINFECTED: 'handsDisinfected',
	HANDS_NOT_DISINFECTED: 'handsNotDisinfected',
	IV_BAG: 'ivBagFluidLevel',
	LOW_IV_BAG: 'lowIvBagFluidLevel',
	PATIENT_GETTING_OUT_OF_CHAIR: 'patientGettingOutOfChair',
	PATIENT_INACTIVE_INFORMATION: 'patientInactiveInformation',
	SNEEZING: 'sneezing',
	SNORING: 'snoring',
	COUGHING: 'coughing',
	CRYING_BABY: 'cryingBaby',
	SCREAMING: 'screaming',
	HELP: 'help',
	LOW_PRIORITY_ALARM: 'lowPriorityAlarm',
	MEDIUM_PRIORITY_ALARM: 'mediumPriorityAlarm',
	HIGH_PRIORITY_ALARM: 'highPriorityAlarm',
	ALARM_OFF: 'alarmOff',
	VITAL_SIGN_ALARM: 'vitalSignAlarm',
};

export const DetectionTypes = {
	RAIL: 'rail',
	BED: 'bed',
	BABY: 'baby',
	PERSON: 'person',
	COMBINE: 'combine',
	FALL: 'fallDetected',
	SURGICAL_TOOLS_MISSING: 'surgicalToolsMissing',
	RAILS: 'railDown',
	PERSON_SITTING: 'personSitting',
	PERSON_STANDING: 'personStanding',
	PERSON_LAYING_RIGHT_SIDE: 'babyFaceDown',
	PERSON_FACE_DOWN: 'personFaceDown',
	BABY_EXITS_CRIB: 'babyExitsCrib',
	SKELETON_AND_PRIVACY: 'skeletonAndPrivacy',
	FACE_DETECTION: 'faceDetection',
	HANDS: 'hands',
	DISINFECTING_HANDS: 'disinfectingHands',
	IV_BAG_FLUID_LEVEL: 'IvBagFluidLevel',
	MOBILITY_SCORE: 'MobilityScore',
};

export const RequestStatus = {
	ALL: 0,
	ACCEPTED: 1,
	PENDING: 2,
	REJECTED: 3,
	CANCELED: 4,
	UNASSIGNED: 5,
	COMPLETED: 6,
};

export const RequestsStatusNames = {
	ACCEPTED: 'acceptedRequests',
	PENDING: 'pendingRequests',
	REJECTED: 'rejectedRequests',
};

export const HttpStatusCodes = {
	OK: 200,
	CREATED: 201,
	ACCEPTED: 202,
	NO_CONTENT: 204,
	SEE_OTHER: 303,
	NOT_MODIFIED: 304,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	CONFLICT: 409,
};

export const CalendarActions = {
	AUTHORIZE: 1,
	UNAUTHORIZE: 2,
};

export const RTCPeerConnectionEnum = {
	CONNECTION_STATE: {
		NEW: 'new',
		CONNECTING: 'connecting',
		CONNECTED: 'connected',
		DISCONNECTED: 'disconnected',
		FAILED: 'failed',
		CLOSED: 'closed',
	},
	SIGNALING_STATE: {
		STABLE: 'stable',
		HAVE_LOCAL_OFFER: 'have-local-offer',
		HAVE_REMOTE_OFFER: 'have-remote-offer',
		HAVE_LOCAL_PRANSWER: 'have-local-pranswer',
		HAVE_REMOTE_PRANSWER: 'have-remote-pranswer',
		CLOSED: 'closed',
	},
	ICE_CONNECTION_STATE: {
		NEW: 'new',
		CHECKING: 'checking',
		CONNECTED: 'connected',
		COMPLETED: 'completed',
		FAILED: 'failed',
		DISCONNECTED: 'disconnected',
		CLOSED: 'closed',
	},
	ICE_GATHERING_STATE: {
		NEW: 'new',
		GATHERING: 'gathering',
		COMPLETE: 'complete',
	},
};

export const ConferenceErrorType = {
	SIGNALING_ERROR: 0, // failures or errors during the signaling procedure
	MEDIA_SOURCE_ERROR: 1, // failure to get access to media device (mic, camera, screen-capture)
	SDP_GENERATION_ERROR: 2, // SDP generation error occur either when generating an offer or answer with incorrect configuration parameters
	NEGOTIATION_FAILURE: 3, // negotiation errors during SDP offer/answer, they mainly occur due ti differing media and network configuration between endpoints
	ICE_CONNECTION_FAILURE: 4, // ICE failures occur when NAT/firewall traversal fails
	TRANSPORT_FAILURE: 5, // call drops after the connection establishment
};

export const JoinConferenceFailureReasonEnum = {
	UNHANDLED_EXCEPTION: 'joinConferenceFailureReason.unhandledException',
	NULL_CONFERENCE: 'joinConferenceFailureReason.nullConference',
	WRONG_PARTICIPANT_STATE: 'joinConferenceFailureReason.wrongParticipantState',
	FAILED_TO_GET_PARTICIPANT_INFO: 'joinConferenceFailureReason.failedToGetParticipantInfo',
};

export const ParticipantRemoveReason = {
	UNDEFINED: 0,
	PARTICIPANT_LEAVE: 1,
	DISCONNECTED: 2,
	PARTICIPANT_FORCED_LEAVE: 3,
	CLEAR_PARTICIPANT_OLD_CONFERENCES: 4,
	PARTICIPANT_CLEANUP: 5,
	PASSWORD_CHANGED: 6,
	IDLE: 7,
	CONFERENCE_TERMINATED_BY_ADMINISTRATOR: 8,
	DISCONNECTED_PARTICIPANT_CLEANUP: 9,
	SOMEONE_ELSE_ANSWERED: 10,
	DISCONNECTED_BY_CALL: 11,
	FAILED_TO_GET_INFO: 12,
};

export const ConferenceEndReason = {
	UNDEFINED: 0,
	PARTICIPANT_OFFLINE: 1,
	PARTICIPANT_BUSY: 2,
	INITIATOR_LEFT: 3,
	PARTICIPANT_NOT_ANSWERING: 4,
	PARTICIPANT_DECLINED: 5,
	PARTICIPANT_LEFT: 6,
	OWNER_LEFT: 7,
	ABORTED: 8,
	DROPPED: 9,
	PARTICIPANT_PASSWORD_CHANGED: 10,
	PARTICIPANT_IDLE: 11,
	TERMINATED_BY_ADMINISTRATOR: 12,
	PARTICIPANT_INVITE_DENIED: 13,
	PARTICIPANT_INVITE_FAILED: 14,
	FAILED_TO_GET_INITIATOR_INFO: 991,
	REMOVED: 992,
	UNAUTHORIZED: 993,
	E2EE_FAILED: 994,
	ANSWERED_ELSEWHERE: 995,
	TRANSFERRED_TO_ANOTHER_CLIENT: 996,
	HAS_ACTIVE_CONFERENCE: 997,
	INIATOR_BUSY: 998,
	CLEANUP: 999,
};

export const ConferenceEndReasonMessage = {
	[ConferenceEndReason.UNDEFINED]: translate('callEnded'),
	[ConferenceEndReason.PARTICIPANT_OFFLINE]: translate('deviceOfflineTryLater'),
	[ConferenceEndReason.PARTICIPANT_BUSY]: translate('deviceOnCallTryLater'),
	[ConferenceEndReason.INITIATOR_LEFT]: translate('callEnded'),
	[ConferenceEndReason.PARTICIPANT_NOT_ANSWERING]: translate('cannotConnect'),
	[ConferenceEndReason.PARTICIPANT_DECLINED]: translate('callDeclined'),
	[ConferenceEndReason.PARTICIPANT_LEFT]: translate('callEnded'),
	[ConferenceEndReason.OWNER_LEFT]: translate('callEnded'),
	[ConferenceEndReason.ABORTED]: translate('callEndedTryAgain'),
	[ConferenceEndReason.DROPPED]: translate('failedReconnect'),
	[ConferenceEndReason.PARTICIPANT_IDLE]: translate('idleCallEnded'),
};

export const TerminateRequestFailureReasonEnum = {
	UNHANDLED_EXCEPTION: 'terminateRequestFailureReason.unhandledException',
	NULL_CONFERENCE: 'terminateRequestFailureReason.nullConference',
	TERMINATE_IN_PROGRESS: 'terminateRequestFailureReason.terminateInProgress',
	NULL_TERMINATE_REQUEST: 'terminateRequestFailureReason.nullTerminateRequest',
	NULL_CONNECTED_USER_PARTICIPANT: 'terminateRequestFailureReason.nullConnectedUserParticipant',
	NO_PERMISSION: 'terminateRequestFailureReason.noPermission',
};

export const MeasurementIcons = {
	APPLE_HEALTH: `${healthCareCdnUrl}measurements-icons/apple-health-kit.svg`,
	GLUCOMETER: `${healthCareCdnUrl}measurements-icons/glucometer.svg`,
	IHEALTH_NEXUS: `${healthCareCdnUrl}measurements-icons/ihealth-nexus.svg`,
	IHEALTH_WAVE: `${healthCareCdnUrl}measurements-icons/ihealth-wave.svg`,
	INFRARED_EAR_THERMOMETER: `${healthCareCdnUrl}live-examination/thermometer.svg`,
	IPHONE: `${healthCareCdnUrl}measurements-icons/iphone.svg`,
	LITTMANN_STETHOSCOPE: `${healthCareCdnUrl}measurements-icons/litmann-stethoscope.svg`,
	MANUALLY_ADDED: `${healthCareCdnUrl}measurements-icons/manually-added.svg`,
	MINTTI_STETHOSCOPE: `${healthCareCdnUrl}measurements-icons/mintti-stethoscope.svg`,
	PULSE_OXIMETER: `${healthCareCdnUrl}measurements-icons/pulse-oximeter.svg`,
	SAMSUNG_HEALTH: `${healthCareCdnUrl}measurements-icons/samsung-health.svg`,
	SMART_BLOOD_PRESSURE: `${healthCareCdnUrl}measurements-icons/smart-blood-pressure.svg`,
	UNKNOWN_SOURCE: `${healthCareCdnUrl}measurements-icons/unknows-source.svg`,
	SAMSUNG_WATCH: `${healthCareCdnUrl}measurements-icons/samsung-watch.svg`,
	VITAL_KIT: `${healthCareCdnUrl}measurements-icons/vital-kit.svg`,
	WEIGHT_SCALE: `${healthCareCdnUrl}live-examination/weight.svg`,
	STETHOSCOPE: `${healthCareCdnUrl}live-examination/stethoscope.svg`,
	APPLE_WATCH: `${healthCareCdnUrl}measurements-icons/apple-watch.svg`,
};

export const MeasurementDevices = {
	FITNESS_TRACKER: 'FitnessTracker',
	BODY_FAT_SCALE: 'BodyFatScale',
	WEIGHT_SCALE: 'WeightScale',
	BLOOD_PRESSURE: 'BloodPressure',
	SMART_GLUCO_METER: 'SmartGlucoMeter',
	INFRARED_EAR_THERMOMETER: 'InfraredEarThermometer',
	THERMOMETER: 'Thermometer',
	PULSE_OXIMETER: 'PulseOximeter',
	OTOSCOPE: 'Otoscope',
	STETHOSCOPE: 'Stethoscope',
	DERMATOSCOPE: 'Dermatoscope',
	CHECK_ME_PRO: 'CheckMePro',
	GLUCOMETER: 'Glucometer',
	IPHONE: 'iPhone',
	SAMSUNG_WATCH: 'smartWatch',
	VITAL_KIT: 'vitalKit',
	APPLE_WATCH: 'AppleWatch',
};

export const IotDeviceState = {
	CONNECTED: 1,
	CONNECTING: 2,
	DISCONNECTED: 3,
	MEASURING: 4,
	COMPLETED: 5,
	COULD_NOT_GET_PATIENT_DATA: 6,
	CONNECTION_FAIL: 7,
	RECONNECT: 8,
	STRIP_REMOVED: 9,
	READER_PROBLEM: 10,
	COMMUNICATION_ERROR: 11,
	PUT_NEW_STRIP: 12,
	STRIP_PROBLEM: 13,
	STRIP_OUT: 14,
	STRIP_IN: 15,
	GLUCOMETER_HIGH_TEMP: 16,
	GLUCOMETER_LOW_TEMP: 17,
	BLOOD_PRESSURE_ERR: 18,
	BLOOD_PRESSURE_PRESSURE_ERROR: 19,
	BLOOD_PRESSURE_WAIT: 20,
	DEVICE_ALREADY_CONNECTED: 21,
	SCAN_FAILED: 22,
	STAND_ON_SCALE: 23,
	STAND_ON_SCALE_TOURIST: 24,
	MISSING_PATIENT_INFO: 25,
	VITAL_KIT_AUTHORIZED: 26,
	SHOES_ON: 27,
	UNREALISTIC_WEIGHT: 28,
	MISSING_HEIGHT: 29,
	MISSING_WEIGHT: 30,
	MISSING_GENDER: 31,
	TRYING_TO_CONNECT: 32,
	SCAN_NOT_FOUND: 33,
};

export const AppointmentType = {
	STANDARD: 1,
	REQUEST: 2,
};

export const InviteParticipantFailureReason = {
	INVITE_DENIED: 'inviteParticipant.denied',
	FAILED_TO_GET_INFO: 'inviteParticipant.failedToGetInfo',
};

export const MeasurementUnits = {
	KILOMETERS: 'km',
	METERS: 'm',
	CENTIMETERS: 'cm',
	INCHES: 'inch',
	FEET: 'ft',
	MILES: 'mi',
	KILOGRAMS: 'kg',
	LBS: 'lbs',
	LB: 'lb',
	ST: 'st',
	FARENHEIT: '°F',
	CELSIUS: '°C',
	MMOLL: 'mmol/L',
	MGDL: 'mg/dL',
	BPM: 'bpm',
	MMHG: 'mmHg',
	PERCENTAGE: '%',
	STEPS: 'steps',
	FLOORS: 'floors',
	BMI: 'BMI',
	HOURS: 'hours',
	SECONDS: 's',
	BREATHS_PER_MIN: 'breathsPerMin',
	STETHOSCOPE: 's',
	ML_BEAT: 'ML/beat',
	L_MIN: 'L/min',
	L_MIN_M: 'L/min/m',
};

export const MeetingPlace = {
	MOBILE_DEVICE: 1,
	CLINIC: 2,
};

export const StethoscopeTypes = {
	LITTMANN: 'littmann',
	MINTTI: 'mintti',
};

export const AnalysesColors = {
	HEART: '#ff8080',
	ACTIVITY: '#80cc52',
	BODY_MEASUREMENTS: '#ef9952',
	RESPIRATORY: '#62d3fd',
	SLEEP: '#bf5bf1',
	BLOOD_GLUCOSE: '#247ef9',
	STETHOSCOPE: '#23a6f8',
	STRESS_LEVEL: '#55a6f8',
	HRV_INDEX: '#11a6f8',
	BILIRUBIN: '#21s6f8',
};

export const UnitCategoryTypes = {
	BLOOD_GLUCOSE: 1,
	DISTANCE: 2,
	TEMPERATURE: 3,
	HEIGHT: 4,
	WEIGHT: 5,
};

export const UnitSystemTypes = {
	METRIC: 1,
	IMPERIAL: 2,
};

export const MeasurementUnitOptions = {
	DISTANCE_WALKING_RUNNING: {
		value: {
			1: { text: 'km (Kilometer)', abbr: 'km', unitValue: UnitSystemTypes.METRIC },
			2: { text: 'mi (Mile)', abbr: 'mi', unitValue: UnitSystemTypes.IMPERIAL },
		},
		name: 'distance',
	},
	WEIGHT: {
		value: {
			1: { text: 'kg (Kilogram)', abbr: 'kg', unitValue: UnitSystemTypes.METRIC },
			2: { text: 'lbs (Pound)', abbr: 'lbs', unitValue: UnitSystemTypes.IMPERIAL },
		},
		name: 'weight',
	},
	LEAN_BODY_MASS: {
		value: {
			1: { text: 'kg (Kilogram)', abbr: 'kg', unitValue: UnitSystemTypes.METRIC },
			2: { text: 'lbs (Pound)', abbr: 'lbs', unitValue: UnitSystemTypes.IMPERIAL },
		},
		name: 'leanBodyMass',
	},

	TEMPERATURE: {
		value: {
			1: { text: '°C (Celsius)', abbr: '°C', unitValue: UnitSystemTypes.METRIC },
			2: { text: '°F (Fahrenheit)', abbr: '°F', unitValue: UnitSystemTypes.IMPERIAL },
		},
		name: 'temperature',
	},

	BLOOD_GLUCOSE: {
		value: {
			1: { text: 'mmol/L (Millimoles per Liter)', abbr: 'mmol/L', unitValue: UnitSystemTypes.METRIC },
			2: { text: 'mg/dL (Milligrams per Deciliter)', abbr: 'mg/dL', unitValue: UnitSystemTypes.IMPERIAL },
		},
		name: 'bloodGlucose',
	},
	HEIGHT: {
		value: {
			1: { text: 'cm (Centimeter)', abbr: 'cm', unitValue: UnitSystemTypes.METRIC },
			2: { text: 'ft (Feet)', abbr: 'ft', unitValue: UnitSystemTypes.IMPERIAL },
		},
		name: 'height',
	},
	WAIST_CIRCUMFERENCE: {
		value: {
			1: { text: 'cm (Centimeter)', abbr: 'cm', unitValue: UnitSystemTypes.METRIC },
			2: { text: 'in (Inch)', abbr: 'in', unitValue: UnitSystemTypes.IMPERIAL },
		},
		name: 'waistCircumference',
	},
	TOTAL_CHOLESTEROL: {
		value: {
			1: { text: 'mmol/L (Millimoles per Liter)', abbr: 'mmol/L', unitValue: UnitSystemTypes.METRIC },
			2: { text: 'mg/dL (Milligrams per Deciliter)', abbr: 'mg/dL', unitValue: UnitSystemTypes.IMPERIAL },
		},
		name: 'totalCholesterol',
	},
	HDL_CHOLESTEROL: {
		value: {
			1: { text: 'mmol/L (Millimoles per Liter)', abbr: 'mmol/L', unitValue: UnitSystemTypes.METRIC },
			2: { text: 'mg/dL (Milligrams per Deciliter)', abbr: 'mg/dL', unitValue: UnitSystemTypes.IMPERIAL },
		},
		name: 'hdlCholesterol',
	},
};

export const MeasurementsMinMax = {
	MMOL: {
		MIN: 0,
		MAX: 50,
	},
	MGDL: {
		MIN: 0,
		MAX: 900,
	},
	CELSIUS: {
		MIN: 33,
		MAX: 42,
	},
	FARENHEIT: {
		MIN: 91,
		MAX: 107,
	},
	MILES: {
		MIN: 0,
		MAX: 10,
	},
	METERS: {
		MIN: 0,
		MAX: 15000,
	},
	HEART_RATE: {
		MIN: 30,
		MAX: 350,
	},
	BMI: {
		MIN: 2,
		MAX: 400,
	},
	OXYGEN_SATURATION: {
		MIN: 70,
		MAX: 100,
	},
	SYSTOLIC: {
		MIN: 40,
		MAX: 300,
	},
	DIASTOLIC: {
		MIN: 30,
		MAX: 200,
	},
};

export const AttestationEnums = {
	CRIMINAL_OR_CIVIL: 1,
	HOSPITAL_PRIVILEGES: 2,
	MEDICARE: 3,
	ABILITY_TO_PERFORM: 4,
	ADDITIONAL_ATTESTATIONS: 5,
};

export const Agreements = {
	BACKGROUND_CHECK: 1,
	DISCLOSURE_AGREEMENT: 2,
	AUTHORIZATION: 3,
	OCG_AGREEMENT: 4,
	APPEAL_RIGHTS: 5,
	CREDENTIALING_RIGHTS: 6,
	TERMS_AND_CONDITIONS: 7,
	PRIVACY_POLICY: 8,
};

export const KeyCodes = {
	PERIOD: 190,
	NUMPAD_PERIOD: 110,
	MINUS: 189,
	NUMPAD_SUBTRACT: 109,
	NUMPAD_ADD: 107,
	PLUS: 187,
	LETTER_E: 69,
	NUMPAD_MINUS: 109,
	LETTER_A: 65,
	LETTER_Z: 90,
	ENTER: 13,
	SPACE: 32,
	BACK_SPACE: 8,
	LEFT_ARROW: 37,
	UP_ARROW: 38,
	RIGHT_ARROW: 39,
	DOWN_ARROW: 40,
};

export const Gender = {
	UNAVAILABLE: 0,
	MALE: 1,
	FEMALE: 2,
	NON_BINARY: 3,
};

export const Country = {
	USA: 232,
	KOSOVO: 118,
	ALBANIA: 3,
};

export const DoctorSpecialty = {
	OTHER: 12,
};

export const PatientTabTypes = {
	OVERVIEW: 'overview',
	SUMMARY: 'summary',
	CONVERSATION: 'conversation',
	FILES: 'files',
	HEALTH_DATA: 'health-data',
	VISITS: 'visits',
	PATIENT_HISTORY: 'patient-history',
	PHYSICAL_THERAPY: 'physicalTherapy',
	WEARABLES: 'wearables',
	ALERT_HISTORY: 'alertHistory',
};

export const CountryIds = {
	USA: 232,
};

export const EducationTypes = {
	MEDICAL_SCHOOL: 1,
	GRADUATE_SCHOOL: 2,
};

export const MedicalTrainingTypes = {
	INTERNSHIP: 1,
	RESIDENCY: 2,
	FELLOWSHIP: 3,
};

export const VisitReasons = {
	INSTRUCTION: 1,
	THERAPY_DESCRIPTION: 2,
	CHECK_UP: 3,
	RE_CHECK_UP: 4,
	REFERRAL: 5,
	CODE: 6,
	RAPID_RESPONSE: 7,
	ED_ADMIT_DISCUSSION: 8,
	DRUG_ORDER_OR_CLARIFICATION: 9,
	ABNORMAL_VITAL: 10,
	ABNORMAL_LAB: 11,
	CRITICAL_LAB: 12,
	PATIENT_DECLINING: 13,
	OTHER_EMERGENT: 14,
	REVIEW_OUTSIDE_FACILITY_TRANSFER: 15,
	DRN_OR_DNI_CLARIFICATION: 16,
	OTHER_NON_EMERGENT: 17,
	ADMIT_ICU: 18,
	ADMIT_NON_ICU: 19,
	ADMIT_BACKUP: 20,
	ROUNDING: 21,
	OTHER: 22,
};

export const OrganizationalUnitType = {
	HEALTH_SYSTEM: 4,
};

export const PatientHeightLimit = {
	FEET: {
		MIN: 3.28,
		MAX: 9.84252,
	},
	INCHES: {
		MIN: 39.3701,
		MAX: 118.11,
	},
	CENTIMETER: {
		MIN: 100,
		MAX: 300,
	},
};

export const TotalCholesterolLimit = {
	MGDL: {
		MIN: 101,
		MAX: 260,
	},
	MMOLL: {
		MIN: 5.6,
		MAX: 14.4,
	},
};

export const HdlCholesterolLimit = {
	MGDL: {
		MIN: 19.82,
		MAX: 59.46,
	},
	MMOLL: {
		MIN: 1.1,
		MAX: 3.3,
	},
};

export const PatientWaistCircumferenceRangeLimit = {
	CM: {
		MIN: 45,
		MAX: 250,
	},
	INCH: {
		MIN: 17.71,
		MAX: 98.42,
	},
};

export const PatientWeightLimit = {
	LBS: {
		MIN: 4.41,
		MAX: 881.85,
	},
	KILOGRAMS: {
		MIN: 2,
		MAX: 400,
	},
};

export const PatientAgeLimit = {
	MIN: 0,
	MAX: 120,
};

export const ConversationType = {
	INDIVIDUAL: 0,
	GROUP: 1,
	SUPPORT: 2,
};

export const AppointmentStatus = {
	ACCEPTED: 1,
	PENDING: 2,
	REJECTED: 3,
	RESCHEDULED: 4,
	CANCELED: 5,
	COMPLETED: 6,
};

export const AppointmentSelectedTab = {
	ACCEPTED: 0,
	PENDING: 1,
	REJECTED: 2,
};

export const StressLevels = {
	HIGHLY_TENSE: 0,
	SLIGHTLY_TENSE: 1,
	MIDLY_CALM: 2,
	QUIETLY_RELEXAED: 3,
	DEEPLY_RELAXED: 4,
};

export const CompanySize = {
	LEVEL_ONE: { id: 1, value: '1-10 people' },
	LEVEL_TWO: { id: 2, value: '11-50 people' },
	LEVEL_THREE: { id: 3, value: '51-100 people' },
	LEVEL_FOUR: { id: 4, value: '101-250 people' },
	LEVEL_FIVE: { id: 5, value: '251-500 people' },
	LEVEL_SIX: { id: 6, value: '501-1000 people' },
	LEVEL_SEVEN: { id: 7, value: '1001-4000 people' },
	LEVEL_EIGHT: { id: 8, value: '4001 or more people' },
};

export const SortByNameValues = {
	SORTING_AZ: 1,
	SORTING_ZA: 2,
};

export const RiskLevels = {
	HIGH: 'high',
	MODERATE: 'mod',
	LOW: 'low',
};

export const VitalSignsEwsScoreLevels = {
	LOW: 1,
	MODERATE: 2,
	HIGH: 3,
};

export const RiskLevelValues = {
	HIGH: 3,
	MODERATE: 2,
	NORMAL: 1,
};

export const AudioOutputDevice = {
	HELLO: 0,
	TV: 1,
};

export const HdmiSysDelayValues = {
	AUTO: 'auto',
	DEFAULT: 'default',
	CUSTOM: 'custom',
};

export const HelloSettings = {
	SELECT_MODE: 1,
	AUTO_ANSWER: 2,
	LANGUAGE: 3,
	PRIVACY_MODE: 4,
	BABY_DETECTION: 5,
	BED_AND_RAILS: 6,
	SKELETON_FALL_DETECTION: 7,
	OBJECT_DETECTION: 8,
	PATIENT_DETECTION: 9,
	SURGICAL_TOOLS_DETECTION: 11,
	AI_MODELS: 12,
	SOFTWARE_UPDATE: 100,
	DATA_ACQUISITION: 13,
	AUDIO_LEVEL: 25,
	CAMERA_TYPE: 15,
	PORTRAIT_MODE: 17,
	DEVICE_ORIENTATION: 26,
	HDMI_SYSDELAY: 21,
	PRIVACY_MODE_TIMEOUT: 24,
	AUDIO_SETTINGS: 999,
	ASPECT_RATIO: 23,
};

export const PrivacyMode = {
	OFF: 0,
	ON: 1,
	LIVE: 2,
};

export const SkeletonPart = {
	RIGHT_EAR: 'rightEar',
};

export const SymptomsLength = {
	MAX_SYMPTOMS: 10,
};

export const PatientSummaryTypes = {
	PRESCRIPTIONS: 0,
	DIAGNOSES: 1,
	PROCEDURES: 2,
	NOTES: 3,
	PHYSICAL_THERAPIES: 4,
};

export const InvitationCode = {
	CANCELLED: 3011,
	ACCEPTED: 3012,
	EXPIRED: 3013,
};

export const ProductTypes = {
	HOME_CARE: '1',
	HOSPITAL_CARE: '2',
};

export const MedicalInfoTypes = {
	DIAGNOSES: 'diagnoses',
	PROCEDURES: 'procedures',
	NOTES: 'notes',
	PRESCRIPTIONS: 'prescriptions',
	PHYSICAL_EXERCISES: 'physicalExercises',
};

export const ClinicalTableTypes = {
	ICD10CM: 'icd-10',
	HCPCS: 'hcpcs',
};

export const DeliveryTypes = {
	PHARMACY_PICK_UP: 1,
	HOME_DELIVERY: 2,
};

export const AcceptedFileTypes = {
	JPG: 'image/jpg',
	JPEG: 'image/jpeg',
	PNG: 'image/png',
	BMP: 'image/bmp',
	TEXT: 'text/plain',
	PDF: '.pdf',
	DOC: '.doc',
	DOCX: '.docx',
	XLS: '.xls',
	XLSX: '.xlsx',
	PPT: '.ppt',
	PPTX: '.pptx',
	MPG: '.mpg',
	MP4: '.mp4',
	MKV: '.mkv',
	GP: '.3gp',
	M4V: '.m4v',
	MOV: '.mov',
};

export const WorkFlow = {
	DIRECTLY: 1,
	THROUGH_NURSES: 2,
};

export const RoomType = {
	CONVERSATION: 1,
	HELLO_DEVICE: 2,
	USER: 3,
	LINK: 4,
	PRIVATE: 5,
	CONFERENCE: 6,
	WHITEBOARD: 7,
};
export const StreamTypes = {
	AUDIO: 1,
	VIDEO: 2,
	SCREEN_SHARE: 3,
};

export const CanceledReschedulingType = {
	RESCHEDULE: 1,
	CHANGE_DOCTOR: 2,
};

export const ChatOptionsViews = {
	DEFAULT: 0,
	IMAGES: 1,
	VIDEOS: 2,
	PARTICIPANTS: 3,
	ATTACHMENTS: 4,
	LINKS: 5,
};

export const PoolTypes = {
	QUEUING: 1,
	FRONTLINE: 2,
};

export const AiAlertActivityType = {
	AI_ALERT_SENT: 1,
	AI_ALERT_RECEIVED: 2,
	ADDED_AS_HOSPITAL_CARETAKER: 3,
	COMMENTED: 4,
	ACKNOWLEDGED: 5,
	AI_FAKE_ALERT: 6,
	AI_FORWARD_TO_NURSES: 7,
};

export const MeasurementActivityTypes = {
	MEASUREMENT_ALERT_SENT: 1,
	MEASUREMENT_ALERT_RECEIVED: 2,
	ADDED_AS_HOSPITAL_CARETAKER: 3,
	COMMENTED: 4,
	ACKNOWLEDGED: 5,
	MEASUREMENT_FAKE_ALERT: 6,
	MEASUREMENT_FORWARD_TO_NURSES: 7,
};

export const AlertTabs = {
	MEASUREMENT: 1,
	AI: 2,
	MANUAL: 3,
	RECORDING: 4,
	NOTES: 5,
};

export const SettingsButtons = {
	HEALTH_HISTORY: 1,
	SAFE_RANGES: 2,
	AI_HISTORY: 3,
	AI_SETUP: 4,
	HEALTH_DATA_SETUP: 5,
	AI_ALERTS: 6,
	AI_GENERAL_SETTINGS: 7,
};

export const ManualAlertTypes = {
	FALL_DETECTED: 1,
	RISK_OF_FALL: 2,
	PATIENT_NEEDS_HELP: 3,
	PATIENT_REQUESTED_HELP: 4,
	CUSTOM: 5,
	START_STAT_ALARM: 1000,
	STOP_STAT_ALARM: 1001,
	START_VOICE_OVER: 1002,
	STOP_VOICE_OVER: 1003,
	PRIVACY_MODE_ON: 5000,
	PRIVACY_MODE_OFF: 5001,
	DISCONTINUE_MONITORING: 8000,
	REPORT_AI_DETECTION: 15000,
};

export const ManualAlertActivityTypes = {
	MANUAL_ALERT_SENT: 1,
	MANUAL_ALERT_RECEIVED: 2,
	ADDED_AS_AN_HOSPITAL_CARETAKER: 3,
	COMMENTED: 4,
	ACKNOWLEDGED: 5,
	MANUAL_FAKE_ALERT: 6,
	MANUAL_FORWARD_TO_NURSES: 7,
	SAVED_ACTIVITY: 8,
	STOPPED_STAT_ALARM: 9,
};

export const TeamTypes = {
	DEFAULT: 1,
	PUBLIC: 2,
	PRIVATE: 3,
	HEALTH_SYSTEM: 4,
	REGION: 5,
	HOSPITAL: 6,
	DEPARTMENT: 7,
	FLOOR: 8,
	FRONTLINE: 9,
};

export const AcademicDegrees = [
	{ id: 1, name: 'medicalSchool' },
	{ id: 2, name: 'graduateSchool' },
];

export const AndroidVersions = [
	{ codeName: 'No codename', versionNum: '1.0', apiLevels: [1] },
	{ codeName: 'No codename', versionNum: '1.1', apiLevels: [2] },
	{ codeName: 'Cupcake', versionNum: '1.5', apiLevels: [3] },
	{ codeName: 'Donut', versionNum: '1.6', apiLevels: [4] },
	{ codeName: 'Eclair', versionNum: '2.0 - 2.1', apiLevels: [5, 6, 7] },
	{ codeName: 'Froyo', versionNum: '2.2 - 2.2.3', apiLevels: [8] },
	{ codeName: 'Gingerbread', versionNum: '2.3 - 2.3.7', apiLevels: [9, 10] },
	{ codeName: 'Honeycomb', versionNum: '3.0 - 3.2.6', apiLevels: [11, 12, 13] },
	{ codeName: 'Ice Cream Sandwich', versionNum: '4.0 - 4.0.4', apiLevels: [14, 15] },
	{ codeName: 'Jelly Bean', versionNum: '4.1 - 4.3.1', apiLevels: [16, 17, 18] },
	{ codeName: 'KitKat', versionNum: '4.4 - 4.4.4', apiLevels: [19, 20] },
	{ codeName: 'Lollipop', versionNum: '5.0 - 5.1.1', apiLevels: [21, 22] },
	{ codeName: 'Marshmallow', versionNum: '6.0 - 6.0.1', apiLevels: [23] },
	{ codeName: 'Nougat', versionNum: ' 7.0', apiLevels: [24] },
	{ codeName: 'Nougat', versionNum: '7.1.0 - 7.1.2', apiLevels: [25] },
	{ codeName: 'Oreo', versionNum: '8.0', apiLevels: [26] },
	{ codeName: 'Oreo', versionNum: '8.1', apiLevels: [27] },
	{ codeName: 'Pie', versionNum: '9.0', apiLevels: [28] },
	{ codeName: 'Android 10', versionNum: '10.0', apiLevels: [29] },
	{ codeName: 'Android 11', versionNum: '11', apiLevels: [30] },
	{ codeName: 'Android 12', versionNum: '12', apiLevels: [31, 32] },
	{ codeName: 'Android 13', versionNum: '13', apiLevels: [33] },
	{ codeName: 'Android 14', versionNum: '14', apiLevels: [34] },
];

export const ConnectivityStatus = {
	ON: 1,
	OFF: 0,
};

export const BodyDimensionsTypes = {
	HEIGHT: 14,
	WEIGHT: 33,
};

export const Address = {
	COUNTRY: 'country1',
	CITY: 'city',
	ZIP_CODE: 'zipCode',
	ADDRESS_1: 'address',
	ADDRESS_2: 'address2',
};

export const License = {
	COUNTRY: 'country2',
	LICENSE_NO: 'licenseNo',
};

export const Education = {
	COUNTRY: 'country3',
	DEGREE: 'degree',
	START: 'start',
	END: 'end',
	SCHOOL: 'school',
	CURRENTLY_STUDYING: 'currentlyStudying',
};

export const Employment = {
	COMPANY: 'company',
	POSITION: 'position',
	COUNTRY: 'country4',
	START_DATE: 'startDate',
	END_DATE: 'endDate',
	CURRENTLY_WORKING: 'currentlyWorking',
};

export const DoctorProfileInfoTabs = {
	PERSONAL_INFO: 0,
	LOCATION: 1,
	SIGNATURE: 2,
	LICENSES: 3,
	EMPLOYMENT: 4,
	EDUCATION: 5,
};

export const CallTypesMessage = {
	AUDIO: translate('audioCall'),
	VIDEO_CALL: translate('videoCall'),
	TALK_TO_PATIENT: translate('talkToPatient'),
	PATIENT_VIEW: translate('patientView'),
	VIDEO_MONITORING: translate('videoMonitoring'),
	AMBIENT_MONITORING: translate('ambientMonitoring'),
	AMBIENT_AND_VIDEO_MONITORING: translate('ambientAndVideoMonitoring'),
	SCREENSHARE: translate('screenShare'),
	ROUNDING: translate('rounding'),
};

export const TerminateRequestFailureReason = {
	UNHANDLED_EXCEPTION: 'terminateRequestFailureReason.unhandledException',
	NULL_CONFERENCE: 'terminateRequestFailureReason.nullConference',
	TERMINATE_IN_PROGRESS: 'terminateRequestFailureReason.terminateInProgress',
	NULL_TERMINATE_REQUEST: 'terminateRequestFailureReason.nullTerminateRequest',
	NULL_CONNECTED_USER_PARTICIPANT: 'terminateRequestFailureReason.nullConnectedUserParticipant',
	NO_PERMISSION: 'terminateRequestFailureReason.noPermission',
};

export const TerminateRequestFailureReasonMessages = {
	[TerminateRequestFailureReason.UNHANDLED_EXCEPTION]: translate('somethingWentWrong'),
	[TerminateRequestFailureReason.NULL_CONFERENCE]: translate('sessionAlreadyEnded'),
	[TerminateRequestFailureReason.TERMINATE_IN_PROGRESS]: translate('terminationInitiated'),
	[TerminateRequestFailureReason.NULL_CONNECTED_USER_PARTICIPANT]: translate('somethingWentWrong'),
	[TerminateRequestFailureReason.NO_PERMISSION]: translate('noPermissionToTerminate'),
};

export const UserRole = {
	DOCTOR: 'Doctor',
	VCP: 'Virtual Care Provider',
	PATIENT: 'Patient',
	ADMIN: 'Admin',
	SUPER_USER: 'Super User',
	SUPER_ADMIN: 'Super Admin',
	OWNER: 'Owner',
	DIGITAL_CLINICIAN: 'Digital Clinician',
	FAMILY_MEMBER: 'Family Member',
	VISITOR: 'Visitor',
	VIRTUAL_SITTER: 'Virtual Sitter',
};

export const DeviceFamilyTypes = {
	TABLET: 'Tablet',
	HELLO_2: 'HELLO 2',
	HELLO_2_PLUS: 'HELLO 2+',
	HELLO_3: 'HELLO 3',
	EXPAND_VISION_3: 'EXPAND VISION 3',
	EXPAND_VISION_5: 'EXPAND VISION 5',
	EXPAND_VISION_3T: 'EXPAND VISION 3T',
	EXPAND_VISION_5T: 'EXPAND VISION 5T',
};

export const DeviceFamilyUniqueTags = {
	HELLO_1: 'H1',
	HELLO_2: 'H2',
	HELLO_2_PLUS: 'H2+',
	HELLO_3: 'H3',
	TABLET: 'TAB',
	PHONE: 'PH',
	EXPAND_VISION_3: 'EV3',
	EXPAND_VISION_5: 'EV5',
	EXPAND_VISION_3T: 'EV3T',
	EXPAND_VISION_5T: 'EV5T',
	HELLO_CARE_TV_27: 'HCTV_27',
	HELLO_CARE_TV_32: 'HCTV_32',
	HELLO_CARE_TV_43: 'HCTV_43',
	HELLO_CARE_TV_55: 'HCTV_55',
	HELLO_CARE_TABLET_10: 'HCTAB_10',
	HELLO_CARE_TABLET_13: 'HCTAB_13',
	HELLO_CARE_TABLET_15: 'HCTAB_15',
};

export const PhysicalCategories = {
	SHOULDER_EXERCISES: 'shoulderExercises',
	NECK_EXERCISES: 'neckExercises',
	LEG_EXERCISES: 'legExercises',
	HIP_EXERCISES: 'hipExercises',
};

export const PhysicalExerciseTabs = {
	EXERCISES_LIST: 1,
	NEW_EXERCISE: 2,
	EXERCISE_DETAILS: 3,
};

export const IntensityTypes = {
	MAX_RANGE_OF_MOTION: 'maxRangeOfMotion',
	DAYS_TO_ACHIEVE_TARGET: 'daysToAchieveTarget',
};

export const ConditionRiskLevels = [
	{
		type: RiskLevels.LOW,
		color: 'var(--green-1)',
		img: 'low-risk.svg',
	},
	{
		type: RiskLevels.MODERATE,
		color: 'var(--orange-1)',
		img: 'moderate-risk.svg',
	},
	{
		type: RiskLevels.HIGH,
		color: 'var(--red-1)',
		img: 'high-risk.svg',
	},
];

export const LastConditionTypes = [
	{ code: '10100001', text: 'universalSafetyInterventions', risk: RiskLevels.LOW },
	{ code: '10100002', text: 'fallRisk', risk: RiskLevels.MODERATE },
	{ code: '10100003', text: 'silentHeartAttack', risk: RiskLevels.HIGH },
	{ code: '10100004', text: 'fallHarmRisk', risk: RiskLevels.HIGH },
];

export const AddEditPatientRadioTypes = {
	NO_ANSWER: 0,
	YES: 1,
	NO: 2,
};

export const TaskStatus = {
	CREATED: 1,
	RUNNING: 2,
	COMPLETED: 3,
	CANCELED: 4,
	FAULTED: 5,
};

export const TaskTypeId = {
	EXPORT_CALL_LOGS: 1,
	CREATE_PATIENT: 2,
	MEDIA_UPLOAD_GCP: 3,
	AD_USER_SYNC: 4,
	INVITE_COMPANY_MEMBERS: 5,
	BIO_BEAT: 6,
	HAND_HYGEINE: 7,
	PATIENT_REPORT: 8,
};

export const MeasurementAlertTypeId = {
	BLOOD_GLUCOSE: 1,
	HEART_RATE: 2,
	BODY_TEMPERATURE: 3,
	BMI: 4,
	BLOOD_PRESSURE: 5,
};

export const BloodPresureUnitTypeIds = {
	LOW_SYSTOLIC: 10,
	LOW_DIASTOLIC: 11,
	EXTREMELY_LOW_SYSTOLIC: 12,
	EXTREMELY_LOW_DIASTOLIC: 13,
	HIGH_SYSTOLIC: 14,
	HIGH_DIASTOLIC: 15,
	EXTREMELY_HIGH_SYSTOLIC: 16,
	EXTREMELY_HIGH_DIASTOLIC: 17,
};

export const PatientAiSetting = {
	RAILS: 1,
	PATIENT_SITTING: 2,
	PATIENT_STANDING: 3,
	PATIENT_FACE_DOWN: 4,
	PATIENT_LYING_SIDE: 5,
	PATIENT_LYING_FRONT: 6,
	PATIENT_LYING_RIGHT_SIDE: 7,
	FALL_DETECTED: 8,
	PRESSURE_INJURY: 9,
	PATIENT_GETTING_OUT_OF_BED: 10,
	SKELETON_PRIVATE_MODE: 11,
	PATIENT_MOBILITY: 12,
	PATIENT_LYING_LEFT_SIDE: 13,
	VITAL_SIGNS_AI: 14,
	HAND_WASHING: 15,
	IV_BAG: 16,
	AI_DRAWING: 17,
	SOUND_ON_AI_ALERT: 18,
	FALL_PREVENTION: 999,
	AI_VOICE_ANALYSIS: 19,
};

export const CookiesAnswer = {
	NO: 0,
	YES: 1,
};

export const HealthDataType = {
	BLOOD_GLUCOSE: 1,
	HEART_RATE: 2,
	BODY_TEMPERATURE: 3,
	BMI: 4,
	BLOOD_PRESSURE: 5,
	OXYGEN_SATURATION: 6,
};

export const HealthDataField = {
	HIGH_BLOOD_GLUCOSE: 1,
	HIGH_HEART_RATE: 2,
	LOW_HEART_RATE: 3,
	LOW_BODY_TEMPERATURE: 4,
	HIGH_BODY_TEMPERATURE: 5,
	CRITICAL_HIGH_BODY_TEMPERATURE: 6,
	LOW_BMI: 7,
	HIGHT_BMI: 8,
	CRITICAL_HIGH_BMI: 9,
	LOW_SYSTOLIC: 10,
	LOW_DIASTOLIC: 11,
	CRITICAL_LOW_SYSTOLIC: 12,
	CRITICAL_LOW_DIASTOLIC: 13,
	HIGH_SYSTOLIC: 14,
	HIGH_DIASTOLIC: 15,
	CRITICAL_HIGH_SYSTOLIC: 16,
	CRITICAL_HIGH_DIASTOLIC: 17,
	LOW_OXYGEN: 18,
	CRITICAL_LOW_OXYGEN: 19,
};

export const HealthSystemType = {
	DEFAULT: 1,
	PRIMARY_CARE: 2,
};

export const NursePoolingOptions = {
	NO_ANSWER: 0,
	YES: 1,
	NO: 2,
};

export const DeviceSettingType = {
	SCREEN_MODE: 1,
	AUTO_ANSWER: 2,
	LANGUAGE: 3,
	PRIVATE_MODE: 4,
	BABY_DETECTION: 5,
	BED_AND_RAILS: 6,
	SKELETON_FALL_DETECTION: 7,
	OBJECT_DETECTION: 8,
	PATIENT_POSITION: 9,
	MULTIPLE_PEOPLE_SKELETON_DETECTION: 10,
	SURGICAL_TOOLS_DETECTION: 11,
	AI_MODELS: 12,
	DATA_ACQUISITION: 13,
	VOICE_COMMAND_CALLING: 14,
	DEFAULT_CAMERA: 15,
	AUDIO_VOLUME: 16,
	PORTRAIT_MODE: 17,
	ALEXA_SERVICE: 18,
};

export const ScreenMode = {
	HELLO_CARE_HOME: 'helloCareHome',
	WIDGET_AI: 'widgetAI',
	WIDGET: 'widget',
	DIGITAL_CLINIC: 'digitalClinic',
	TELEMEDICINE: 'telemedicine',
	HOSPITAL: 'hospital',
	NICU: 'NICU',
	VIRTUAL_EXAMINATION: 'virtualExamination',
	SIMPLIFIED: 'simplified',
	EVIDEON: 'eVideon',
	PATIENT_EXPERIENCE: 'patientExperience',
	HOSPITAL_AT_HOME: 'hospitalAtHome',
};

export const MeasuringDevices = [
	{
		id: 1,
		title: 'pulseOximeter',
	},
	{
		id: 2,
		title: 'glucometer',
	},
	{
		id: 3,
		title: 'thermometer',
	},
	{
		id: 4,
		title: 'bloodPressureMonitor',
	},
	{
		id: 5,
		title: 'bodyWeightScale',
	},
	{
		id: 6,
		title: 'fitnessTracker',
	},
	{
		id: 7,
		title: 'stethoscope',
	},
	{
		id: 8,
		title: 'checkMePro',
	},
	{
		id: 9,
		title: 'HELLO',
	},
	{
		id: 10,
		title: 'ECG',
	},
];

export const UserPermissionDeniedErrors = {
	NotFoundError: 'NotFoundError',
	NotAllowedError: 'NotAllowedError',
};

export const ErrorComponentTypes = {
	Modal: 'modal',
	Popup: 'popup',
};

export const DeviceStateNames = {
	HasPairedRemote: 'hasPairedRemote',
};

export const HealthcareErrorCode = {
	USER_NOT_FOUND: 4001,
	MONITORING_SESSION_NOT_FOUND: 4002,
	POOL_ROLE_NOT_FOUND: 4003,
	POOL_NOT_FOUND: 4004,
	NURSE_ASSIGNMENT_NOT_FOUND: 4005,
	RESOURCE_EXISTS: 4006,
	DEVICE_POOL_NOT_FOUND: 4007,
	INSUFFICIENT_PERMISSIONS: 4008,
	REQUEST_IS_ASSIGNED: 4009,
	INVALID_USER_PREFERENCE_TYPE: 4010,
	GENERAL_NURSE_NOT_FOUND: 4011,
	APPOINTMENT_SLOT_NOT_FOUND: 4012,
	USER_ROLES_NOT_FOUND: 4013,
	NO_NURSE_AUTHORIZED: 4014,
	PATIENT_NOT_FOUND: 4015,
	USER_DEACTIVATED: 4016,
	PATIENT_EXISTING_PENDING_REQUEST: 4017,
	PATIENT_EXISTING_APPOINTMENT_TIMESLOT: 4018,
	DEVICE_OFFLINE: 4019,
	DEVICE_NOT_AVAILABLE_FOR_CALL: 4020,
	DEVICE_STATE_NOT_AVAILABLE: 4021,
	USER_IS_CHECKED_IN: 4024,
	DOCTOR_ID_CARD_EXISTS: 4025,
	PATIENT_IN_QUEUE: 4026,
	MEDICAL_VISIT_INITIATED: 4029,
	PATIENT_IN_VISIT: 4030,
	EXISTING_COMPANION_MODE: 5001,
};

export const DeviceErrorCode = {
	NOT_FOUND: 1000,
	SERVER_ERROR: 1001,
	VALIDATION_ERROR: 1003,
	BAD_REQUEST: 1004,
	UNAUTHORIZED: 1005,
	FORBIDDEN: 1006,
	EMAIL_EXISTS: 1008,
	USER_EXISTS: 3000,
	USER_NOT_FOUND: 3001,
	PARTNER_NOT_FOUND: 3002,
	CLIENT_NOT_FOUND: 3003,
	DEFINITION_NOT_FOUND: 3004,
	WALLPAPER_NOT_FOUND: 3005,
	BUILD_NOT_FOUND: 3006,
	DEVICE_NOT_REGISTERED: 3007,
	DEVICE_ALREADY_REGISTERED: 3008,
	DEVICE_NOT_VERIFIED: 3009,
	DEVICE_ALREADY_VERIFIED: 3010,
	DEVICE_SERIAL_NUMBER_NOT_VALID: 3011,
	POOL_NOT_FOUND: 3012,
	PROFILE_NOT_FOUND: 3012,
	RESOURCE_EXISTS: 3013,
	PRIVATE_MODE_AND_DATA_ACQUISITION_ENABLED: 3014,
};

export const CareEventsForNurses = {
	HomeMedListCompleted: 6,
	AdmissionDataCompleted: 7,
	IPOCcompleted: 8,
	DischargeInstructionsCreated: 9,
	DischargeTeachingCompleted: 10,
	Performed2ndRNMedVerification: 11,
	NotifiedMD: 12,
	PerformedMentorship: 13,
	PerformedSepsisScreenNotification: 14,
	Completed24HourChartCheck: 15,
	PerformedClinicalRounding: 16,
	ProvidedUpdateToFamily: 17,
	AssistedWithCodeBlue: 18,
	OtherCareEventForNurse: 19,
};

export const CareEventsForSafetyCompanions = {
	RedirectedPatientToPreventFall: 20,
	NotifiedBedsideStaffToAssistPatientToPreventFall: 21,
	NotifiedBedsideStaffToAssistPatient: 22,
	CalledRapidResponse: 23,
	PreventedHarmToStaffMember: 24,
};

export const ManualAlertCategoryTypes = {
	Basic: 1,
	FrontLineNurses: 2,
	SafetyCompanions: 3,
};

export const CareEventTypes = {
	FrontLineNurses: 2,
	SafetyCompanions: 3,
	Rounding: 4,
};

export const HelloVideoFeedRecordAction = {
	END: 0,
	START: 1,
};

export const Client = [
	{ id: 1, name: 'Hellocare' },
	{ id: 2, name: 'Banyan' },
	{ id: 3, name: 'Northwell' },
	{ id: 4, name: 'Amwell' },
	{ id: 5, name: 'Solaborate' },
];

export const SourceOfInput = [
	{ id: 1, name: 'Hello' },
	{ id: 2, name: 'Web' },
	{ id: 3, name: 'Android' },
	{ id: 4, name: 'iOS' },
];

export const ObjectTypeDetail = [
	{ id: ObjectType.USER, name: 'User' },
	{ id: ObjectType.HELLO_DEVICE, name: 'Hello Device' },
];

export const InputObjectTypeDetails = [...ObjectTypeDetail, { id: ObjectType.SYSTEM, name: 'System Executed Actions' }];

export const PatientProfileInfoTab = {
	PERSONAL_INFORMATION: 1,
	ADDRESS: 2,
	CONTACT_INFORMATION: 3,
	HEALTH_INFORMATION: 4,
};

export const AccountSettingsTab = {
	GENERAL: 0,
	EDIT_PROFILE: 1,
	CHANGE_PASSWORD: 2,
	PHARMACY: 3,
	INTEGRATED_APPS: 4,
	APP_LANGUAGE: 5,
	MEASUREMENT_UNITS: 6,
	COMPANY_DETAILS: 7,
	COMPANY_SETTINGS: 8,
	HELP: 9,
	MY_WORKFLOW: 10,
	MY_USER_ROLES: 11,
	CHANGE_EMAIL: 12,
};

export const UserLanguage = {
	ENGLISH: 'English',
	ALBANIAN: 'Albanian',
	ARABIC: 'Arabic',
	SPANISH: 'Spanish',
	GERMAN: 'German',
};

export const TreeHierarchyType = {
	DEFAULT_TREE: 0,
	HOSPITAL_DEPT_FLOOR_ROOM: 1,
	HOSPITAL_DEPT_ROOM: 2,
	HOSPITAL_FLOOR_ROOM: 3,
	HOSPITAL_ROOM: 4,
};

export const MembersType = {
	ALL_MEMBERS: 1,
	AD_MEMBERS: 2,
	NON_AD_MEMBERS: 3,
};

export const DefaultCameraTypes = {
	HELLO: 1,
	HUDDLE: 2,
};

export const MeasureDeviceType = {
	VITAL_KIT: 'vitalKit',
	WATCH: 'watch',
	AI_DETECTIONS: 'aiDetection',
	IOT_DEVICES: 'iHealth',
};

export const LiveExamQRCode = {
	QR_CODE: 'qrCode',
};

export const VisitorType = { FAMILY_MEMBER: 1, VISITOR: 2 };

export const AudioRoutingDeviceTypes = {
	HELLO_1: 1,
	HELLO_2: 2,
	HELLO_2_PLUS: 3,
	HELLO_3: 4,
	PHONE: 5,
	TABLET: 6,
};

export const AudioRoutingOption = {
	ALWAYS_FROM_HELLO: 'always-from-hello',
	TV_PATIENT_NURSE: 'tv-patient-sees-nurse',
	TV_SOURCE_AVAILABLE: 'tv-source-available',
	ALWAYS_PILLOW_SPEAKER: 'always-from-pillow-speaker',
	ALWAYS_FROM_TABLET: 'always-from-tablet',
	ALWAYS_FROM_PHONE: 'always-from-phone',
	EXTERNAL_SPEAKER_DETECTED: 'external-speaker-detected',
};

export const CameraUseOption = {
	SINGLE_CAMERA_USE: 'single-use',
	MULTI_CAMERA_USE: 'multi-use',
};

export const visitStatuses = [
	{ id: 1, description: 'pending' },
	{ id: 2, description: 'invitee' },
	{ id: 3, description: 'notShownUp' },
	{ id: 4, description: 'inProgress' },
	{ id: 5, description: 'completed' },
	{ id: 6, description: 'canceled' },
];

export const SensitivityType = {
	HIGH: '1',
	MEDIUM: '2',
	LOW: '3',
};

export const SensitivityLabel = {
	HIGH: 'high',
	MEDIUM: 'medium',
	LOW: 'low',
};

export const RailType = {
	TOP_LEFT: 'TL',
	TOP_RIGHT: 'TR',
	BOTTOM_LEFT: 'BL',
	BOTTOM_RIGHT: 'BR',
};

export const BodyPositionType = {
	SITTING: 1,
	LYING_LEFT_SIDE: 2,
	LYING_RIGHT_SIDE: 3,
	LYING_FRONT: 4,
	LYING_DOWN: 5,
};

export const PatientPositionSummaryLabels = {
	leftLateral: 'Left Lateral',
	prone: 'Prone',
	rightLateral: 'Right Lateral',
	supine: 'Supine',
	upInAChair: 'Up in a Chair',
};

export const PatientPositionTypes = {
	NA: 0,
	SUPINE: 1,
	PRONE: 3,
	UP_IN_A_CHAIR: 4,
	LEFT_LATERAL: 7,
	RIGHT_LATERAL: 8,
};

export const BodyPositions = {
	[BodyPositionType.LYING_FRONT]: {
		bodyPositionId: BodyPositionType.LYING_FRONT,
		patientPositionId: PatientPositionTypes.SUPINE,
		label: 'Supine',
	},
	[BodyPositionType.LYING_DOWN]: {
		bodyPositionId: BodyPositionType.LYING_DOWN,
		patientPositionId: PatientPositionTypes.PRONE,
		label: 'Prone',
	},
	[BodyPositionType.SITTING]: {
		bodyPositionId: BodyPositionType.SITTING,
		patientPositionId: PatientPositionTypes.UP_IN_A_CHAIR,
		label: 'Up in a Chair',
	},
	[BodyPositionType.LYING_LEFT_SIDE]: {
		bodyPositionId: BodyPositionType.LYING_LEFT_SIDE,
		patientPositionId: PatientPositionTypes.LEFT_LATERAL,
		label: 'Left Lateral',
	},
	[BodyPositionType.LYING_RIGHT_SIDE]: {
		bodyPositionId: BodyPositionType.LYING_RIGHT_SIDE,
		patientPositionId: PatientPositionTypes.RIGHT_LATERAL,
		label: 'Right Lateral',
	},
};

export const ExamType = {
	HEART: 1,
	LUNGS: 2,
};

export const DoctorRequestErrorCodes = {
	DEVICE_OFFLINE: 1,
	INSUFFICIENT_PERMISSION: 2,
	DEVICE_BUSY: 3,
};

export const HttpMethods = {
	GET: 0,
	POST: 1,
	PUT: 2,
	DELETE: 3,
};

export const CompanionTypes = {
	BED_SIDE: 1,
	FRONT_DOOR: 2,
	TV: 3,
	WHITE_BOARD: 4,
};

export const RouteTypes = {
	DEFAULT: 1,
	PRIMARY_CARE: 2,
	GENERAL: 3,
};

export const OtoscopeExaminationPoints = {
	EAR: 1,
	THROAT: 2,
};

export const HealthDataSetupTypes = {
	BIO_BEAT: 'bioBeat',
	WATCH_MEASUREMENTS: 'watchMeasurements',
	LIFE_SIGNALS: 'lifeSignals',
	MINDRAY: 'Mindray',
};

export const SpirometerTypes = {
	FEV1: 'FEV1',
	PEF: 'PEF',
	RES: 'Res.',
};

export const SpirometerUnits = {
	LITER: 'L',
	LITER_MIN: 'L/min',
	PERCENTAGE: '%',
};

export const SpirometerIndex = {
	ZERO: 0,
	ONE: 1,
	TWO: 2,
};

export const ExtendedTrackTypes = {
	IOT_DEVICE_CAMERA: 'video:iotDeviceCamera',
};

export const AiAlertSign = {
	WARNING: 1,
	INFORMATION: 2,
};

export const UserStatus = {
	ONLINE: 1,
	OFFLINE: 2,
};

export const CareMemberType = {
	FAMILY_PHYSICIAN: 1,
	INTERNIST: 2,
	NURSE: 3,
	HOSPITALIST: 4,
	EMERGENCY_PHYSICIAN: 5,
};

export const PatientRpmStatus = {
	INACTIVE: 0,
	ACTIVE: 1,
};

export const LevelOfConsciousness = {
	A: 1,
	VPU: 2,
};

export const SupplementOxygen = {
	NO: 1,
	YES: 2,
};

export const EWSType = {
	LEVEL_OF_CONSCIOUSNESS: 'levelOfConsciousness',
	SUPPLEMENT_OXYGEN: 'supplementOxygen',
};

export const WearablesStatus = {
	INACTIVE: 0,
	ACTIVE: 1,
};

export const WearableProviderIds = {
	LIFE_SIGNALS: 1,
	BIO_BEAT: 2,
};

export const WearableTypes = {
	PATCH: 'patchWearableType',
	WATCH: 'watchWearableType',
};

export const WearableProviders = {
	1: 'LifeSignals',
	2: 'Biobeat',
};

export const WearableTypeIds = {
	LIFE_SIGNALS_PATCH: 1,
	BIO_BEAT_PATCH: 2,
	BIO_BEAT_WATCH: 3,
};

export const IframeIntregrationsIds = {
	AMWELL: 1,
	PHILIPS: 2,
};

export const ExternalIdentityProviders = {
	AZURE: 1,
	PING_FEDERATE: 2,
	OKTA: 3,
	DUO_SSO: 4,
};

export const MemberType = {
	DOCTOR: 1,
	NURSE: 2,
	FAMILY_MEMBER: 3,
};

export const CareMemberTypeValue = {
	[MemberType.DOCTOR]: 'doctor',
	[MemberType.NURSE]: 'nurse',
};

export const PrescriptionUnits = {
	DOSAGE_UNIT_ID: 1,
};

export const RequestType = {
	RAPID: 1,
	ADMISSION: 2,
	REGULAR: 3,
};

export const CallRequestType = {
	RAPID_REQUEST_TYPE: 'rapidRequestType',
	ADMISSION_REQUEST_TYPE: 'admissionRequestType',
	REGULAR_REQUEST_TYPE: 'regularRequestType',
};

export const RequestAcuity = {
	IMMEDIATE: 1,
	TEN_MINUTES: 2,
	THIRTY_MINUTES: 3,
	SIXTY_MINUTES: 4,
	ROUNDING: 5,
};

export const PatientQueuedRequestStatus = {
	PENDING: 1,
	ENQUEUED_TO_MEDIC: 2,
	CANCELLED: 3,
};

export const VoiceOverAlertTypes = {
	GENERIC: 'generic',
};

export const StatAlarmType = {
	ALARM_1: 'sound-1',
	ALARM_2: 'sound-2',
};

export const AnalyticsCategory = {
	PATIENT_POSITION: 1,
	IV_BAG_FLUID_LEVEL: 2,
};

export const AssignedRequestTypes = {
	WITH_INTAKE_FORM: 1,
	WITHOUT_INTAKE_FORM: 2,
};

export const ButtonType = {
	SUBMIT: 1,
	CANCEL: 2,
};

export const AuthenticationOptions = {
	DEFAULT: 'none',
	EXTERNAL_AUTHENTICATOR: 'external-authenticator',
	EMAIL: 'email',
};

export const NotesType = {
	SITTER: 1,
	PATIENT: 2,
};

export const CameraDirections = {
	UP: 'up',
	RIGHT: 'right',
	LEFT: 'left',
	DOWN: 'down',
};

export const AiTab = {
	SETUP: 0,
	GENERAL: 1,
	HISTORY: 2,
};

export const PatientPositionTab = {
	CURRENT_POSITION: 0,
	HISTORY: 1,
	STATS: 2,
};

export const AiAlertId = {
	RAILS: 1,
	PATIENT_SITTING: 2,
	PATIENT_STANDING: 3,
	PATIENT_FACE_DOWN: 4,
	PATIENT_LYING_SIDE: 5,
	PATIENT_LYING_FRONT: 6,
	PATIENT_LYING_RIGHT_SIDE: 7,
	FALL_DETECTION: 8,
	PATIENT_INACTIVE: 9,
	PATIENT_GETTING_OUT_OF_BED: 10,
	PATIENT_MOBILITY: 11,
	PATIENT_LYING_LEFT_SIDE: 12,
	HANDS_DISINFECTED: 13,
	LOW_IV_BAG: 14,
	PATIENT_GETTING_OUT_OF_CHAIR: 15,
	PATIENT_INACTIVE_INFORMATION: 16,
	HANDS_NOT_DISINFECTED: 17,
	SNEEZING: 18,
	SNORING: 19,
	COUGHING: 20,
	CRYING_BABY: 21,
	SCREAMING: 22,
	HELP: 23,
	LOW_PRIORITY_ALARM: 24,
	MEDIUM_PRIORITY_ALARM: 25,
	HIGH_PRIORITY_ALARM: 26,
	ALARM_OFF: 27,
	VITAL_SIGN_ALARM: 28,
};

export const MeasurementAlertId = {
	BLOOD_GLUCOSE_HIGH: 1,
	HEART_RATE_HIGH: 2,
	HEART_RATE_LOW: 3,
	BODY_TEMPERATURE_LOW: 4,
	BODY_TEPERATURE_HIGH: 5,
	BODY_TEMPERATURE_CRITICAL_HIGH: 6,
	BMI_LOW: 7,
	BMI_HIGH: 8,
	BMI_EXTREMELY_HIGH: 9,
	BLOOD_PRESSURE_SYSTOLIC_LOW: 10,
	BLOOD_PRESSURE_DIASTOLIC_LOW: 11,
	BLOOD_PRESSURE_SYSTOLIC_CRITICAL_LOW: 12,
	BLOOD_PRESSURE_DIASTOLIC_CRITICAL_LOW: 13,
	BLOOD_PRESSURE_SYSTOLIC_HIGH: 14,
	BLOOD_PRESSURE_DIASTOLIC_HIGH: 15,
	BLOOD_PRESSURE_SYSTOLIC_CRITICAL_HIGH: 16,
	BLOOD_PRESSURE_DIASTOLIC_CRITICAL_HIGH: 17,
	OXYGEN_SATURATION_LOW: 18,
	OXYGEN_SATURATION_CRITICAL_LOW: 19,
};

export const AiAlertsDetails = [
	{
		id: AiAlertId.RAILS,
		name: AiAlertType.RAILS,
		color: 'rgba(90, 171, 255, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_SITTING,
		name: 'sitting',
		color: 'rgba(90, 171, 255, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_STANDING,
		name: 'standing',
		color: 'rgba(23, 190, 207, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_LYING_SIDE,
		name: 'lateral',
		color: 'rgba(255, 181, 51, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_LYING_FRONT,
		name: 'supine',
		color: 'rgba(241, 94, 94, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_FACE_DOWN,
		name: 'prone',
		color: 'rgba(51, 194, 127, 0.8)',
	},
	{
		id: AiAlertId.FALL_DETECTION,
		name: 'fallDetection',
		color: 'rgba(190, 213, 255, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_INACTIVE,
		name: 'inactive',
		color: 'rgba(31, 119, 180, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_GETTING_OUT_OF_BED,
		name: 'getOutOfBed',
		color: 'rgba(210, 141, 185, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_MOBILITY,
		name: 'patientMobility',
		color: 'rgba(214, 39, 40, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_LYING_LEFT_SIDE,
		name: 'leftLateral',
		color: 'rgba(255, 0, 255, 0.8)',
	},
	{
		id: AiAlertId.LOW_IV_BAG,
		name: 'lowIvBagFluidLevel',
		color: 'rgba(107, 198, 181, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_GETTING_OUT_OF_CHAIR,
		name: 'patientGettingOutOfChair',
		color: 'rgba(98, 211, 253, 0.8)',
	},
	{
		id: AiAlertId.PATIENT_LYING_RIGHT_SIDE,
		name: 'rightLateral',
		color: 'rgba(217, 232, 246, 0.8)',
	},
];

export const HealthDataAlertsDetails = [
	{ id: HealthDataField.HIGH_BLOOD_GLUCOSE, name: 'highBloodGlucose', color: '#D9E8F6' },
	{ id: HealthDataField.HIGH_HEART_RATE, name: 'highHeartRate', color: '#5AABFF' },
	{ id: HealthDataField.LOW_HEART_RATE, name: 'lowHeartRate', color: '#5AABFF' },
	{ id: HealthDataField.LOW_BODY_TEMPERATURE, name: 'lowBodyTemperatureDescription', color: '#5390FF' },
	{ id: HealthDataField.HIGH_BODY_TEMPERATURE, name: 'highBodyTemperatureDescription', color: '#5390FF' },
	{ id: HealthDataField.CRITICAL_HIGH_BODY_TEMPERATURE, name: 'criticalHighTemperature', color: '#5390FF' },
	{ id: HealthDataField.LOW_BMI, name: 'thelowBMIUnderweight', color: '#6BC6B5' },
	{ id: HealthDataField.HIGHT_BMI, name: 'thehighBMI', color: '#6BC6B5' },
	{ id: HealthDataField.CRITICAL_HIGH_BMI, name: 'criticalHighBMI', color: '#6BC6B5' },
	{ id: HealthDataField.LOW_SYSTOLIC, name: 'systolicLowBloodPressure', color: '#BED5FF' },
	{ id: HealthDataField.LOW_DIASTOLIC, name: 'diastolicLowBloodPressure', color: '#BED5FF' },
	{ id: HealthDataField.CRITICAL_LOW_SYSTOLIC, name: 'systolicCriticalLowBloodPressure', color: '#BED5FF' },
	{ id: HealthDataField.CRITICAL_LOW_DIASTOLIC, name: 'diastolicCriticalLowBloodPressure', color: '#BED5FF' },
	{ id: HealthDataField.HIGH_SYSTOLIC, name: 'systolicHighBloodPressure', color: '#BED5FF' },
	{ id: HealthDataField.HIGH_DIASTOLIC, name: 'diastolicHighBloodPressure', color: '#BED5FF' },
	{ id: HealthDataField.LOW_OXYGEN, name: 'lowOxygenSaturation', color: '#8383F4' },
	{ id: HealthDataField.CRITICAL_LOW_OXYGEN, name: 'criticalLowOxygenSaturation', color: '#8383F4' },
];

export const CallStatsLogType = {
	INFORMATION: 1,
	WARNING: 2,
	ERROR: 3,
};

export const SessionType = {
	VIDEO_MONITORING: 0,
	AMBIENT_MONITORING: 1,
	VIDEO_AMBIENT_MONITORING: 2,
};

export const TakePictureButtonState = {
	TAKE_PICTURE: 'takePicture',
	SAVING: 'saving',
	SAVED: 'saved',
	FAILED: 'savingFailed',
};

export const AiAlertNotificationType = {
	TOAST_MESSAGE: 1,
	MODAL_ON_FEED: 2,
	STAT_ALARM_HELLO: 3,
	STAT_ALARM_DIGITAL_ROOM_SIGN: 4,
	SILENT_MODE: 5,
	MOBILE_APP: 6,
};

export const ExcludedAiSettings = [
	PatientAiSetting.AI_DRAWING,
	PatientAiSetting.SOUND_ON_AI_ALERT,
	PatientAiSetting.HAND_WASHING,
];

export const ExcludedAiSettingsHello3 = [
	...ExcludedAiSettings,
	PatientAiSetting.FALL_PREVENTION,
	PatientAiSetting.FALL_DETECTED,
	PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
	PatientAiSetting.RAILS,
	PatientAiSetting.PATIENT_MOBILITY,
	PatientAiSetting.PRESSURE_INJURY,
	PatientAiSetting.SKELETON_PRIVATE_MODE,
];

export const IncludedAiSettings = [PatientAiSetting.VITAL_SIGNS_AI, PatientAiSetting.IV_BAG];

export const ProcedureTypes = [{ id: 1, value: 'CPT_4' }];
export const DiagnosisTypes = [{ id: 1, value: 'ICD_10_CM' }];

export const DeviceConnectionType = {
	ETHERNET: 0,
	WIRELESS: 1,
};

export const DeviceConnectionStatus = { ONLINE: 1, OFFLINE: 2 };

export const ObservationType = {
	TOBACCO_SMOKE: 1,
	PAIN_MANAGEMENT: 2,
	LAB_RESULT: 3,
	PATIENT_LOGS: 4,
	LABOR: 5,
	BRADEN_SCORE: 7,
	PRIMARY_PATIENT_RISK_FACTOR: 15,
	PRIMARY_ADVERSE_EVENT: 16,
	ADDITIONAL_PATIENT_NOTES: 17,
	ADDITIONAL_TITLE_BAR_NOTES: 18,
};

export const DeviceSubscriptionType = {
	DEVICE_OFFLINE: 1,
};

export const ConditionType = {
	FALL_RISK: 1,
	DIABETES: 2,
	PRECAUTION: 3,
	ISOLATIONS: 4,
	PRIMARY_PATIENT_CONDITION: 8,
};

export const TalkToPatient = {
	AUDIO_ONLY: 'audio-only',
	AUDIO_VIDEO: 'audio-video',
};

export const AccessTypes = {
	DENIED: 'denied',
	GRANTED: 'granted',
	DEFAULT: 'default',
};

export const WhiteboardWidgets = {
	CARE_TEAM: 1,
	GOING_HOME: 7,
	DIET: 8,
	ACTIVITY: 9,
	PAIN_MED: 10,
	QUESTION: 11,
	SAFETY_ALERTS: 12,
	NEW_MEDICATIONS: 13,
	WHAT_MATTERS: 14,
	TODAYS_PLAN: 15,
	PAIN_LEVEL: 16,
	BRADEN_SCORE: 17,
	FALL_RISK: 18,
	DAILY_WEIGHT: 25,
};

export const BoardTypes = [
	{ value: 1, label: 'Critical Care Unit' },
	{ value: 2, label: 'Family Birthing Center' },
	{ value: 3, label: 'Post Partum' },
	{ value: 4, label: 'Acute Rehab' },
	{ value: 5, label: 'Emergency Department' },
];

export const DietTypes = {
	NPO: 'NPO',
	CLEAR_LIQUID_DIET: 'Clear liquid diet',
	BARIATRIC_DIET: 'Bariatric diet',
	FULL_LIQUID_DIET: 'Full liquid diet',
	HEART_HEALTHY: 'Heart Healthy',
	DIABETIC_DIET: 'Diabetic diet',
	REGULAR_DIET: 'Regular diet',
	DYSPHAGIA_DIET: 'Dysphagia diet',
	RENAL_DIET: 'Renal diet',
	LOW_SODIUM_DIET: 'Low Sodium Diet',
	FLUID_RESTRICTIONS: 'Fluid restrictions',
	OTHER: 'Other',
};

export const DietTypesId = {
	NPO: 1,
	CLEAR_LIQUID_DIET: 2,
	BARIATRIC_DIET: 3,
	FULL_LIQUID_DIET: 4,
	HEART_HEALTHY: 5,
	DIABETIC_DIET: 6,
	REGULAR_DIET: 7,
	DYSPHAGIA_DIET: 8,
	RENAL_DIET: 9,
	LOW_SODIUM_DIET: 10,
	FLUID_RESTRICTIONS: 11,
	OTHER: 12,
};

export const RTCStatsType = {
	OUTBOUND: 'outbound-rtp',
	INBOUND: 'inbound-rtp',
	TRACK: 'track',
	CODEC: 'codec',
	REMOTE_CANDIDATE: 'remote-candidate',
};

export const RTCMediaType = {
	AUDIO: 'audio',
	VIDEO: 'video',
};
export const AlertCategory = {
	MEASUREMENT: 'measurement',
	FALL_DETECTION: 'fallDetection',
	RAILS: 'railDown',
	GETTING_OUT_OF_BED: 'gettingOutOfBed',
	FALL_PREVENTION: 'fallPrevention',
	IV_BAG: 'ivBag',
	PATIENT_MOBILITY: 'patientMobility',
	PRESSURE_INJURY: 'pressureInjury',
	AI_VOICE_ANALYSIS: 'aiVoiceAnalysis',
};

export const AlertCategoryMapping = {
	[PatientAiSetting.PRESSURE_INJURY]: AlertCategory.PRESSURE_INJURY,
	[PatientAiSetting.HAND_WASHING]: null,
	[PatientAiSetting.VITAL_SIGNS_AI]: AlertCategory.MEASUREMENT,
	[PatientAiSetting.IV_BAG]: AlertCategory.IV_BAG,
	[PatientAiSetting.FALL_DETECTED]: AlertCategory.FALL_DETECTION,
	[PatientAiSetting.PATIENT_GETTING_OUT_OF_BED]: AlertCategory.GETTING_OUT_OF_BED,
	[PatientAiSetting.RAILS]: AlertCategory.RAILS,
	[PatientAiSetting.PATIENT_MOBILITY]: AlertCategory.PATIENT_MOBILITY,
	[PatientAiSetting.FALL_PREVENTION]: AlertCategory.FALL_PREVENTION,
	[PatientAiSetting.AI_VOICE_ANALYSIS]: AlertCategory.AI_VOICE_ANALYSIS,
};

export const DateRangePresets = {
	TODAY: { id: 1, value: null },
	YESTERDAY: { id: 2, value: null },
	LAST_7_DAYS: { id: 3, value: 7 },
	LAST_30_DAYS: { id: 4, value: 30 },
	LAST_90_DAYS: { id: 5, value: 90 },
	LAST_MONTH: { id: 6, value: null },
	LAST_YEAR: { id: 7, value: null },
	MONTH_TO_DATE: { id: 8, value: null },
	QUARTER_TO_DATE: { id: 9, value: null },
	YEAR_TO_DATE: { id: 10, value: null },
	FIRST_QUARTER: { id: 11, value: 1 },
	SECOND_QUARTER: { id: 12, value: 2 },
	THIRD_QUARTER: { id: 13, value: 3 },
	FOURTH_QUARTER: { id: 14, value: 4 },
	CUSTOM_RANGE: { id: 15, value: null },
};

export const StatusEnum = {
	STATUS: 'Status',
	ENROLLMENT: 'Enrollment',
};

export const DayPeriodTypes = {
	AM: 1,
	PM: 2,
};

export const HealthDataFilterTabs = {
	DAILY: 0,
	WEEKLY: 1,
	MONTHLY: 2,
	QUARTERLY: 3,
};

export const AverageTypes = {
	DAILY: 1,
	WEEKLY: 2,
	MONTHLY: 3,
	QUARTERLY: 4,
};

export const Range = {
	START: 'start',
	END: 'end',
};

export const CareTeam = {
	NURSE: 'nurse',
	NURSE_PHONE: 'nursePhone',
	PCA: 'pca',
	PCA_PHONE: 'pcaPhone',
};

export const PatientUnassignedTypes = {
	DISCHARGE: 1,
	TRANSFER: 2,
};

export const TvSettingTypes = {
	POWER: 'power',
	DISPLAY: 'display',
	VOLUME: 'volume',
	INPUTSOURCE: 'inputsource',
	PROTOCOL: 'protocol',
};

export const TvSettingTypesId = {
	POWER: 1,
	DISPLAY: 2,
	VOLUME: 3,
	INPUTSOURCE: 4,
	PROTOCOL: 5,
};

export const TvSettingIndex = {
	WHITEBOARD: 2,
};

export const ConferenceSocketEventKeys = {
	TRACK_TOGGLE: 'conference.trackToggle',
};

export const CompanionModeId = {
	BED_SIDE: 1,
	ROOM_SIGN: 2,
	TV: 3,
	WHITEBOARD: 4,
};

export const ConferenceInviteStatus = {
	OK: 0,
	NOT_ACTIVE: 1,
	INVALID_PARAMETERS: 2,
	INVALID_LINK: 3,
	INVALID_INVITATION: 4,
};

export const OutOfRoomEnums = {
	PATIENT_OUT_OF_ROOM: 'Patient Out of Room',
	PATIENT_RETURNED_TO_ROOM: 'Patient Returned to Room',
};

export const ScreenDisplayOrientation = {
	PORTRAIT: 1,
	LANDSCAPE: 2,
	PORTRAIT_REVERSED: 3,
};

export const PatientPositionsLabels = {
	0: 'N/A',
	1: 'Left Lateral Position',
	2: 'Right Lateral Position',
	3: 'Prone Position',
	4: 'Supine Position',
	5: 'Sitting Position',
};

export const PressureInjuryStatsColor = {
	leftLateral: '#5390FF',
	rightLateral: '#87A5DD',
	prone: '#6BC6B5',
	supine: '#BED5FF',
	upInAChair: '#62D3FD',
};

export const HandHygieneLabels = {
	compliant: 'Compliant',
	nonCompliant: 'Non Compliant',
};

export const HandHygieneIdentificationLabels = {
	doctor: 'Doctor',
	pca: 'PCA',
	rn: 'RN',
	other: 'Other',
};

export const HospitalLogoType = {
	PRIMARY: 'primary-picture',
	SECONDARY_DISPLAY: 'secondary-picture',
};

export const EventSubscriberRoomType = {
	AI_EVENTS: 7,
	USER_STATUS: 8,
	DEVICE_STATUS: 9,
	PATIENT_EVENTS: 10,
};

export const VitalsAlertTypes = {
	LOW: 'LOW',
	MEDIUM: 'MEDIUM',
	HIGH: 'HIGH',
};
