import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { actionCreators as healthSystemsActions } from 'state/healthSystems/actions.js';
import {
	SectorTypes,
	DeviceListLevel,
	MediaPermissions,
	StreamError,
	ObjectType,
	MediaTypes,
	UserRoles,
	StatAlarmType,
	AiAlertActivityType,
} from 'constants/enums.js';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import AlertFeed from 'components/AlertFeedMayo.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import Modal from 'components/Modal.jsx';
import Alert from 'components/Alert.jsx';
import Notification from 'components/Notification.jsx';
import { Prompt } from 'react-router-dom';
import AmbientMonitoringAlertModal from 'calls/components/AmbientMonitoringAlertModal.jsx';
import _ from 'lodash';
import {
	checkForPermission,
	findSectorById,
	findDeviceById,
	getConfigurationValue,
	updateConfigsList,
	getConfigurationVariant,
} from 'infrastructure/helpers/commonHelpers.js';
import {
	getAlertingSessions,
	getAlertingSessionDevices,
	saveAlertingSession,
	subscribeToDeviceAlerts,
	unsubscribeFromAllDeviceAlerts,
	unsubscribeFromDeviceAlerts,
	updateAlertingSessionDevices,
	deleteAlertingSession,
} from 'api/alert-center.js';
import { getDeviceList } from 'api/devices.js';
import { getDeviceOwnerPatient } from 'api/patients.js';
import styled from 'styled-components';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import ConferenceModal from 'calls/views/ConferenceModal.jsx';
import { outGoingCallSound } from 'components/CallSounds.jsx';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getTeams } from 'api/teams.js';
import { forwardAiAlert } from 'api/alerts.js';
import { getLanguagePrefix } from 'infrastructure/helpers/alertCenterHelper.js';
import {
	MonitoringSettings,
	SettingsCategory,
	UserSettingTypes,
	configurableMonitoringMenu,
} from 'constants/configurationEnums.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import SectorListMayo from 'containers/ SectorListMayo.jsx';
import useScreenType from 'hooks/useScreenType.js';

const AlertCenter = () => {
	const healthSystems = useSelector(state => state.healthSystems);
	const user = useSelector(state => state.user);
	const [alertFeeds, setAlertFeeds] = useState([]);
	const [alertingSessions, setAlertingSessions] = useState([
		{ id: 0, name: translate('currentSession'), isActive: true, isCurrent: true },
	]);
	const [showSessionPopup, setShowSessionPopup] = useState(false);
	const [currentSessionId, setCurrentSessionId] = useState(null);
	const [newSessionName, setNewSessionName] = useState('');
	const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);
	const [sessionToDelete, setSessionToDelete] = useState(null);
	const [isSessionNotificationVisible, setIsSessionNotificationVisible] = useState(false);
	const [errorFetchingSessions, setErrorFetchingSessions] = useState('');
	const [isSessionLoading, setIsSessionLoading] = useState(false);
	const [sessionIsBeingSaved, setSessionIsBeingSaved] = useState(false);
	const [currentSector, setCurrentSector] = useState(null);
	const [error, setError] = useState('');
	const [selectedFeed, setSelectedFeed] = useState(null);
	const [isObserverConferenceModalOpen, setIsObserverConferenceModalOpen] = useState(false);
	const [hasObserverConferenceStarted, setHasObserverConferenceStarted] = useState(null);
	const [isAmbientMonitoringAlertModalOpen, setIsAmbientMonitoringAlertModalOpen] = useState(false);
	const [observerConferenceData, setObserverConferenceData] = useState({
		participants: [],
		conferenceName: '',
		callType: null,
		roomId: null,
	});
	const [channels, setChannels] = useState([]);
	const [statAlarmSrc, setStatAlarmSrc] = useState(`${healthCareCdnUrl}admin/super-admin/stat-alarms/alarm-1.mp3`);

	const intl = useIntl();
	const dispatch = useDispatch();
	const userInfoRef = useRef(getUserInfo());
	const socket = useContext(SocketContext);
	const currentSession = useRef({ id: 0, name: translate('currentSession'), isActive: true, isCurrent: true });
	const micStatus = useRef(null);
	const playingAlert = useRef(new Audio());
	const [showForwardToNurses, setShowForwardToNurses] = useState(false);
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));
	const screenType = useScreenType();

	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	useEffect(() => {
		const toggleIsFullScreen = () => {
			dispatch(healthSystemsActionCreators.toggleFullscreen());
		};

		document.addEventListener('fullscreenchange', toggleIsFullScreen);
		return () => {
			document.removeEventListener('fullscreenchange', toggleIsFullScreen);
		};
	}, [dispatch]);

	const onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			healthSystemsActionCreators.setStreamPermissionMessage(null);
		}
	};

	useEffect(() => {
		const statAlarmCdnUrl = `${healthCareCdnUrl}admin/super-admin/stat-alarms/alarm-`;
		const availableAlarms = [
			{ label: `${intl.formatMessage({ id: 'alarm' })} 1`, id: StatAlarmType.ALARM_1, url: `${statAlarmCdnUrl}1.mp3` },
			{ label: `${intl.formatMessage({ id: 'alarm' })} 2`, id: StatAlarmType.ALARM_2, url: `${statAlarmCdnUrl}2.mp3` },
		];

		const statAlarmVariant = getConfigurationVariant(companyConfigurations[MonitoringSettings.StatAlarm]);
		if (statAlarmVariant && statAlarmVariant !== statAlarmSrc && availableAlarms.find(item => item.id === statAlarmVariant)) {
			setStatAlarmSrc(availableAlarms.find(item => item.id === statAlarmVariant).url);
		}
	}, [companyConfigurations, intl, statAlarmSrc]);

	useEffect(() => {
		if (alertFeeds.find(item => item.warning?.value) && !alertFeeds.find(item => item.isStatAlarmActive)) {
			const soundUrl = `${healthCareCdnUrl}sound/${getLanguagePrefix()}patient-at-risk.mp3`;

			if (!playingAlert.current?.src || playingAlert.current?.src !== soundUrl) {
				playingAlert.current.src = `${healthCareCdnUrl}sound/${getLanguagePrefix()}patient-at-risk.mp3`;
				playingAlert.current.loop = true;
				// playingAlert.current.load();
				playingAlert.current.play().catch(e => console.error('Error playing audio:', e));
			}
		}
	}, [alertFeeds]);

	useEffect(() => {
		const onAiAlertActivity = data => {
			if (!data || data?.aiAlertActivityTypeId !== AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER) {
				return;
			}
			const newVideoFeeds = alertFeeds.map(item => {
				const newItem = { ...item };
				if (newItem.deviceId === data.deviceId && newItem.intervention) {
					newItem.intervention.value = true;
					newItem.intervention.providerFullName = data.causerFullName;
					newItem.intervention.providerProfilePicture = data.causerProfilePicture;
				}
				return newItem;
			});
			setAlertFeeds(newVideoFeeds);
		};
		socket.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, onAiAlertActivity);
		return () => {
			socket.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, onAiAlertActivity);
		};
	}, [socket, alertFeeds]);

	useEffect(() => {
		const onStatAlarm = result => {
			if (alertFeeds.find(item => item.deviceId === result.deviceId) && result.isSuccessful) {
				const newAlertFeeds = alertFeeds.map(item => {
					const newItem = { ...item };
					if (item.deviceId === result.deviceId) {
						newItem.isStatAlarmActive = result.data;
						if (result.data) {
							newItem.warning = {};
						}
					}
					return newItem;
				});

				if (!playingAlert?.current) {
					playingAlert.current = new Audio();
				}

				if (playingAlert?.current && result.data) {
					playingAlert.current.src = statAlarmSrc;
					playingAlert.current.pause();
					playingAlert.current.currentTime = 0;
					playingAlert.current.loop = true;
					playingAlert.current.play().catch(e => console.error('Error playing audio:', e));
				}

				if (
					playingAlert?.current &&
					!result.data &&
					!newAlertFeeds.find(item => item.isStatAlarmActive) &&
					result.shouldForward
				) {
					playingAlert.current.currentTime = 0;
					playingAlert.current.loop = false;
					playingAlert.current.pause();
				}
				setAlertFeeds(newAlertFeeds);
			}
		};

		socket.on(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE, onStatAlarm);
		return () => {
			socket.off(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE, onStatAlarm);
		};
	}, [alertFeeds, socket, statAlarmSrc]);

	useEffect(() => {
		const currentAudio = playingAlert.current;
		return () => {
			if (currentAudio) {
				currentAudio.pause();
				currentAudio.currentTime = 0;
			}
		};
	}, []);

	useEffect(() => {
		const getMicStatus = async () => {
			micStatus.current = await checkForPermission(MediaTypes.MICROPHONE);
			micStatus.current.onchange = onDevicePermissionChange;
		};
		getMicStatus();
	}, []);

	useEffect(() => {
		fetchTeams();
	}, []);

	const stopVoiceOver = (alertTypeId, deviceId) => {
		const newFeeds = alertFeeds.map(item => {
			const newItem = item;
			if (newItem.deviceId === deviceId && alertTypeId === newItem.warning.alertTypeId) {
				newItem.warning = {};
				newItem.isStatAlarmActive = false;
			}
			return newItem;
		});
		if (!newFeeds.find(item => item.warning?.alertTypeId || item.isStatAlarmActive)) {
			if (playingAlert?.current) {
				playingAlert.current.pause();
				playingAlert.current.currentTime = 0;
				playingAlert.current.src = '';
				playingAlert.current = null;
				playingAlert.current = new Audio();
			}
		}
		setAlertFeeds(newFeeds);
	};

	const getMappedFeed = (deviceId, isFromSession = false) => {
		const sector = findSectorById(healthSystems.treeData.tree, deviceId);
		if (!sector || (!isFromSession && alertFeeds.some(feed => feed.deviceId === deviceId))) {
			return null;
		}
		const { roomId, hospitalId, departmentId, floorId, name, aiPrivacyStatus } = sector;
		return {
			deviceId,
			events: [],
			warning: {},
			roomName: name,
			roomId,
			hospitalId,
			departmentId,
			floorId,
			deviceOwner: {},
			aiPrivacyStatus,
			hospitalName: name,
			lastCondition: { code: '', display: '', risk: '' },
			alertInfo: {},
			healthSystemId: user.userSession.healthSystem.id,
			intervention: {},
		};
	};

	const fetchAlertingSessions = async () => {
		const params = {
			healthSystemId: user.userSession.healthSystem.id,
			userId: userInfoRef.current.id,
		};
		const alertingSessionsRes = await getAlertingSessions(params);

		if (alertingSessionsRes.error) {
			setErrorFetchingSessions(intl.formatMessage({ id: 'failedToLoad' }));
		} else {
			setAlertingSessions([...alertingSessionsRes.userSavedDeviceAlertSubscriptions, currentSession.current]);
		}
	};

	const setActiveSession = async session => {
		setAlertingSessions(prevState => prevState.map(item => ({ ...item, isActive: item.id === session.id })));
	};

	const closeFeeds = async () => {
		const params = {
			healthSystemId: user.userSession.healthSystem.id,
			userId: userInfoRef.current.id,
		};
		const unsubscribeFromAllDeviceAlertsRes = await unsubscribeFromAllDeviceAlerts(params);
		if (unsubscribeFromAllDeviceAlertsRes.error) {
			setError(unsubscribeFromAllDeviceAlertsRes.error.message);
			return;
		}
		if (playingAlert?.current) {
			playingAlert.current.pause();
			playingAlert.current.currentTime = 0;
			playingAlert.current.src = '';
			playingAlert.current = null;
			playingAlert.current = new Audio();
		}
		setAlertFeeds([]);
		setSelectedFeed(null);
	};

	const closeCurrentSession = (activateCurrentSession, clickedSession) => {
		if ((alertFeeds.length === 0 || (clickedSession && !clickedSession.isActive)) && !currentSessionId) {
			return;
		}
		closeFeeds();
		setCurrentSessionId(null);
		setNewSessionName('');
		if (activateCurrentSession) {
			const isCurrent = alertingSessions.find(session => session.isCurrent);
			setActiveSession(isCurrent);
		}
	};

	const setSessionName = event => {
		setNewSessionName(event.target.value);
	};

	const toggleSessionModal = isOpen => {
		setShowSessionPopup(isOpen);
	};

	const toggleDeleteSessionModal = session => {
		if (session && (session.isCurrent || !session.isActive)) {
			return;
		}
		setShowDeleteSessionModal(prevState => !prevState);
		setSessionToDelete(session);
	};

	const deleteSession = async session => {
		const sessionData = {
			healthSystemId: user.userSession.healthSystem.id,
			userId: userInfoRef.current.id,
			savedSubscriptionId: session.id,
		};
		const deleteAlertingSessionRes = await deleteAlertingSession(sessionData);
		if (deleteAlertingSessionRes.error) {
			setError(deleteAlertingSessionRes.error.message);
			return;
		}
		const params = {
			healthSystemId: user.userSession.healthSystem.id,
			userId: userInfoRef.current.id,
		};
		const alertingSessionsRes = await getAlertingSessions(params);
		if (alertingSessionsRes.error) {
			setError(alertingSessionsRes.error.message);
			return;
		}
		setAlertFeeds([]);
		setAlertingSessions([...alertingSessionsRes.userSavedDeviceAlertSubscriptions, currentSession.current]);
		if (currentSessionId === session.id) {
			setCurrentSessionId(null);
		}
		toggleDeleteSessionModal(null);
		closeCurrentSession(true);
	};

	const openSession = async (event, session) => {
		event.preventDefault();
		if (session.isActive || isSessionLoading) {
			return;
		}
		if (alertFeeds.length > 0 && currentSessionId) {
			setCurrentSessionId(null);
			const params = {
				healthSystemId: user.userSession.healthSystem.id,
				userId: userInfoRef.current.id,
			};
			const unsubscribeFromAllDeviceAlertsRes = await unsubscribeFromAllDeviceAlerts(params);
			if (unsubscribeFromAllDeviceAlertsRes.error) {
				setError(unsubscribeFromAllDeviceAlertsRes.error.message);
				return;
			}
			setAlertFeeds([]);
			closeFeeds();
			setNewSessionName('');
		}
		setActiveSession(session);
		setIsSessionLoading(true);
		if (!session.id) {
			setIsSessionLoading(false);
			return;
		}
		const params = {
			healthSystemId: user.userSession.healthSystem.id,
			userId: userInfoRef.current.id,
			savedSubscriptionId: session.id,
		};
		const devicesRes = await getAlertingSessionDevices(params);
		if (devicesRes.error) {
			setError(devicesRes.error.message);
			setIsSessionLoading(false);
			return;
		}
		const devices = devicesRes.deviceAlertSubscriptions;
		devices.forEach(item => {
			const mappedResponse = {
				deviceId: item.deviceId,
				settings: item?.aiSettings?.length > 0 ? item?.aiSettings : [],
			};
			dispatch(aiSettingsActionCreators.setPatientAiSettings(mappedResponse));
		});
		const feeds = devices.reduce((result, item) => {
			const feed = getMappedFeed(item.deviceId, true);
			if (feed) {
				result.push(feed);
			}
			return result;
		}, []);

		setCurrentSessionId(session.id);
		setAlertFeeds(feeds);
		setIsSessionLoading(false);
		setError(null);
		setSelectedFeed(null);
	};

	const updateSession = async sessionId => {
		const params = {
			healthSystemId: user.userSession.healthSystem.id,
			userId: userInfoRef.current.id,
			savedSubscriptionId: sessionId,
		};
		const response = await updateAlertingSessionDevices(params);
		if (!response.error) {
			showSessionUpdatedNotification();
		} else {
			setError(response.error.message);
		}
	};

	const showSessionUpdatedNotification = () => {
		setIsSessionNotificationVisible(true);
	};

	const saveSession = session => {
		if (alertFeeds.length === 0 || (session && !session.isActive)) {
			return;
		}
		if (currentSessionId) {
			updateSession(currentSessionId);
		} else {
			toggleSessionModal(true);
		}
	};

	const createNewSession = async name => {
		if (!name || (name && name.trim() === '') || sessionIsBeingSaved) {
			return;
		}
		setSessionIsBeingSaved(true);
		const sessionData = {
			name: name,
			healthSystemId: user.userSession.healthSystem.id,
			userId: userInfoRef.current.id,
		};
		const response = await saveAlertingSession(sessionData);
		if (response?.error) {
			setError(response.error.message);
			setSessionIsBeingSaved(false);
			return;
		}
		toggleSessionModal(false);
		const params = {
			healthSystemId: user.userSession.healthSystem.id,
			userId: userInfoRef.current.id,
		};
		const getAlertingSessionsRes = await getAlertingSessions(params);
		if (getAlertingSessionsRes.error) {
			setErrorFetchingSessions(intl.formatMessage({ id: 'failedToLoad' }));
			setSessionIsBeingSaved(false);
			return;
		}
		const sessions = getAlertingSessionsRes.userSavedDeviceAlertSubscriptions;
		const savedSession = sessions[0];
		savedSession.isActive = true;
		const updatedCurrentSession = { ...currentSession.current };
		updatedCurrentSession.isActive = false;
		setAlertingSessions([...sessions, updatedCurrentSession]);
		setCurrentSessionId(savedSession.id);
		setSessionIsBeingSaved(false);
	};

	const handleTreeNodeClick = async option => {
		let isRoomNameEditable = false;
		setCurrentSector({ ...option, isRoomNameEditable });
	};

	const onAddDevice = async selection => {
		const maxNumberOfFeeds = 100;
		const { helloDeviceId } = selection;
		const selectedFeed = alertFeeds.find(item => item.helloDeviceId === helloDeviceId);
		if (selectedFeed || alertFeeds.length === maxNumberOfFeeds) {
			return;
		}
		const feed = getMappedFeed(helloDeviceId);
		if (feed) {
			let isDefaultOwner = false;
			const devicesResponse = await getDeviceList(DeviceListLevel.ROOM, feed.roomId);
			if (devicesResponse.error) {
				setError(devicesResponse.error.message);
			} else {
				isDefaultOwner = devicesResponse[0].isDefaultOwner;
			}
			let params = {
				healthSystemId: feed.healthSystemId,
				userId: userInfoRef.current.id,
				deviceId: feed.deviceId,
				patientId: null,
			};
			if (!isDefaultOwner) {
				let deviceOwner = {};
				const deviceOwnerResponse = await getDeviceOwnerPatient(helloDeviceId);
				if (deviceOwnerResponse.error) {
					setError(deviceOwnerResponse.error.message);
				} else {
					deviceOwner = deviceOwnerResponse;
					feed.deviceOwner = deviceOwner;
					params = {
						healthSystemId: feed.healthSystemId,
						userId: userInfoRef.current.id,
						deviceId: feed.deviceId,
						patientId: feed.deviceOwner?.healthcareUserId,
					};
				}
			}

			feed.isDefaultOwner = isDefaultOwner;
			const subscribeResponse = await subscribeToDeviceAlerts(params);
			if (subscribeResponse.error) {
				if (subscribeResponse.error.response.data.code === 8005) {
					setError(intl.formatMessage({ id: 'maxAmbientMonitoringProviders' }));
				} else {
					setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				}
				return;
			}

			if (subscribeResponse?.patientAiSettings?.length > 0) {
				const mappedResponse = {
					deviceId: helloDeviceId,
					settings: subscribeResponse?.patientAiSettings?.length > 0 ? subscribeResponse?.patientAiSettings : [],
				};

				dispatch(aiSettingsActionCreators.setPatientAiSettings(mappedResponse));
			}

			setAlertFeeds(prevState => {
				const alreadyExists = prevState.some(existingFeed => existingFeed.deviceId === feed.deviceId);
				return alreadyExists ? prevState : [...prevState, feed];
			});
		}
	};

	const removeFeed = async key => {
		const feeds = _.cloneDeep(alertFeeds);
		const feedIndex = feeds.findIndex(item => item.deviceId === key);
		const feed = feeds[feedIndex];

		if (!feed) {
			return;
		}

		stopVoiceOver(feed.warning.alertTypeId, feed.deviceId);

		const params = {
			healthSystemId: feed.healthSystemId,
			userId: userInfoRef.current.id,
			deviceId: feed.deviceId,
		};
		const unsubscribeResponse = await unsubscribeFromDeviceAlerts(params);
		if (unsubscribeResponse.error) {
			setError(unsubscribeResponse.error.message);
			return;
		}

		setSelectedFeed(prevState => {
			if (!prevState) {
				return null;
			}
			const exists = +prevState.deviceId === +key;
			return exists ? null : prevState;
		});

		feeds.splice(feedIndex, 1);
		setAlertFeeds(feeds);
	};

	const collapseTree = () => {
		dispatch(healthSystemsActions.toggleLeftNavigation());
	};

	const toggleRoomSettings = feed => {
		let selectedObj = { ...selectedFeed };
		const newFeeds = alertFeeds.map(item => {
			const newItem = item;
			if (item.deviceId === feed.deviceId) {
				newItem.isAlertTimelineVisible = !feed.isAlertTimelineVisible;
				selectedObj = newItem;
			} else {
				newItem.isAlertTimelineVisible = false;
			}
			return newItem;
		});

		setAlertFeeds(newFeeds);
		setSelectedFeed(selectedObj);
	};

	const fetchTeams = async () => {
		if (
			![UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) ||
			!user.userSession.healthSystem.id
		) {
			return;
		}
		const response = await getTeams(user.userSession.healthSystem.id);
		if (!response.error) {
			let channels = [];
			response.teams.forEach(team => {
				channels = channels.concat(team.channels.map(channel => ({ ...channel, team })));
			});
			setChannels(channels);
		}
	};

	const getSelectedFloor = deviceId => {
		const newChannels = [...channels];
		const newTree = [...healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		return newChannels.find(channel => channel.floorId === device?.floorId);
	};

	const getHierarchyNaming = deviceId => {
		const newTree = [...healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		if (!device?.breadcrumb) {
			return {};
		}
		const { breadcrumb } = device;
		return {
			hospital: breadcrumb[0].name,
			department: breadcrumb[1]?.name,
			floor: breadcrumb[2]?.name,
			room: breadcrumb[3]?.name,
		};
	};

	const sendAutomaticAlert = async feed => {
		const conversationId = getSelectedFloor(feed.deviceId)?.conversationId;
		const { hospital, department, floor, room } = getHierarchyNaming(feed.deviceId);
		const dataToSend = {
			conversationId,
			alertId: feed.warning.alertId,
			hospital,
			department,
			floor,
			room,
		};
		let response = null;
		if (feed.warning.isAiAlert) {
			response = await forwardAiAlert(dataToSend);
		}
		if (response.error) {
			setError(response.error.message);
		}
		onAlertCloseClick(feed);
	};

	const beforeUnloadEvent = event => {
		if (alertFeeds.length) {
			event.preventDefault();
			event.returnValue = '';
		}
	};

	const unloadEvent = () => {
		if (alertFeeds.length) {
			closeFeeds();
		}
	};

	const bindWindowListeners = () => {
		window.addEventListener('beforeunload', beforeUnloadEvent);
		window.addEventListener('unload', unloadEvent);
	};

	const unbindWindowListeners = () => {
		window.addEventListener('beforeunload', beforeUnloadEvent);
		window.addEventListener('unload', unloadEvent);
	};

	useEffect(() => {
		bindWindowListeners();

		return () => {
			unbindWindowListeners();
		};
	});

	useEffect(() => {
		const unsubscribeOnInit = async () => {
			const params = {
				healthSystemId: user.userSession.healthSystem.id,
				userId: userInfoRef.current.id,
			};
			await unsubscribeFromAllDeviceAlerts(params);
		};
		unsubscribeOnInit();
	}, [user.userSession.healthSystem.id]);

	useEffect(() => {
		fetchAlertingSessions();
	}, []);

	useEffect(() => {
		if (isSessionNotificationVisible) {
			setTimeout(() => {
				setIsSessionNotificationVisible(false);
			}, 2000);
		}
	}, [isSessionNotificationVisible]);

	useEffect(() => {
		if (error === intl.formatMessage({ id: 'maxAmbientMonitoringProviders' })) {
			setTimeout(() => {
				setError('');
			}, 3000);
		}
	}, [error, intl]);

	useEffect(() => {
		return () => {
			closeFeeds();
		};
	}, []);

	useEffect(() => {
		if (window.Android) {
			if (alertFeeds?.find(item => item.isStatAlarmActive)) {
				window.Android?.toggleStatAlarmLED(true);
				return;
			}
			window.Android?.toggleStatAlarmLED(false);
		}
	}, [alertFeeds]);

	const onObserverConferenceEnded = () => {
		setIsObserverConferenceModalOpen(false);
		setHasObserverConferenceStarted(false);
		setObserverConferenceData({
			participants: [],
			conferenceName: '',
			callType: null,
			roomId: null,
		});
	};

	const onConferenceStart = async ({ deviceId, roomName: conferenceName, roomId }, callType) => {
		if (isObserverConferenceModalOpen) {
			return;
		}
		if (micStatus.current?.state === MediaPermissions.DENIED) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: 'modal',
					type: StreamError.MICROPHONE_BLOCKED.type,
					message: 'allowMicPermissions',
				})
			);
			return;
		}
		await outGoingCallSound();
		setIsObserverConferenceModalOpen(true);
		setObserverConferenceData({
			conferenceName,
			callType,
			participants: [{ objectId: deviceId, objectType: ObjectType.HELLO_DEVICE }],
			roomId,
		});
	};

	const handleManageAlertClick = feed => {
		setSelectedFeed(feed);
		setIsAmbientMonitoringAlertModalOpen(true);
	};

	const onAlertCloseClick = feed => {
		setIsAmbientMonitoringAlertModalOpen(false);

		if (feed.warning.value && feed.warning.alertTypeId) {
			stopVoiceOver(feed.warning.alertTypeId, feed.deviceId);
		}
	};

	const handleProviderIntervening = deviceId => {
		if (!alertFeeds.find(item => item.deviceId === deviceId)) {
			return;
		}
		const newAlertFeeds = alertFeeds.map(item => {
			const newItem = { ...item };
			if (item.deviceId === deviceId) {
				newItem.intervention = {};
			}
			return newItem;
		});
		setAlertFeeds(newAlertFeeds);
	};

	useEffect(() => {
		const getForwardToNursesValue = () => {
			setShowForwardToNurses(getConfigurationValue(adminConfigurations[MonitoringSettings.ForwardToNurses]));
		};
		getForwardToNursesValue();
	}, [adminConfigurations]);

	useEffect(() => {
		if (selectedFeed && isAmbientMonitoringAlertModalOpen) {
			const fetchRoomSettings = async () => {
				const [adminRoomSettings, myRoomSettings] = await Promise.all([
					getRoomSettings(selectedFeed?.roomId, SettingsCategory.MONITORING),
					getUserPreferences(UserSettingTypes.Monitoring, selectedFeed.roomId),
				]);
				const response = updateConfigsList(adminRoomSettings, myRoomSettings);
				if (response.error) {
					setError(response.error.message);
				} else {
					setAdminConfigurations(response.configs);
				}
			};
			fetchRoomSettings();
		}
	}, [selectedFeed, isAmbientMonitoringAlertModalOpen]);

	return (
		<MainLayout>
			{!healthSystems.allHealthSystems.length && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{healthSystems.allHealthSystems.length && (
				<>
					<Grid
						className={classNames(
							'monitoring-grid ambient-monitoring-grid',
							screenType.isSmall ? 'mobile-monitoring-grid' : '',
							healthSystems.isLeftNavigationExpanded ? 'collapsed-second-column-pilot' : ''
						)}
						stretch='100%'>
						{((screenType.isSmall && !healthSystems.isLeftNavigationExpanded) || !screenType.isSmall) && (
							<Grid stretch='100%'>
								<main className='main-view alert-center-view'>
									<section>
										{alertFeeds.length === 0 && (
											<>
												<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
													<div style={{ textAlign: 'center' }}>
														{!currentSector && <p>{translate('selectViewOrAddRooms')}</p>}
														{currentSector?.type === SectorTypes.ROOM && (
															<>
																<h3>{currentSector.name}</h3>
																{!currentSector.helloDeviceId && (
																	<p>
																		{translate('roomNoDeviceAssigned')}, <br />
																		{translate('roomsNeedDevice')} {translate('inOrderToReceiveAlerts')}
																	</p>
																)}
																{currentSector.helloDeviceId && (
																	<p>
																		{translate('receiveAlerts')}
																		<br />
																		{translate('clickAddIconInTree')}.
																	</p>
																)}
															</>
														)}
														{currentSector && currentSector?.type !== SectorTypes.ROOM && (
															<p>
																{translate('receiveAlerts')}
																<br />
																{translate('openHospitalChooseDepartment')}, <br />
																{translate('clickFloorSelectRoom')}.
															</p>
														)}
													</div>
												</Grid>
											</>
										)}
										{alertFeeds.length > 0 && (
											<>
												<Grid
													width='100%'
													gridGap='10px'
													className={classNames(
														'alert-feeds',
														alertFeeds.length <= 16 ? 'alert-feeds-16' : '',
														alertFeeds.length > 16 && alertFeeds.length <= 25 ? 'alert-feeds-25' : '',
														alertFeeds.length > 25 ? 'alert-feeds-auto' : '',
														healthSystems.isLeftNavigationExpanded ? 'padding-right-xxxv' : ''
													)}>
													{alertFeeds.map(feed => (
														<React.Fragment key={feed.deviceId}>
															<AlertFeed
																feed={feed}
																onSettingsClick={toggleRoomSettings}
																onCloseClick={() => removeFeed(feed.deviceId)}
																intl={intl}
																onConferenceStart={onConferenceStart}
																onManageAlertClick={handleManageAlertClick}
																onProviderIntervening={handleProviderIntervening}
																isDefaultOwner={feed?.isDefaultOwner}
																setDiscontinueMonitoringReasons={() => null}
															/>
														</React.Fragment>
													))}
												</Grid>
											</>
										)}
									</section>
								</main>
							</Grid>
						)}
						<aside className={'right-side-monitoring hello-list hospitals-list monitoring-hospitals-list'}>
							<h4 className='monitoring-title'>{translate('careNotifications')}</h4>
							<span className='collapse-second-column collapse-icon' onClick={collapseTree}>
								<i className='material-icons-outlined'>
									{healthSystems.isLeftNavigationExpanded ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
								</i>
							</span>
							{((screenType.isSmall && healthSystems.isLeftNavigationExpanded) || !screenType.isSmall) && (
								<Sessions>
									<h4>{translate('savedSessions')}</h4>
									{errorFetchingSessions && <ul>{errorFetchingSessions}</ul>}
									<ul className='monitoring-session-ul'>
										{alertingSessions.map(session => (
											<li className={session.isActive ? 'active-monitoring-session' : ''} key={session.id}>
												<div onClick={event => openSession(event, session)}>
													<img
														src={`${healthCareCdnUrl}monitoring/session${session.isActive ? '-active' : ''}.svg`}
														alt='close_session'
													/>
													<span> {session.name}</span>
												</div>
												{session.isActive && (
													<div className='flex'>
														<div
															className={alertFeeds.length === 0 || !session.isActive ? 'disabled' : ''}
															onClick={() => saveSession(session)}
															id='save-session'>
															<img
																src={`${healthCareCdnUrl}${user.darkMode ? 'dark-mode/save-gray.svg' : 'monitoring/save.svg'}`}
																alt='save_session'
															/>
															<img
																className='active-session-img'
																src={`${healthCareCdnUrl}monitoring/save-active.svg`}
																alt='save_session'
															/>
															<span>{translate('save')}</span>
														</div>
														<div
															onClick={() => toggleDeleteSessionModal(session)}
															className={session.isCurrent || !session.isActive ? 'disabled' : ''}
															id='delete-session'>
															<img
																src={`${healthCareCdnUrl}${user.darkMode ? 'dark-mode/delete-gray.svg' : 'monitoring/trash.svg'}`}
																alt='delete_session'
															/>
															<img
																className='active-session-img'
																src={`${healthCareCdnUrl}monitoring/trash-active.svg`}
																alt='delete_session'
															/>
															<span>{translate('delete')}</span>
														</div>

														<div
															className={alertFeeds.length === 0 || !session.isActive ? 'disabled' : ''}
															onClick={() => closeCurrentSession(true, session)}
															id='close-session'>
															<img
																src={`${healthCareCdnUrl}${
																	user.darkMode ? 'dark-mode/remove-gray.svg' : 'monitoring/remove.svg'
																}`}
																alt='close_session'
															/>
															<img
																className='active-session-img'
																src={`${healthCareCdnUrl}monitoring/remove-active.svg`}
																alt='close_session'
															/>
															<span>{translate('close')}</span>
														</div>
													</div>
												)}
											</li>
										))}
									</ul>
								</Sessions>
							)}
							<SectorListMayo
								isAlertCenter={true}
								isSavedSessionsHidden={true}
								onAddDevice={onAddDevice}
								onTreeViewLinkClick={option => handleTreeNodeClick(option)}
								alertFeeds={alertFeeds}
							/>
						</aside>
						<Modal
							display={showSessionPopup}
							position='center'
							onModalClose={() => toggleSessionModal(false)}
							onModalSubmit={() => createNewSession(newSessionName)}>
							<div className='save-session'>
								<h3>{translate('saveSession')}</h3>
								<Input
									type='text'
									onChange={setSessionName}
									name='newSessionName'
									value={newSessionName}
									validationOptions={{}}
									label={translate('sessionName')}
									placeholder={intl.formatMessage({ id: 'enterSessionName' })}
									maxLength={100}
								/>
							</div>
						</Modal>
						<Modal
							display={showDeleteSessionModal}
							position='center'
							onModalClose={() => toggleDeleteSessionModal(null)}
							onModalSubmit={() => deleteSession(sessionToDelete)}
							primaryButtonLabel={translate('delete')}>
							<Form title={translate('deleteSession')}>
								<p>{translate('sureDeleteSession')}</p>
							</Form>
						</Modal>
						{isObserverConferenceModalOpen && (
							<ConferenceModal
								startConferenceData={observerConferenceData}
								incomingConferenceInfo={null}
								onCallStarted={() => hasObserverConferenceStarted(true)}
								onCallEnded={onObserverConferenceEnded}
								isEmergencyCallOpen={false}
								isDarkMode={user.darkMode}
							/>
						)}
						<Prompt when={alertFeeds.length > 0} message={intl.formatMessage({ id: 'switchingToOtherTab' })} />
						{isSessionNotificationVisible && <Notification text={translate('sessionUpdatedSuccessfully')} />}
						<AmbientMonitoringAlertModal
							feed={selectedFeed}
							display={isAmbientMonitoringAlertModalOpen}
							showForwardToNurses={showForwardToNurses}
							onForwardToNurses={feed => sendAutomaticAlert(feed)}
							onCloseClick={() => onAlertCloseClick(selectedFeed)}
							intl={intl}
							darkMode={user.darkMode}
						/>
					</Grid>
					<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
				</>
			)}
		</MainLayout>
	);
};

const Sessions = styled.div`
	+ div {
		padding: 0 !important;
	}

	> h4 {
		padding: 0;
		font-size: 16px;
		font-weight: 600;
	}

	ul {
		margin: var(--spacing-m) 0;
		li {
			div:first-of-type {
				display: flex;
				align-items: center;
				color: var(--gray-5);
				font-size: 14px;

				i {
					margin-right: var(--spacing-s);
					color: var(--gray-4);
					font-size: 18px;
				}

				&:not(:first-of-type) {
					margin-left: 5px;
				}

				&:first-of-type {
					flex: 1;
				}

				img {
					height: 18px;
					margin-right: 5px;
				}
			}

			&:last-of-type {
				div:first-of-type {
					span {
						font-weight: 500;
						margin-bottom: 0;
					}
				}
			}
		}
	}
`;

export default AlertCenter;
