import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import Loader from 'components/Loader.jsx';
import PropTypes from 'prop-types';
import {
	ParticipantState,
	DeviceStatusMessages,
	AlertTypes,
	DetectionTypes,
	ObjectType,
	PatientAiSetting,
	CareEventTypes,
	HelloVideoFeedRecordAction,
	ParticipantRemoveReason,
	CallTypes,
	UserRoles,
	DeviceListLevel,
	SensitivityType,
	SensitivityLabel,
	AiAlertType,
	IotDeviceState,
	CameraType,
	ExcludedAiSettings,
	AiAlertActivityType,
} from 'constants/enums.js';
import CameraTypes from 'calls/enums/CameraTypes.js';
import {
	configurableMonitoringMenu,
	MonitoringSettings,
	UserSettingTypes,
	NoteVariants,
	SettingsCategory,
	configurableAISettings,
	AiSettingFeaturesCategory,
	CallWorkflowType,
	getConfigurationWithDiffTypeKey,
	AiSetting,
} from 'constants/configurationEnums.js';
import translate from 'i18n-translations/translate.jsx';
import { injectIntl } from 'react-intl';
import WarningDetection from 'components/WarningDetection.jsx';
import drawObjectDetections from 'services/aiDetections/objectDetection.js';
import drawSkeletonDetections from 'services/aiDetections/skeletonDetection.js';
import {
	adjustPositions,
	getConfigurationValue,
	updateConfigsList,
	getMonitoringConfigurationVariant,
	getAiSettingsConfigurations,
} from 'infrastructure/helpers/commonHelpers.js';

import {
	forwardMeasurementAlert,
	forwardAiAlert,
	forwardManualAlert,
	forwardManualAlertToMSTeams,
	saveManualAlert,
	getPatientMeasurementAlerts,
} from 'api/alerts.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getUserId, getCompanyId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import moment from 'moment';
import Button from 'components/Button.jsx';
import { convertUTCDateToLocalDate } from 'infrastructure/helpers/dateHelper.js';
import VideoFeedSubOption from 'components/VideoFeedSubOption.jsx';
import classNames from 'classnames';
import SendMessageModal from 'components/SendMessageModal.jsx';
import { callSpecificNurse, getTeamMembers, sendGroupMessage, setPrecautions } from 'api/monitoring.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import Alert from 'components/Alert.jsx';
import { insertMessage } from 'api/messenger.js';
import { getCheckedInNurses } from 'api/teams.js';
import ManualReportModal from 'components/ManualReportModal.jsx';
import SocketEvents from 'constants/socket-events.js';
import RiskAssessmentBadge from 'components/RiskAssessmentBadge.jsx';
import Countdown from 'react-countdown';
import { getPatientConditions } from 'api/patients.js';
import ToastMessage from 'components/ToastMessage.jsx';
import MonitoringNotes from 'containers/MonitoringNotes.jsx';
import FeedColorsDropdown from 'containers/Monitoring/FeedColorsDropdown.jsx';
import CareEventsModal from 'components/CareEventsModal.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { withSocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import DisableVisitingHoursModal from 'components/DisableVisitingHoursModal.jsx';
import DisabledCountDown from 'components/DisabledCountDown.jsx';
import { enableVisits, getVisitingHours } from 'api/visitingHours.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { enableAiAlerts, getDisabledAiControls } from 'api/monitoringAiAlerts.js';
import DisableAiModal from 'components/DisableAiModal.jsx';
import _ from 'lodash';
import { getUserPreferences } from 'api/users.js';
import VitalSignsAI from 'components/VitalSignsAI.jsx';
import BioBeatMeasurements from 'components/BioBeatMeasurements.jsx';
import WatchMeasurements from 'components/WatchMeasurements.jsx';
import AlertInformation from 'components/AlertInformation.jsx';
import LifeSignalsMeasurements from 'components/LifeSignalsMeasurements.jsx';
import Video from 'components/Call/Video.jsx';
import { recordingWorkerScript } from 'workers/recording-worker.js';
import { RoomTypes } from 'constants/visitEnums.js';
import VoiceOverButton from 'components/VoiceOverButton.jsx';
import CircularDetectionChart from 'components/CircularDetectionChart.jsx';
import AIToastMessage from 'components/AIToastMessage.jsx';
import IvBag from 'icons/Monitoring/IvBag.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import CameraControls from 'containers/CameraControls.jsx';
import FallPreventionModal from 'components/FallPreventionModal.jsx';
import ConfigurableButtonsMonitoring from 'components/MonitoringSettings/ConfigurableButtonsMonitoring.jsx';
import { isSettingEnabled } from 'infrastructure/helpers/aiHelper.js';
import { FallPreventionSettingTypes } from 'constants/ai.js';

class VideoFeed extends Component {
	state = {
		windowWidth: window.innerWidth,
		windowHeight: window.innerHeight,
		isToggleAcknowledgmentModalVisible: false,
		isRaiseAlertOpen: false,
		checkedInNurses: [],
		isContactNursesOpen: false,
		isMicrosoftTeamsOpen: false,
		isWalkieTalkieOn: false,
		isFeedExpanded: false,
		isAiVisible: false,
		isSpecificNurseOpen: false,
		isSendGroupMessageOpen: false,
		teamMembers: [],
		error: null,
		isWalkieTalkieVolumeOn: true,
		myMicrophoneAudioLevel: 0,
		participantTalking: '',
		isManualReportLoading: false,
		isSendMessageLoading: false,
		isForwardMSTeamsLoading: false,
		isMoreThanOneAi: false,
		isPrecautionsPortalOpen: false,
		showPrivacyModeError: false,
		careEventsForNurses: false,
		careEventsForSafetyCompanions: false,
		nameOfNurseAlsoMonitoring: '',
		time: 0,
		running: false,
		recordingStatus: false,
		isDisabledHours: false,
		isDisableVisitsOpen: false,
		disabledVisitTime: {
			createdAt: new Date(),
			disableTime: 0,
			isDisabled: false,
		},
		adminConfigurations: configurableMonitoringMenu,
		adminAiSettingsConfigurations: configurableAISettings(),

		disabledAiTime: {
			createdAt: new Date(),
			disableTime: 0,
			isDisabled: false,
		},
		isDisableAiOpen: false,
		disabledAiControls: [],
		showRecordingFailedAlert: false,
		showRecordingSavedAlert: false,
		isConfigureFeedButtonsOpen: false,
		sensitivityTypes: [
			{ id: 0, label: this.props.intl.formatMessage({ id: 'high' }), value: SensitivityType.HIGH, img: SensitivityLabel.HIGH },
			{
				id: 1,
				label: this.props.intl.formatMessage({ id: 'medium' }),
				value: SensitivityType.MEDIUM,
				img: SensitivityLabel.MEDIUM,
			},
			{ id: 2, label: this.props.intl.formatMessage({ id: 'low' }), value: SensitivityType.LOW, img: SensitivityLabel.LOW },
		],
		recordingInitialized: false,
		isVoiceOverOptionsVisible: false,
		precautions: [
			{
				id: '22631001',
				abbreviation: 'F',
				name: 'Falls',
				textColor: '#282D30',
				boxColor: '#F3C752',
			},
			{
				id: '77272004',
				abbreviation: 'SZ',
				name: 'Seizure',
				textColor: '#FFFFFF',
				boxColor: '#E270DA',
			},
			{
				id: '441862004',
				abbreviation: 'ISO',
				name: 'Isolation',
				textColor: '#FFFFFF',
				boxColor: '#D64F2D',
			},
			{
				id: '413322009',
				abbreviation: 'SW',
				name: 'Suicide Watch',
				textColor: '#282D30',
				boxColor: '#88D9FB',
			},
			{
				id: '26544005',
				abbreviation: 'SB',
				name: 'Suspicious Behavior',
				textColor: '#FFFFFF',
				boxColor: '#7B35C1',
			},
			{
				id: '49436004',
				abbreviation: 'HP',
				name: 'Hospice',
				textColor: '#FFFFFF',
				boxColor: '#000000',
			},
			{
				id: '71388002',
				abbreviation: 'D',
				name: 'Detox',
				textColor: '#282D30',
				boxColor: '#F2A356',
			},
			{
				id: '233604007',
				abbreviation: 'PO2',
				name: 'Pulling O2',
				textColor: '#282D30',
				boxColor: '#B6D7E4',
			},
			{
				id: '35489007',
				abbreviation: 'IC',
				name: 'Impulsive/Confused',
				textColor: '#282D30',
				boxColor: '#D3D3D3',
			},
			{
				id: '43998006',
				abbreviation: 'NR',
				name: 'Non-redirectable',
				textColor: '#FFFFFF',
				boxColor: '#4BA5F8',
			},
		],
		mobilityPercentage: 0,
		isIvBagMeasuring: false,
		isFallsPrecautionsModalOpen: false,
		isDrawing: false,
		coordinates: {
			x1: 0,
			x2: 0,
			y1: 0,
			y2: 0,
		},
		squareDrawingCoordinates: {
			x1: 0,
			x2: 0,
			y1: 0,
			y2: 0,
		},
		isPtzAdminConfig: false,
	};

	audioLevels = [
		{
			id: 0,
			value: 4,
		},
		{
			id: 1,
			value: 6,
		},
		{
			id: 2,
			value: 8,
		},
		{
			id: 3,
			value: 6,
		},
		{
			id: 4,
			value: 4,
		},
	];

	userId = getUserId();

	companyId = getCompanyId();

	videoWrapperRef = null;

	videoInnerWrapperRef = null;

	videoRef = React.createRef();

	ctx = null;

	ctx2 = null;

	canvasRef = null;

	canvas2Ref = null;

	imgRef = null;

	walkieTalkieRef = null;

	microsoftTeamsRef = null;

	raiseAlertRef = null;

	contactNursesRef = null;

	monitoringIconLink = `${healthCareCdnUrl}monitoring/`;

	socket = this.context;

	timeout = null;

	maximumRecordingLengthInSeconds = 299;

	prevDeviceState = null;

	maxSimultaneousMonitoringFeedsReached = false;

	hasFetchedPrecautions = false;

	notesHeight = 125;

	componentDidMount = async () => {
		this.props.callManager.on('draw-skeleton', this.skeletonDetectionListener);
		this.props.callManager.on('object-detection', this.objectDetectionsListener);
		this.props.callManager.on('call-state-changed', this.callStateChanged);
		this.socket.on(SocketEvents.Conference.RECORD_MONITORING_RESPONSE, this.handleRecordMonitoringResponse);
		this.socket.on(SocketEvents.HelloDevice.IOT_TOAST_MESSAGES, this.toastMessagesListener);
		// this.props.callManager.on('walkie-talkie-activity', this.hadleWalkieTalkieVoiceActivity);
		this.socket.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, this.onAiAlertActivity);
		document.addEventListener('mousedown', this.handleClickOutside);
		window.addEventListener('resize', this.handleResize);
		this.props.ptt
			.on('ptt_stopped_speaking', volume => {
				this.setState({
					myMicrophoneAudioLevel: volume,
				});
			})
			.on('ptt_volume_changed', volume => {
				this.setState({
					myMicrophoneAudioLevel: volume,
				});
			})
			.on('ptt_talking', this.handleWalkieTalkieVoiceActivity);
		this.fetchVisitingHours();
		this.fetchRoomSettings();
		this.fetchDisabledAiControls();
		this.recordingWorker = new Worker(recordingWorkerScript, {
			name: 'Recording timer worker',
		});
		if (this.recordingWorker) {
			this.recordingWorker.onmessage = this.onRecordingMessage;
		}
	};

	componentDidUpdate() {
		if (this.state.isFeedExpanded !== this.props.feed.isFeedExpanded) {
			this.setState({ isFeedExpanded: this.props.feed.isFeedExpanded });
		}

		if (!this.hasFetchedPrecautions && this.props.feed.deviceOwner?.healthcareUserId) {
			this.fetchPrecautions(this.props.feed.deviceOwner?.healthcareUserId);
			this.hasFetchedPrecautions = true;
		}

		this.shouldClearDetections();

		if (this.state.time === this.maximumRecordingLengthInSeconds) {
			this.setState(
				{
					running: false,
					time: 0,
				},
				() => {
					this.feedRecording(this.props.feed);
					if (this.recordingWorker) {
						this.recordingWorker.postMessage('stop');
					}
					this.setState({
						recordingInitialized: false,
					});
					clearInterval(this.timeout);
				}
			);
		}
	}

	componentWillUnmount = () => {
		this.props.callManager.off('draw-skeleton', this.skeletonDetectionListener);
		this.props.callManager.off('object-detection', this.objectDetectionsListener);
		this.props.callManager.off('walkie-talkie-activity', this.handleWalkieTalkieVoiceActivity);
		this.socket.off(SocketEvents.Conference.RECORD_MONITORING_RESPONSE, this.handleRecordMonitoringResponse);
		this.socket.off(SocketEvents.HelloDevice.IOT_TOAST_MESSAGES, this.toastMessagesListener);
		this.socket.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, this.onAiAlertActivity);
		this.props.callManager.off('call-state-changed', this.callStateChanged);
		document.removeEventListener('mousedown', this.handleClickOutside);
		window.removeEventListener('resize', this.handleResize);
		if (this.recordingWorker) {
			this.recordingWorker.terminate();
		}
	};

	shouldClearDetections() {
		const noDrawingOrSettingOn = !this.props.feed.isAiDrawingsActive || !this.getFeedAiSettings().some(item => item.isEnabled);
		if (noDrawingOrSettingOn && (this.ctx || this.ctx2)) {
			this.prepareCanvas(true);
		}
	}

	fetchPrecautions = async ownerId => {
		const precautions = await getPatientConditions(ownerId);
		const newPrecautions = this.state.precautions.map(x => {
			const precaution = precautions.conditions.find(p => p.code === x.id);
			if (precaution) {
				x.active = true;
			}
			return x;
		});
		this.setState({
			precautions: newPrecautions,
		});
	};

	handleResize = () => {
		this.setState({
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
		});
	};

	toastMessagesListener = data => {
		if (this.props.feed.participantId !== data.actioneeParticipantId) {
			return;
		}
		if (data.deviceType === AiAlertType.IV_BAG) {
			this.setState({
				isIvBagMeasuring: data.deviceState === IotDeviceState.MEASURING,
			});
			return;
		}
		if (this.state.isIvBagMeasuring) {
			this.setState({
				isIvBagMeasuring: false,
			});
		}
	};

	callStateChanged = data => {
		if (data.deviceId === this.props.feed.deviceId) {
			const activeDeviceMonitoringFeeds = data.activeConferences?.filter(feed => feed.callType === CallTypes.MONITORING)?.length;
			const prevIsDeviceOnACall = this.prevDeviceState?.activeConferences?.some(
				feed => feed.callType === CallTypes.SECURITY_CAM || feed.callType === CallTypes.VIDEO || feed.callType === CallTypes.AUDIO
			);
			const isDeviceOnACall = data.activeConferences.some(
				feed => feed.callType === CallTypes.SECURITY_CAM || feed.callType === CallTypes.VIDEO || feed.callType === CallTypes.AUDIO
			);
			const MaxSimultaneousMonitoringFeeds = 2;
			if (
				prevIsDeviceOnACall &&
				!isDeviceOnACall &&
				activeDeviceMonitoringFeeds < MaxSimultaneousMonitoringFeeds &&
				(this.props.feed.reason === ParticipantRemoveReason.DISCONNECTED_BY_CALL ||
					this.props.feed.status === ParticipantState.BUSY.type)
			) {
				this.props.reAddFeed(this.props.feed.deviceId);
			}
			this.prevDeviceState = data;
			const monitoringConnections = data.activeConferences?.filter(feed => feed.callType === CallTypes.MONITORING);

			this.maxSimultaneousMonitoringFeedsReached = activeDeviceMonitoringFeeds >= MaxSimultaneousMonitoringFeeds;
			this.setState({
				nameOfNurseAlsoMonitoring: monitoringConnections.length > 0 ? monitoringConnections?.at(0)?.name : '',
			});
		}
	};

	feedRecording = ({ participantId: actioneeParticipantId }) => {
		this.props.socketFunctions.sendRecordMonitoringEvent({
			conferenceId: this.props.conferenceInfo.conferenceId,
			participantId: this.props.conferenceInfo.participantId,
			actioneeParticipantId,
			recordCommand: !this.state.recordingStatus ? HelloVideoFeedRecordAction.START : HelloVideoFeedRecordAction.END,
		});
		this.setState(prevState => ({
			recordingStatus: !prevState.recordingStatus,
		}));
	};

	setRecordingTimer = () => {
		if (this.state.time === 0) {
			if (this.recordingWorker) {
				this.recordingWorker.postMessage('start');
			}
			this.setState({
				recordingInitialized: true,
			});
			return;
		}
		if (this.recordingWorker) {
			this.recordingWorker.postMessage('stop');
		}
		this.setState({
			time: 0,
			recordingInitialized: false,
		});
	};

	onRecordingMessage = result => {
		this.setState({
			time: result.data,
		});
	};

	closePersonUpAlert = () => {
		this.props.callManager.cancelPersonUpAlert(
			this.props.feed.deviceId,
			this.props.conferenceInfo.conferenceId,
			this.props.feed.participantId,
			this.props.feed.type
		);
	};

	closeRecordingAlerts = () => {
		this.setState({ showRecordingSavedAlert: false, showRecordingFailedAlert: false });
		clearTimeout(this.timeout);
	};

	handleRecordMonitoringResponse = data => {
		if (this.props.feed.participantId === data.participantId) {
			if (data.succeeded && data.recordCommand === HelloVideoFeedRecordAction.END) {
				this.setState({ showRecordingFailedAlert: false, showRecordingSavedAlert: true });
			}
			if (!data.succeeded) {
				this.setState({ showRecordingFailedAlert: true, showRecordingSavedAlert: false });
			}
			this.timeout = setTimeout(() => {
				this.closeRecordingAlerts();
			}, 3000);
		}
	};

	shouldShowDetections = data =>
		(this.ctx || this.ctx2) &&
		this.props.conferenceInfo.conferenceId === data.conferenceId &&
		this.props.feed.participantId === data.participantId &&
		this.props.feed.isAiDrawingsActive &&
		this.getFeedAiSettings().some(item => item.isEnabled);

	objectDetectionsListener = data => {
		const ivFluid = data.detections.find(item => item.detectionType === DetectionTypes.IV_BAG_FLUID_LEVEL)?.detectionType;
		const mobilityScore = data.detections.find(item => item.detectionType === DetectionTypes.MOBILITY_SCORE)?.detectionType;

		const hasDetectionType = data.detections.find(item => item.detectionType);

		if (this.props.feed.participantId === data.participantId) {
			if (hasDetectionType && ivFluid) {
				const ivBagPercentage = Math.round(hasDetectionType.confidence);
				this.props.setIvBagPercentage(this.props.feed.deviveId, ivBagPercentage);
			} else {
				this.props.setIvBagPercentage(this.props.feed.deviceId, 0);
			}
			if (hasDetectionType && mobilityScore) {
				const mobilityPercentage = Math.round(hasDetectionType.confidence);
				this.setState({
					mobilityPercentage,
				});
			} else {
				this.setState({
					mobilityPercentage: 0,
				});
			}
		}

		let type = null;
		const bedOrRail = data.detections.find(item =>
			[DetectionTypes.RAILS, DetectionTypes.BED].includes(item.detectionType)
		)?.detectionType;

		if (bedOrRail) {
			type = bedOrRail;
		}

		if (ivFluid) {
			type = ivFluid;
		}

		if (mobilityScore) {
			type = mobilityScore;
		}

		this.prepareCanvas(false);
		if (this.shouldShowDetections(data)) {
			drawObjectDetections(this.ctx, data.detections, type, this.state.windowWidth, this.state.windowHeight);
		}
	};

	skeletonDetectionListener = data => {
		this.prepareCanvas(false, true);
		if (this.shouldShowDetections(data)) {
			this.ctx2?.clearRect(0, 0, this.state.windowWidth, this.state.windowHeight);
			const skeletonArray = adjustPositions(data.skeletons, this.state.windowWidth, this.state.windowHeight);
			drawSkeletonDetections({
				ctx: this.ctx2,
				skeleton: skeletonArray,
			});
		}
	};

	prepareCanvas = (shouldClearCanvas = true, isSkeleton = false) => {
		if (isSkeleton) {
			this.ctx2 = this.canvas2Ref?.getContext('2d');
			if (!this.ctx2) {
				return;
			}
		} else {
			this.ctx = this.canvasRef?.getContext('2d');
			if (!this.ctx) {
				return;
			}
		}

		if (shouldClearCanvas) {
			this.ctx?.clearRect(0, 0, this.state.windowWidth, this.state.windowHeight);
			this.ctx2?.clearRect(0, 0, this.state.windowWidth, this.state.windowHeight);
		}
	};

	getNursesInMonitoringNames = () => {
		let names = '';

		if (this.props.feed.nursesInConference?.length > 1) {
			names = this.props.feed.nursesInConference.map(obj => obj.name).join(` ${this.props.intl.formatMessage({ id: 'and' })} `);
		}

		return names;
	};

	getDeviceStatus() {
		if (this.props.feed.status === ParticipantState.CONNECTED.type && this.props.feed.isCameraPrivacyOn) {
			return DeviceStatusMessages.privacyModeOn;
		}
		switch (this.props.feed.status) {
			case ParticipantState.BUSY.type:
				if (this.maxSimultaneousMonitoringFeedsReached) {
					return translate('maximumNumberOfMonitoringSessions', { value: this.getNursesInMonitoringNames() });
				}

				if (this.props.feed.onPatientBusyNurse?.name) {
					return `${DeviceStatusMessages.deviceOnCallWithNurse} ${this.props.feed.onPatientBusyNurse.name}.`;
				}
				return DeviceStatusMessages.deviceOnCall;
			case ParticipantState.OFFLINE.type:
				return DeviceStatusMessages.deviceOffline;
			case ParticipantState.NOT_ANSWERING.type:
				return DeviceStatusMessages.notAnswering;
			case ParticipantState.DISCONNECTED.type:
			case ParticipantState.LEFT_CALL.type:
				if (this.props.feed.reason === ParticipantRemoveReason.DISCONNECTED_BY_CALL) {
					return DeviceStatusMessages.disconnectedByCall;
				}
				if (this.props.feed.reason === ParticipantRemoveReason.FAILED_TO_GET_INFO) {
					return DeviceStatusMessages.failedToGetInfo;
				}
				return DeviceStatusMessages.leftCall;
			case ParticipantState.RECONNECTING.type:
				return DeviceStatusMessages.reconnecting;
			case ParticipantState.REMOVED.type:
				return DeviceStatusMessages.removed;
			case ParticipantState.INVITE_DENIED.type:
				return DeviceStatusMessages.inviteDenied;
			case ParticipantState.FAILED_TO_GET_INFO.type:
				return DeviceStatusMessages.failedToGetInfo;
			default:
				return DeviceStatusMessages.initiatingMonitoring;
		}
	}

	toggleExpand = () => {
		if (this.props.numberOfFeeds === 1) {
			return;
		}
		this.props.onExpandClick();
	};

	onAlertCloseClick = () => {
		if (this.props.feed.warning.value) {
			this.props.stopVoiceOver({ alertTypeId: this.props.feed.warning.alertTypeId, deviceId: this.props.feed.deviceId });
			this.prepareCanvas(true);
		}
		if (this.props.feed.warning.alertTypeId) {
			this.closePersonUpAlert();
		} else {
			this.props.onAlertInfoClose(this.props.feed.deviceId, true);
		}
	};

	onAiAlertActivity = data => {
		if (
			[AiAlertActivityType.ACKNOWLEDGED, AiAlertActivityType.AI_FAKE_ALERT, AiAlertActivityType.AI_FORWARD_TO_NURSES].includes(
				data.aiAlertActivityTypeId
			) &&
			data.deviceId === this.props.feed.deviceId
		) {
			this.onAlertCloseClick();
		}
	};

	getFeedAiSettings = () => {
		const settings = _.cloneDeep(this.props.aiSettings);
		const found = settings.find(item => item.deviceId === this.props.feed.deviceId);
		if (!found) {
			return [];
		}
		let result =
			found.settings?.length > 0 ? found.settings.filter(item => !ExcludedAiSettings.includes(item.settingTypeId)) : [];
		return result;
	};

	toggleReport = () => {
		this.setState(prevState => ({
			isRaiseAlertOpen: !prevState.isRaiseAlertOpen,
		}));
		this.onAlertCloseClick();
	};

	toggleCareEventsForNurses = () => {
		this.setState(prevState => ({
			careEventsForNurses: !prevState.careEventsForNurses,
		}));
		this.onAlertCloseClick();
	};

	toggleCareEventsForSafetyCompanions = () => {
		this.setState(prevState => ({
			careEventsForSafetyCompanions: !prevState.careEventsForSafetyCompanions,
		}));
		this.onAlertCloseClick();
	};

	toggleContactNurses = () => {
		this.setState(prevState => ({
			isContactNursesOpen: !prevState.isContactNursesOpen,
			isMicrosoftTeamsOpen: false,
			isSpecificNurseOpen: false,
		}));
	};

	toggleMicrosoftTeams = async () => {
		this.setState({ error: null });
		const response = await getTeamMembers(this.props.channelId);
		if (response.error || !response.hasSucceeded) {
			this.setState(prevState => ({
				isMicrosoftTeamsOpen: !prevState.isMicrosoftTeamsOpen,
				error: response?.baseErrorResponse?.message,
				isContactNursesOpen: false,
				isSpecificNurseOpen: false,
			}));
		} else {
			this.setState(prevState => ({
				isMicrosoftTeamsOpen: !prevState.isMicrosoftTeamsOpen,
				teamMembers: response.result.members,
				isContactNursesOpen: false,
				isSpecificNurseOpen: false,
			}));
		}
	};

	toggleWalkieTalkie = async () => {
		const hasPermission = await this.props.onToggleWalkieTalkie(!this.state.isWalkieTalkieOn);
		if (!hasPermission || !this.props.feed.isPttAvailable) {
			return;
		}
		this.setState(prevState => ({
			isWalkieTalkieOn: !prevState.isWalkieTalkieOn,
		}));
	};

	toggleWKSeakers = () => {
		this.props.ptt.speaker(!this.state.isWalkieTalkieVolumeOn);
		this.setState(prevState => ({
			isWalkieTalkieVolumeOn: !prevState.isWalkieTalkieVolumeOn,
		}));
	};

	handleClickOutside = event => {
		const isNotContactNursesRef = this.contactNursesRef && !this.contactNursesRef.contains(event.target);
		const isNotMicrosoftTeamsRef = this.microsoftTeamsRef && !this.microsoftTeamsRef.contains(event.target);

		const state = {
			isMicrosoftTeamsOpen: false,
			isContactNursesOpen: false,
			isSpecificNurseOpen: false,
		};

		if (isNotContactNursesRef && isNotMicrosoftTeamsRef) {
			this.setState(state);
		}
	};

	selectSpecificNurse = async e => {
		e.stopPropagation();

		const { users: checkedInNurses, error } = await getCheckedInNurses(this.props.feed.floorId);
		if (error) {
			this.setState({ error: 'Failed to get checked-in nurses!' });
		}

		const myUserIndex = checkedInNurses.findIndex(user => user.userId === this.userId);
		if (myUserIndex > -1) {
			checkedInNurses.splice(myUserIndex, 1);
		}

		this.setState(prevState => ({ checkedInNurses, isSpecificNurseOpen: !prevState.isSpecificNurseOpen }));
	};

	toggleSendGroupMessage = () => {
		this.setState(prevState => ({ isSendGroupMessageOpen: !prevState.isSendGroupMessageOpen }));
	};

	toggleSendTeamsMessage = () => {
		this.setState(prevState => ({ isSendTeamsMessageOpen: !prevState.isSendTeamsMessageOpen }));
	};

	closeSendGroupMessage = () => {
		this.setState(prevState => ({ isSendGroupMessageOpen: !prevState.isSendGroupMessageOpen }));
	};

	sendGroupMessage = async values => {
		this.setState({ isSendMessageLoading: true });
		const response = await sendGroupMessage(this.props.channelId, values.message);
		if (response.error) {
			this.setState({ error: response.error.message, isSendGroupMessageOpen: false, isSendMessageLoading: false });
			return;
		}
		this.setState({ isSendGroupMessageOpen: false, isSendMessageLoading: false });
	};

	sendTeamsMessage = async values => {
		this.setState({ isSendTeamsMessageOpen: false, isSendMessageLoading: true });
		const userInfo = getUserInfo();
		const dateCreated = new Date().getTime();
		const clientMessageId = `${this.props.conversationId}_${userInfo.userId}_${dateCreated}`;
		const messageObj = {
			attachments: [],
			clientMessageId,
			content: values.message,
			links: [],
			mentions: [],
		};
		const response = await insertMessage(this.props.conversationId, messageObj);
		if (response.error) {
			this.setState({ error: response.error.message, isSendTeamsMessageOpen: false });
		}
		this.setState({ isSendMessageLoading: false });
	};

	callMSTeamsSpecificNurse = async (channelId, item) => {
		const name = getUserInfo();
		const params = { channelId, userId: item.id, subject: `Meeting with ${name.firstName} ${name.lastName}` };
		const response = await callSpecificNurse(params);
		if (!response.error && response.hasSucceeded) {
			window.open(response.result.joinWebUrl, '_blank');
		} else {
			this.setState({ error: response.error.message });
		}
	};

	handleWalkieTalkieVoiceActivity = data => {
		this.setState({
			participantTalking: data.talking ? data.participant.name : '',
		});
	};

	sendAutomaticAlert = async (alertId, isAiAlert) => {
		const { hospital, department, floor, room } = this.props.hierarchyNaming;
		const dataToSend = {
			conversationId: this.props.conversationId,
			alertId,
			hospital,
			department,
			floor,
			room,
		};
		let response = null;
		if (isAiAlert) {
			response = await forwardAiAlert(dataToSend);
		} else {
			response = await forwardMeasurementAlert(dataToSend);
		}
		if (response.error) {
			this.setState({ error: response.error.message, isSendTeamsMessageOpen: false });
		}
		this.onAlertCloseClick();
		const params = {
			pageSize: 10,
			pageIndex: 0,
			userId: this.props.feed.deviceOwner?.healthcareUserId,
		};
		const measurementResponse = await getPatientMeasurementAlerts(params);
		if (measurementResponse.error) {
			this.setState({ error: measurementResponse.error.message });
			return;
		}
		this.props.setAlertToMap(measurementResponse.measurementAlerts ? measurementResponse.measurementAlerts[0] : null);
	};

	sendManualAlert = async (manualAlertTypeId, comment) => {
		if (this.props.feed.deviceOwner) {
			const { hospital, department, floor, room } = this.props.hierarchyNaming;
			const params = {
				conversationId: this.props.conversationId,
				hospital,
				department,
				floor,
				room,
				patientId: this.props.feed.deviceOwner?.healthcareUserId,
				deviceId: this.props.feed.deviceId,
				manualAlertTypeId,
				comment,
			};
			this.setState({ isManualReportLoading: true });
			const raiseManualAlertResponse = await forwardManualAlert(params);
			if (raiseManualAlertResponse.error) {
				this.setState({ error: raiseManualAlertResponse.error.message, isRaiseAlertOpen: false });
			}
			this.setState({ isRaiseAlertOpen: false, isManualReportLoading: false, careEventsForNurses: false });
		}
	};

	sendManualAlertToMSNurses = async (manualAlertTypeId, comment) => {
		if (this.props.feed.deviceOwner) {
			const { hospital, department, floor, room } = this.props.hierarchyNaming;
			const params = {
				conversationId: this.props.conversationId,
				channelId: this.props.channelId,
				hospital,
				department,
				floor,
				room,
				patientId: this.props.feed.deviceOwner?.healthcareUserId,
				deviceId: this.props.feed.deviceId,
				manualAlertTypeId,
				comment,
			};
			this.setState({ isForwardMSTeamsLoading: true });
			const raiseManualAlertResponse = await forwardManualAlertToMSTeams(params);
			if (raiseManualAlertResponse.error) {
				this.setState({ error: raiseManualAlertResponse.error.message, isRaiseAlertOpen: false });
			}
			this.setState({ isRaiseAlertOpen: false, isForwardMSTeamsLoading: false });
		}
	};

	sendRTSPcoordinates = ({ clientX, clientY }) => {
		if (this.props.feed?.RTSPcameras === 0) {
			return;
		}
		const rect = this.videoWrapperRef.getBoundingClientRect();
		const x = (1280 / rect.width) * (clientX - rect.left);
		const y = (720 / rect.height) * (clientY - rect.top);
		this.props.context.emit(SocketEvents.HelloDevice.RTSP_CAMERA_EVENT, {
			helloDeviceId: this.props.feed.deviceId,
			coordinates: { x, y },
			objectType: ObjectType.HELLO_DEVICE,
			isGridView: !this.props.feed.isGridView,
		});
	};

	getCountDownTimer = ({ minutes, seconds, completed }) => {
		if (completed) {
			this.props.toggleOffPrivacy();
			return <></>;
		}
		const getFormattedTime = value => (value.toString().length > 0 ? value.toString().padStart(2, '0') : value.toString());

		return (
			<div>
				<span>
					{getFormattedTime(minutes)}:{getFormattedTime(seconds)}
				</span>
			</div>
		);
	};

	checkIsColorActive = color => this.props.feed?.feedColors?.some(item => item === color);

	setFeedColor = async (event, item) => {
		const precautions = [...this.state.precautions];
		const noPrecautions = [
			{
				code: '00000000',
				name: 'Stability',
				conditionType: 3,
			},
		];
		const newPrecautions = precautions.map(precaution => {
			const newPrecaution = { ...precaution };
			if (precaution.id === item.key) {
				newPrecaution.active = !newPrecaution.active;
			}
			return newPrecaution;
		});
		const dataToSend = newPrecautions.filter(x => x.active).map(x => ({ conditionType: 3, code: x.id }));
		await setPrecautions(this.props.feed.deviceOwner?.healthcareUserId, {
			deviceId: this.props.feed.deviceId,
			conditions: dataToSend.length > 0 ? dataToSend : noPrecautions,
		});
		const fallsPrecautionsCode = '22631001';
		let fallPreventionStatus = false;

		const foundAiSettings = this.props.aiSettings.find(item => item.deviceId === this.props.feed.deviceId);
		if (foundAiSettings) {
			fallPreventionStatus = foundAiSettings.settings.reduce((acc, item) => {
				if (FallPreventionSettingTypes.includes(item.settingTypeId)) {
					return acc || item.isEnabled;
				}
				return acc;
			}, false);
		}

		if (
			item.key === fallsPrecautionsCode &&
			!fallPreventionStatus &&
			dataToSend.find(precaution => precaution.code === fallsPrecautionsCode) &&
			this.isAnyFallPreventionConfigEnabled()
		) {
			this.setState({
				isFallsPrecautionsModalOpen: true,
			});
		}
		this.setState({
			precautions: newPrecautions,
		});
	};

	isAnyFallPreventionConfigEnabled = () =>
		getConfigurationValue(this.state.adminAiSettingsConfigurations[AiSetting.RAILS]) ||
		getConfigurationValue(this.state.adminAiSettingsConfigurations[AiSetting.GET_OUT_OF_BED]) ||
		getConfigurationValue(this.state.adminAiSettingsConfigurations[AiSetting.FALL_DETECTION]);

	onCareEventSave = async (alertId, comment) => {
		const dataToSend = {
			patientId: this.props.feed.deviceOwner?.healthcareUserId,
			deviceId: this.props.feed.deviceId,
			manualAlertTypeId: alertId,
			comment,
			conferenceId: this.props.conferenceInfo.conferenceId,
		};
		this.setState({ isManualReportLoading: true });
		const response = await saveManualAlert(dataToSend);
		if (response.error) {
			this.setState({ error: response.error.message, isManualReportLoading: false });
			return;
		}
		if (this.state.careEventsForSafetyCompanions) {
			this.setState(prevState => ({
				careEventsForSafetyCompanions: !prevState.careEventsForSafetyCompanions,
				isManualReportLoading: false,
			}));
			this.props.careEventFired(true);
		} else {
			this.setState(prevState => ({
				careEventsForNurses: !prevState.careEventsForNurses,
				isManualReportLoading: false,
			}));
			this.props.careEventFired(true);
		}
	};

	handleEnableVisitingHours = async () => {
		if (!this.state.disabledVisitTime.isDisabled) {
			return;
		}
		const response = await enableVisits(DeviceListLevel.ROOM, this.props.feed.roomId);
		if (response.error) {
			this.setState({ error: response.error.message });
		} else {
			this.setState(prevState => ({
				disabledVisitTime: { ...prevState.disabledVisitTime, createdAt: new Date(), disableTime: 0, isDisabled: false },
			}));
		}
	};

	handleEnableAi = async () => {
		if (!this.state.disabledAiTime.isDisabled) {
			return;
		}
		const response = await enableAiAlerts(this.props.feed.roomId);
		if (response.error) {
			this.setState({ error: response.error.message });
		} else {
			this.setState(
				prevState => ({
					disabledAiTime: {
						...prevState.disabledAiTime,
						createdAt: new Date(),
						disableTime: 0,
						isDisabled: false,
					},
				}),
				() => {
					this.emitDisableAiEvent(false);
				}
			);
		}
	};

	fetchVisitingHours = async () => {
		if (getUserRole() !== UserRoles.NURSE) {
			return;
		}
		const response = await getVisitingHours(DeviceListLevel.ROOM, this.props.feed.roomId);
		if (response.error) {
			this.setState({ error: response.error.message });
		} else {
			const createdAt = new Date(response.createdAt);
			const localTime = convertUTCDateToLocalDate(createdAt);
			this.setState({
				disabledVisitTime: { createdAt: localTime, disableTime: response.disableTime, isDisabled: response.isVisitDisabled },
			});
		}
	};

	fetchRoomSettings = async () => {
		const [adminRoomSettings, myRoomSettings, adminAiSettings, aiRoomSettings] = await Promise.all([
			getRoomSettings(this.props.feed.roomId, SettingsCategory.MONITORING),
			getUserPreferences(UserSettingTypes.Monitoring, this.props.feed.roomId),
			getRoomSettings(this.props.feed.roomId, SettingsCategory.AI_SETTINGS),
			getUserPreferences(UserSettingTypes.AiSettings, this.props.feed.roomId),
		]);
		const responseError = adminRoomSettings.error || myRoomSettings.error || adminAiSettings.error || aiRoomSettings.error;
		if (responseError) {
			this.setState({ error: responseError.message });
			return;
		}
		const response = updateConfigsList(adminRoomSettings, myRoomSettings);
		const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings);
		const resError = response.error || aiResponse.error;
		if (resError) {
			this.setState({ error: resError });
		} else {
			this.setState({ adminConfigurations: response.configs, adminAiSettingsConfigurations: aiResponse.configs });

			this.props.setIsNoteShowing(getConfigurationValue(response.configs[MonitoringSettings.Notes]));
			this.setState({
				isPtzAdminConfig: getConfigurationValue(response.configs[MonitoringSettings.PTZCam]),
			});
		}
	};

	updateMonitoringSetting = (setting, key) => {
		const cloned = _.cloneDeep(this.state.adminConfigurations);
		const clonedAiSettings = _.cloneDeep(this.state.adminAiSettingsConfigurations);
		if (
			Object.keys(configurableAISettings()).includes(getConfigurationWithDiffTypeKey(key, CallWorkflowType.MONITORING).toString())
		) {
			clonedAiSettings[key] = setting;
			this.setState({
				adminAiSettingsConfigurations: clonedAiSettings,
			});
		} else {
			cloned[key] = setting;
			this.setState({
				adminConfigurations: cloned,
			});
		}
		if (key === MonitoringSettings.Notes) {
			this.props.setIsNoteShowing(setting.value);
		}
		if (key === MonitoringSettings.PTZCam) {
			this.setState({
				isPtzAdminConfig: setting.value,
			});
		}
	};

	fetchDisabledAiControls = async () => {
		const disabledAiControls = [];
		this.props.aiSettings.forEach(item => {
			if (item.value && JSON.parse(item.value)) {
				disabledAiControls.push(item.settingTypeId);
			}
		});
		const response = await getDisabledAiControls(DeviceListLevel.ROOM, this.props.feed.roomId);
		if (response.error) {
			this.setState({ error: response.error.message, disabledAiControls });
		} else {
			const createdAt = new Date(response.createdAt);
			const localTime = convertUTCDateToLocalDate(createdAt);
			this.setState({
				disabledAiControls,
				disabledAiTime: { createdAt: localTime, disableTime: response.minutes, isDisabled: response.isDisabled },
			});
		}
	};

	emitDisableAiEvent = isDisabled => {
		this.socket.emit(SocketEvents.Conference.AI_CONTROLS_EVENT_DISABLE, {
			conferenceId: this.props.conferenceInfo.conferenceId,
			actioneeParticipantId: this.props.feed.participantId,
			isDisabled,
			participantId: this.props.conferenceInfo.participantId,
			data: JSON.stringify({ aiControls: isDisabled ? [] : this.state.disabledAiControls.map(item => item.type) }),
		});
	};

	showMonitoringSettings = () => Object.entries(this.state.adminConfigurations).some(([, item]) => !item.isLocked);

	showAlerts = () =>
		Object.entries(this.state.adminAiSettingsConfigurations).some(
			([, item]) => item.category === AiSettingFeaturesCategory.AI_SETTINGS && item.value
		);

	getFormattedRecordingTime = () => {
		const { time } = this.state;
		const duration = moment.duration(time, 'seconds');
		return moment.utc(duration.asMilliseconds()).format('mm:ss');
	};

	handleMouseDown = e => {
		const container = e.currentTarget;
		const containerRect = container.getBoundingClientRect();
		const offsetX = e.clientX - containerRect.left;
		const offsetY = e.clientY - containerRect.top;
		let drawOffsetY = e.clientY - containerRect.top;

		const heightDifference = containerRect.height - this.videoInnerWrapperRef.clientHeight;

		if (heightDifference > 0) {
			drawOffsetY = drawOffsetY - heightDifference / 2;
		}

		this.setState({
			isDrawing: true,
			coordinates: {
				x1: offsetX,
				x2: offsetX,
				y1: offsetY,
				y2: offsetY,
			},
			squareDrawingCoordinates: {
				x1: offsetX,
				x2: offsetX,
				y1: drawOffsetY,
				y2: drawOffsetY,
			},
		});
	};

	handleMouseMove = e => {
		if (!this.state.isDrawing) return;

		const container = e.currentTarget;
		const containerRect = container.getBoundingClientRect();
		const offsetX = e.clientX - containerRect.left;
		const offsetY = e.clientY - containerRect.top;
		let drawOffsetY = e.clientY - containerRect.top;

		const heightDifference = containerRect.height - this.videoInnerWrapperRef.clientHeight;

		if (heightDifference > 0) {
			drawOffsetY = drawOffsetY - heightDifference / 2;
		}

		this.setState(prevState => ({
			coordinates: {
				...prevState.coordinates,
				x2: offsetX,
				y2: offsetY,
			},
			squareDrawingCoordinates: {
				...prevState.squareDrawingCoordinates,
				x2: offsetX,
				y2: drawOffsetY,
			},
		}));
	};

	handleMouseUp = async e => {
		this.setState({ isDrawing: false });

		const container = e.currentTarget;
		const containerRect = container.getBoundingClientRect();

		const coordinates = { ...this.state.coordinates };
		const { x1, y1, x2, y2 } = coordinates;

		if (x1.toFixed(2) === x2.toFixed(2) || y1.toFixed(2) === y2.toFixed(2)) {
			return;
		}

		const containerWidth = containerRect.width;
		const containerHeight = containerRect.height;

		const aspectRatio = 16 / 9;

		const originalWidth = containerHeight * aspectRatio;
		const widthDifference = originalWidth - containerWidth;
		const originalHeight = containerWidth / aspectRatio;
		const heightDifference = originalHeight - containerHeight;

		let width = containerWidth;
		let height = containerHeight;
		let croppedx1 = x1;
		let croppedx2 = x2;
		let croppedy1 = y1;
		let croppedy2 = y2;

		if (widthDifference > 0) {
			width = containerWidth + widthDifference;
			croppedx1 += widthDifference / 2;
			croppedx2 += widthDifference / 2;
		}

		if (widthDifference <= 0 && heightDifference > 0) {
			height = containerHeight + heightDifference;
			croppedy1 += heightDifference / 2;
			croppedy2 += heightDifference / 2;
		}

		const x1Percentage = (croppedx1 / width) * 100;
		const y1Percentage = (croppedy1 / height) * 100;
		const x2Percentage = (croppedx2 / width) * 100;
		const y2Percentage = (croppedy2 / height) * 100;

		const response = await this.props.context.emitWithAck(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
			actioneeParticipantId: this.props.feed.participantId,
			conferenceId: this.props.conferenceInfo.conferenceId,
			participantId: this.props.conferenceInfo.participantId,
			data: JSON.stringify({
				x1: x1Percentage.toFixed(2),
				x2: x2Percentage.toFixed(2),
				y1: y1Percentage.toFixed(2),
				y2: y2Percentage.toFixed(2),
			}),
			command: 'dragToZoom',
			type: 'camera',
		});

		if (response.deviceControlsLocked) {
			this.props.showDeviceControlsLockedModal();
		}
	};

	getCoordinates = e => {
		const rect = e.target.getBoundingClientRect();
		const containerWidth = rect.width;
		const containerHeight = rect.height;

		const aspectRatio = 16 / 9;

		const originalWidth = containerHeight * aspectRatio;
		const widthDifference = originalWidth - containerWidth;
		const originalHeight = containerWidth / aspectRatio;
		const heightDifference = originalHeight - containerHeight;

		let width = containerWidth;
		let height = containerHeight;
		let offsetX = e.clientX - rect.left;
		let offsetY = e.clientY - rect.top;

		if (widthDifference > 0) {
			width = containerWidth + widthDifference;
			offsetX += widthDifference / 2;
		}

		if (widthDifference <= 0 && heightDifference > 0) {
			height = containerHeight + heightDifference;
			offsetY += heightDifference / 2;
		}

		const x = (offsetX / width) * 100;
		const y = (offsetY / height) * 100;
		const xFloat = parseFloat(x.toFixed(2));
		const yFloat = parseFloat(y.toFixed(2));
		return {
			x: xFloat > 0 ? xFloat : 0,
			y: yFloat > 0 ? yFloat : 0,
		};
	};

	sendCoordinates = async e => {
		if (!e.target) {
			return;
		}

		e.stopPropagation();

		const { x, y } = this.getCoordinates(e);

		const response = await this.props.context.emitWithAck(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
			actioneeParticipantId: this.props.feed.participantId,
			conferenceId: this.props.conferenceInfo.conferenceId,
			participantId: this.props.conferenceInfo.participantId,
			data: JSON.stringify({ x, y }),
			command: 'moveToPosition',
			type: 'camera',
		});

		if (response.deviceControlsLocked) {
			this.props.showDeviceControlsLockedModal();
		}
	};

	render() {
		const showFeed =
			this.props.feed.src !== null &&
			this.props.feed.status === ParticipantState.CONNECTED.type &&
			!this.props.feed.isCameraPrivacyOn;
		const showButtons = !this.props.disableButtons && this.props.feed.status === ParticipantState.CONNECTED.type;
		const isNurse = getUserRole() === UserRoles.NURSE;
		const showRaiseAlert = isNurse && getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.RaiseAlert]);
		const showContactNurses = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.ContactNurses]);
		const showCallPatient = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.CallPatient]);
		const showWalkieTalkie = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.WalkieTalkie]);
		const showMicrosoftTeams = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.MicrosoftTeams]);
		const showCall911 = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.Call911]);
		const showForwardToNurses = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.ForwardToNurses]);
		const showCareEventsForNurses = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.CareEventsForNurses]);
		const showNotes = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.Notes]);
		const showNightVision = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.NightVision]);
		const showEHR = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.EHR]);
		const showCareEventsForSafetyCompanions = getConfigurationValue(
			this.state.adminConfigurations[MonitoringSettings.CareEventsForSafetyCompanions]
		);
		const showPrecautions = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.Precautions]);
		const showPrivacy = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.Privacy]);
		const showRecording = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.Recording]);
		const showDisableVisits = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.DisableVisits]);
		const showDisableAi = getConfigurationValue(this.state.adminAiSettingsConfigurations[AiSetting.DISABLE_AI]);
		const showPTZCam = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.PTZCam]);
		const showStatAlarm = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.StatAlarm]);
		const showSilentMode = getConfigurationValue(this.state.adminAiSettingsConfigurations[AiSetting.SILENT_MODE]);

		const footerShownEmergencyButtonsLength = [
			showRaiseAlert,
			showContactNurses,
			showCallPatient,
			showWalkieTalkie,
			showMicrosoftTeams,
			showCareEventsForNurses,
			showCareEventsForSafetyCompanions,
		].filter(Boolean).length;

		const showHideEmergencyButtonsBtn = [
			showRaiseAlert,
			showContactNurses,
			showCallPatient,
			showWalkieTalkie,
			showMicrosoftTeams,
			showCall911,
			showForwardToNurses,
			showCareEventsForNurses,
			showCareEventsForSafetyCompanions,
			showNotes,
		].includes(true);

		const shouldShowAIVitals = () =>
			this.props.feed.areEmergencyButtonsHiden || (this.props.numberOfFeeds > 1 && !this.props.feed.isFeedExpanded);

		const shouldShowIVBagChart = () =>
			this.props.selectedFeed?.ivBagPercentage > 0 &&
			(this.props.feed.isFeedExpanded || this.props.numberOfFeeds === 1) &&
			isSettingEnabled(this.getFeedAiSettings(), PatientAiSetting.IV_BAG);

		const showMonitoringNotes = () => {
			const config = showFeed && showNotes;
			const noteVariant = getMonitoringConfigurationVariant(
				this.state.adminConfigurations[MonitoringSettings.Notes],
				MonitoringSettings.Notes
			);

			return noteVariant.value === NoteVariants.SHOW_ON_EXPAND
				? config && (this.props.feed.isFeedExpanded || this.props.numberOfFeeds === 1)
				: config && noteVariant.value === NoteVariants.SHOW_UNDER_TILE;
		};

		const newSetNotesHeight = () => {
			if (showMonitoringNotes() && !this.props.feed.isFeedExpanded && this.props.numberOfFeeds > 1) {
				return 25;
			}
			return this.notesHeight;
		};

		const isAnyMeasurementViewOn = () =>
			this.props.feed.isBioBeatActive ||
			this.props.feed.isLifeSignalsActive ||
			isSettingEnabled(this.getFeedAiSettings(), PatientAiSetting.VITAL_SIGNS_AI) ||
			(this.props.watchMeasurementsVisible?.length > 0 &&
				this.props.watchMeasurementsVisible?.some(item => item.value === true && item.deviceId === this.props.feed.deviceId));

		const getCanvasDimensions = () => {
			const videoElement = this.videoRef.current;

			const aspectRatio = 16 / 9;
			if (!this.videoWrapperRef || !videoElement) {
				return null;
			}
			const originalWidth = this.videoWrapperRef.clientHeight * aspectRatio;
			const widthDifference = originalWidth - this.videoWrapperRef.clientWidth;
			const heightDifference = videoElement.clientHeight - this.videoWrapperRef.clientHeight;
			let width = '100%';
			let height = '100%';
			let clientHeight = videoElement.clientHeight;

			if (showMonitoringNotes()) {
				clientHeight = videoElement.clientHeight + newSetNotesHeight();
			}
			if (this.videoWrapperRef.clientHeight >= clientHeight) {
				width = this.videoWrapperRef.clientWidth + widthDifference;
			}
			if (this.videoWrapperRef.clientHeight < clientHeight) {
				height = this.videoWrapperRef.clientHeight + heightDifference;
			}
			return {
				width,
				height,
			};
		};

		let videoFeedHeightForAlert = 0;

		if (this.videoWrapperRef) {
			videoFeedHeightForAlert = this.videoWrapperRef.clientHeight;

			if (showMonitoringNotes()) {
				videoFeedHeightForAlert = this.videoWrapperRef.clientHeight - 125;
			}
		}

		return (
			<>
				<VideoFeedWrapper
					className={classNames(this.props.className || '', this.state.isPrecautionsPortalOpen ? 'precautions-portal-open' : '')}
					ref={ref => {
						this.videoWrapperRef = ref;
					}}
					$isFitScreen={this.props.feed.roomType === RoomTypes.BABY_ROOM.type}>
					<VideoFeedInnerWrapper
						ref={ref => {
							this.videoInnerWrapperRef = ref;
						}}
						$isFullHeight={!showFeed || !showMonitoringNotes()}
						$notesHeight={newSetNotesHeight()}
						className={classNames(
							'should-hover-monitoring',
							this.props.feed.roomType === RoomTypes.BABY_ROOM.type ? 'baby-room-monitoring' : '',
							isAnyMeasurementViewOn() ? 'feed-w-measurements' : ''
						)}
						onMouseLeave={() => {
							this.setState({
								coordinates: {
									x1: 0,
									x2: 0,
									y1: 0,
									y2: 0,
								},
								squareDrawingCoordinates: {
									x1: 0,
									x2: 0,
									y1: 0,
									y2: 0,
								},
								isDrawing: false,
							});
						}}>
						{showFeed && !this.props.feed.isCameraPrivacyOn && (
							<>
								{showButtons && (
									<div className='upper-monitoring-control-buttons-wrapper'>
										<div className='monitoring-control-buttons flex'>
											<>
												{showRecording && this.props.feed.status === ParticipantState.CONNECTED.type && (
													<>
														<Button
															alt='icon'
															className={`rec-label alert-timeline-btn${
																this.state.running || this.state.time > 0 ? ' recording-on' : ''
															}`}
															position='bottom'
															tooltip={
																this.state.showSavingRecordingAlert
																	? this.props.intl.formatMessage({
																			id: 'videoRecordingSaving',
																	  })
																	: this.props.intl.formatMessage({
																			id: this.state.running || this.state.time > 0 ? 'stopRecording' : 'startRecording',
																	  })
															}
															onClick={() => {
																this.feedRecording(this.props.feed);
																this.setState(
																	prevState => ({
																		running: !prevState.running,
																	}),
																	() => {
																		this.setRecordingTimer();
																	}
																);
															}}
															disabled={this.state.recordingInitialized && this.state.time === 0}
															svgIcon={this.state.showSavingRecordingAlert && <Loader />}
															imgIcon={
																!this.state.showSavingRecordingAlert
																	? `${healthCareCdnUrl}monitoring/video-feed/${
																			!this.state.running && this.state.time === 0 ? 'rec' : 'stop'
																	  }-red.svg`
																	: ''
															}
															text={
																(this.state.running || this.state.time > 0) && (
																	<div>
																		<span>{this.getFormattedRecordingTime()}</span>
																	</div>
																)
															}
														/>
													</>
												)}
												{showPrecautions && !this.props.feed.isDefaultOwner && (
													<FeedColorsDropdown
														precautions={this.state.precautions}
														setIsPortalOpen={val =>
															this.setState({
																isPrecautionsPortalOpen: val,
															})
														}
														checkIsColorActive={this.checkIsColorActive}
														setFeedColor={this.setFeedColor}
													/>
												)}
												{this.props.feed?.RTSPcameras > 0 && (
													<Button
														className='alert-timeline-btn'
														onClick={() => this.props.toggleRTPSgridView(this.props.feed.deviceId)}
														position='bottom'
														tooltip={this.props.intl.formatMessage({
															id: this.props.feed.isGridView ? 'gridView' : 'singleView',
														})}
														icon='grid_on'
														alt={this.props.feed.isGridView ? 'gridView' : 'singleView'}
													/>
												)}
												{this.showAlerts() && !this.props.feed.isDefaultOwner && (
													<Button
														className={`alert-timeline-btn ${this.props.feed.isAlertTimelineVisible ? 'active' : ''}`}
														onClick={this.props.toggleAlerts}
														position='bottom'
														tooltip={this.props.intl.formatMessage({ id: 'alerts' })}
														imgIcon={
															!this.props.isDarkMode
																? `${healthCareCdnUrl}monitoring/video-feed/alerts${
																		this.props.feed.isAlertTimelineVisible ? '-active' : ''
																  }.svg?v3`
																: `${healthCareCdnUrl}${
																		this.props.feed.isAlertTimelineVisible
																			? 'monitoring/video-feed/alerts-active.svg?v3'
																			: 'dark-mode/alerts.svg'
																  }  `
														}
													/>
												)}
												{showDisableVisits && !this.state.disabledVisitTime.isDisabled && (
													<Button
														onClick={() =>
															this.setState(prevState => ({
																isDisableVisitsOpen: !prevState.isDisableVisitsOpen,
															}))
														}
														alt={this.props.intl.formatMessage({
															id: this.state.isDisabledHours ? 'enableVisits' : 'disableVisits',
														})}
														imgIcon={`${healthCareCdnUrl}monitoring/video-feed/disable-${
															this.state.isDisabledHours ? 'white' : 'red'
														}.svg`}
														className={classNames('rec-label alert-timeline-btn', {
															'--red-background': this.state.isDisabledHours,
														})}
														position='bottom'
														tooltip={this.props.intl.formatMessage({
															id: this.state.isDisabledHours ? 'enableVisits' : 'disableVisits',
														})}
													/>
												)}
												{showDisableVisits && this.state.disabledVisitTime.isDisabled && (
													<Button
														className={`alert-timeline-btn${this.state.disabledVisitTime ? ' privacy-on' : ''}`}
														onClick={this.handleEnableVisitingHours}
														position='bottom'
														tooltip={this.props.intl.formatMessage({
															id: this.state.disabledVisitTime ? 'enableVisits' : 'disableVisits',
														})}
														svgIcon={
															<DisabledCountDown
																disabledTime={this.state.disabledVisitTime}
																setDisabledTime={() =>
																	this.setState(prevState => ({
																		disabledVisitTime: { ...prevState.disabledVisitTime, isDisabled: false },
																	}))
																}
															/>
														}
													/>
												)}
												{showDisableAi && !this.state.disabledAiTime.isDisabled && !showSilentMode && (
													<Button
														className='rec-label alert-timeline-btn time-button'
														position='bottom'
														tooltip={this.props.intl.formatMessage({ id: 'disableAi' })}
														onClick={() =>
															this.setState(prevState => ({
																isDisableAiOpen: !prevState.isDisableAiOpen,
															}))
														}
														imgIcon={`${healthCareCdnUrl}monitoring/disable-ai-1.svg`}
														alt={this.props.intl.formatMessage({ id: 'disableAi' })}
													/>
												)}

												{showDisableAi && this.state.disabledAiTime.isDisabled && !showSilentMode && (
													<Button
														className={`alert-timeline-btn time-button${this.state.disabledAiTime ? ' privacy-on' : ''}`}
														onClick={this.handleEnableAi}
														data-position='bottom'
														data-tooltip={this.props.intl.formatMessage({
															id: this.state.disabledAiTime ? 'enableAi' : 'disableAi',
														})}
														imgIcon={`${healthCareCdnUrl}monitoring/disable-ai.svg`}
														alt={this.props.intl.formatMessage({ id: 'enableAi' })}
														svgIcon={
															<div>
																<DisabledCountDown
																	disabledTime={this.state.disabledAiTime}
																	setDisabledTime={() =>
																		this.setState(
																			prevState => ({
																				disabledAiTime: { ...prevState.disabledAiTime, isDisabled: false },
																			}),
																			() => this.emitDisableAiEvent(false)
																		)
																	}
																/>
															</div>
														}
													/>
												)}
												{showPrivacy && (
													<>
														<Button
															className={`alert-timeline-btn time-button${
																this.props.feed.aiPrivacyStatus && this.props.feed.privacyTimerMilliseconds > 0
																	? ' privacy-on'
																	: ''
															}`}
															onClick={() =>
																this.props.togglePrivacyMode(
																	this.props.feed.deviceId,
																	this.props.feed.aiPrivacyStatus,
																	this.props.feed.deviceOwner?.healthcareUserId
																)
															}
															position='bottom'
															tooltip={this.props.intl.formatMessage({
																id: this.props.feed.aiPrivacyStatus ? 'privacyOn' : 'privacyOff',
															})}
															imgIcon={
																!this.props.isDarkMode
																	? `${healthCareCdnUrl}privacy-mode/privacy${
																			this.props.feed.aiPrivacyStatus ? '' : '-gray'
																	  }.svg?v3`
																	: `${healthCareCdnUrl}${
																			this.props.feed.aiPrivacyStatus
																				? 'privacy-mode/privacy.svg?v3'
																				: 'dark-mode/privacy-gray.svg'
																	  }`
															}
															svgIcon={
																this.props.feed.aiPrivacyStatus &&
																this.props.feed.privacyTimerMilliseconds > 0 && (
																	<Countdown date={this.props.feed.privacyTimerMilliseconds} renderer={this.getCountDownTimer} />
																)
															}
															alt='icon'
														/>
													</>
												)}
												{this.props.feed.isCameraPrivacyOn && (
													<Button
														className='alert-timeline-btn camera-mic-off'
														position='bottom'
														tooltip={this.props.intl.formatMessage(
															{ id: 'seeingOrHearingPatient' },
															{
																value: this.props.helloName,
																value5: this.props.intl.formatMessage({ id: 'seeing' }),
																value2: this.props.intl.formatMessage({
																	id: getUserRole() === UserRoles.VISITOR ? 'theBaby' : 'thePatient', // familymember
																}),
																value3: this.props.intl.formatMessage({
																	id: 'cam',
																}),
																value4: ' ',
															}
														)}
														imgIcon={`${healthCareCdnUrl}monitoring/video-feed/camera-off.svg`}
													/>
												)}
												{this.props.feed.isMicPrivacyOn && (
													<Button
														className='alert-timeline-btn camera-mic-off'
														position='bottom'
														tooltip={this.props.intl.formatMessage(
															{ id: 'seeingOrHearingPatient' },
															{
																value: this.props.helloName,
																value5: this.props.intl.formatMessage({ id: 'hearing' }),
																value2: this.props.intl.formatMessage({
																	id: getUserRole() === UserRoles.VISITOR ? 'theBaby' : 'thePatient', // familymember
																}),
																value3: this.props.intl.formatMessage({
																	id: 'mic',
																}),
																value4: ' ',
															}
														)}
														alt={this.props.intl.formatMessage({ id: 'seeingOrHearingPatient' })}
														imgIcon={`${healthCareCdnUrl}monitoring/video-feed/mic-off.svg?v3`}
													/>
												)}
												{this.props.numberOfFeeds > 1 && (
													<div
														id='expand-icon'
														className={`call-button${this.props.feed.isFeedExpanded ? ' active' : ''}`}
														onClick={this.toggleExpand}
														data-position='bottom'
														data-tooltip={this.props.intl.formatMessage({
															id: this.props.feed.isFeedExpanded ? 'collapse' : 'expand',
														})}
													/>
												)}
												{this.showMonitoringSettings() && (
													<Button
														className='alert-timeline-btn'
														onClick={() => this.setState({ isConfigureFeedButtonsOpen: true })}
														position='bottom'
														tooltip={this.props.intl.formatMessage({ id: 'monitoringSettings' })}
														imgIcon={`${healthCareCdnUrl}${!this.props.isDarkMode ? 'header' : 'dark-mode'}/account-settings.svg`}
														alt={this.props.intl.formatMessage({
															id: 'monitoringSettings',
														})}
													/>
												)}
											</>
										</div>
									</div>
								)}
								<div
									onClick={event => this.sendRTSPcoordinates(event)}
									className={this.props.feed.roomType === RoomTypes.BABY_ROOM.type ? 'full-height' : ''}>
									<Video
										ref={this.videoRef}
										feed={this.props.feed.src}
										muted={!this.props.feed.isPeerSpeakerActive}
										height={this.videoInnerWrapperRef ? (this.videoInnerWrapperRef.clientWidth * 9) / 16 : 0}
										width={this.videoInnerWrapperRef ? this.videoInnerWrapperRef.clientWidth : 0}
									/>

									<>
										<canvas
											ref={ref => {
												this.canvasRef = ref;
											}}
											height={this.state.windowHeight}
											width={this.state.windowWidth}
											className={
												this.props.feed.roomType === RoomTypes.BABY_ROOM.type ? 'skeleton-canvas' : 'scaled-skeleton-canvas'
											}
											style={
												this.props.feed.roomType !== RoomTypes.BABY_ROOM.type
													? {
															width: getCanvasDimensions()?.width || '100%',
															height: getCanvasDimensions()?.height || '100%',
													  }
													: null
											}
										/>
										<canvas
											ref={ref => {
												this.canvas2Ref = ref;
											}}
											height={this.state.windowHeight}
											width={this.state.windowWidth}
											className={
												this.props.feed.roomType === RoomTypes.BABY_ROOM.type ? 'skeleton-canvas' : 'scaled-skeleton-canvas'
											}
											style={
												this.props.feed.roomType !== RoomTypes.BABY_ROOM.type
													? {
															width: getCanvasDimensions()?.width || '100%',
															height: getCanvasDimensions()?.height || '100%',
													  }
													: null
											}
										/>
									</>

									{(this.props.feed.isFeedExpanded || this.props.numberOfFeeds === 1) && this.state.isPtzAdminConfig && (
										<>
											<Video
												feed={this.props.feed.src}
												muted={!this.props.feed.isPeerSpeakerActive}
												style={{ opacity: '0' }}
												onDoubleClick={this.sendCoordinates}
												onMouseDown={
													this.props.feed.cameraType === CameraType.HUDDLE &&
													this.props.feed.isHuddleCamConnected &&
													this.props.feed.roomType !== RoomTypes.BABY_ROOM.type
														? this.handleMouseDown
														: undefined
												}
												onMouseMove={
													this.props.feed.cameraType === CameraType.HUDDLE &&
													this.props.feed.isHuddleCamConnected &&
													this.props.feed.roomType !== RoomTypes.BABY_ROOM.type
														? this.handleMouseMove
														: undefined
												}
												onMouseUp={
													this.props.feed.cameraType === CameraType.HUDDLE &&
													this.props.feed.isHuddleCamConnected &&
													this.props.feed.roomType !== RoomTypes.BABY_ROOM.type
														? this.handleMouseUp
														: undefined
												}
											/>
											{this.state.isDrawing && (
												<div
													className='drag-zoom-square'
													style={{
														left: `${
															this.state.squareDrawingCoordinates.x2 > this.state.squareDrawingCoordinates.x1
																? this.state.squareDrawingCoordinates.x1
																: this.state.squareDrawingCoordinates.x2
														}px`,
														top: `${
															this.state.squareDrawingCoordinates.y2 > this.state.squareDrawingCoordinates.y1
																? this.state.squareDrawingCoordinates.y1
																: this.state.squareDrawingCoordinates.y2
														}px`,
														width: `${
															this.state.squareDrawingCoordinates.x2 > this.state.squareDrawingCoordinates.x1
																? this.state.squareDrawingCoordinates.x2 - this.state.squareDrawingCoordinates.x1
																: this.state.squareDrawingCoordinates.x1 - this.state.squareDrawingCoordinates.x2
														}px`,
														height: `${
															this.state.squareDrawingCoordinates.y2 > this.state.squareDrawingCoordinates.y1
																? this.state.squareDrawingCoordinates.y2 - this.state.squareDrawingCoordinates.y1
																: this.state.squareDrawingCoordinates.y1 - this.state.squareDrawingCoordinates.y2
														}px`,
													}}
												/>
											)}
										</>
									)}
									{(this.props.feed.warning.value ||
										this.props.feed.isStatAlarmActive ||
										(this.props.feed.voiceOverAlert && !this.props.feed.isVoiceOverOtherInitiator)) && (
										<div className='fall-detected-red-warning fall-is-detected' />
									)}
								</div>
								{this.props.feed.aiToastDetails?.deviceId && (
									<AIToastMessage
										display={true}
										position='center-bottom'
										className={AiAlertType.PATIENT_INACTIVE_INFORMATION}
										aiToastDetails={this.props.feed.aiToastDetails}
										onButtonClick={() => {
											this.sendAutomaticAlert(this.props.feed.aiToastDetails?.alertId, true);
											this.props.onAlertInfoClose(this.props.feed.deviceId, true);
										}}
										onToastMessageClose={() => this.props.onAlertInfoClose(this.props.feed.deviceId, true)}
										showForwardToNurses={showForwardToNurses}
									/>
								)}
								<WarningDetection
									showForwardToNurses={showForwardToNurses}
									warningObject={this.props.feed.warning}
									alertType={AlertTypes.DANGER}
									onAlertClose={this.onAlertCloseClick}
									isFeedExpanded={this.props.feed.isFeedExpanded}
									onForwardToNurses={(alertId, isAiAlert) => this.sendAutomaticAlert(alertId, isAiAlert)}
									onAlertCloseClick={this.onAlertCloseClick}
									numberOfFeeds={this.props.numberOfFeeds}
									feedDeviceId={this.props.feed.deviceId}
									setSnoozeType={this.props.setSnoozeType}
									videoHeight={videoFeedHeightForAlert}
								/>
								{shouldShowIVBagChart() && (
									<CircularDetectionChart
										percentage={this.props.selectedFeed?.ivBagPercentage}
										type={DetectionTypes.IV_BAG_FLUID_LEVEL}
									/>
								)}
								{/** HIDDEN FOR NOW UNTIL FURTHER NOTICE */}
								{/* {this.state.mobilityPercentage > 0 && (this.props.feed.isFeedExpanded || this.props.numberOfFeeds === 1) && (
									<CircularDetectionChart percentage={this.state.mobilityPercentage} type={DetectionTypes.MOBILITY_SCORE} />
								)} */}
								{this.props.feed.alertInfo?.deviceId && (
									<AlertInformation
										alertInfo={this.props.feed.alertInfo}
										onAlertClose={this.props.onAlertInfoClose}
										icon={<img src={this.props.feed.alertInfo.imgUrl} alt='ico' />}
									/>
								)}
								{this.state.isIvBagMeasuring && (
									<AlertInformation
										alertInfo={{
											deviceId: this.props.feed.deviceId,
											text: this.props.intl.formatMessage({ id: 'measuringIv' }),
										}}
										onAlertClose={() => {
											this.setState({
												isIvBagMeasuring: false,
											});
										}}
										icon={<IvBag color={this.props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />}
									/>
								)}
								{this.props.feed.analyticsData?.deviceId && (
									<AlertInformation
										alertInfo={this.props.feed.analyticsData}
										onAlertClose={this.props.onAnalyticsToastMessageClose}
										icon={this.props.feed.analyticsData?.icon}
									/>
								)}
								{!this.props.feed.areEmergencyButtonsHiden &&
									(this.props.feed.isFeedExpanded || this.props.numberOfFeeds === 1) && (
										<div className='flex video-feed-warning-options'>
											{showCareEventsForNurses && (
												<div
													onClick={this.toggleCareEventsForNurses}
													className='warning-options-item position-relative'
													ref={ref => {
														this.careEventsForNurses = ref;
													}}>
													<img src={`${this.monitoringIconLink}video-feed/report-as-false.svg`} alt='icon' />
													<p className='--gray-color'>{translate('careEvents')}</p>
												</div>
											)}
											{showCareEventsForSafetyCompanions && (
												<div
													onClick={this.toggleCareEventsForSafetyCompanions}
													className='warning-options-item position-relative'
													ref={ref => {
														this.careEventsForSafetyCompanions = ref;
													}}>
													<img src={`${this.monitoringIconLink}video-feed/report-as-false.svg`} alt='icon' />
													<p className='--gray-color'>{translate('safetyCompanions')}</p>
												</div>
											)}
											{showRaiseAlert && (
												<div
													onClick={this.toggleReport}
													className='warning-options-item position-relative'
													ref={ref => {
														this.raiseAlertRef = ref;
													}}>
													<img src={`${this.monitoringIconLink}video-feed/report-as-false.svg`} alt='icon' />
													<p className='--gray-color'>{translate('raiseAlert')}</p>
												</div>
											)}
											{showContactNurses && (
												<div
													onClick={this.toggleContactNurses}
													className='warning-options-item  position-relative'
													ref={ref => {
														this.contactNursesRef = ref;
													}}>
													<img src={`${this.monitoringIconLink}video-feed/contact-nurses.svg`} alt='icon' />
													<p className='--blue-color'>{translate('contactNurses')}</p>
													{this.state.isContactNursesOpen && (
														<div className='video-feed-sub-options-wrapper'>
															<VideoFeedSubOption
																isReports={false}
																onSelectSpecificNurse={this.selectSpecificNurse}
																onCallNurses={() => this.props.onCallNurses()}
																onSendGroupMessage={this.toggleSendTeamsMessage}>
																{this.state.isSpecificNurseOpen && (
																	<ul className='list-group workflow-list-group monitoring-nurses'>
																		{this.state.checkedInNurses.length === 0 && <p>No checked in nurses available!</p>}
																		{this.state.checkedInNurses.length > 0 &&
																			this.state.checkedInNurses.map(item => (
																				<span key={item.userId} onClick={() => this.props.onCallNurses(item)}>
																					<li className='flex flex-align-center video-feed-list-item'>
																						<ProfilePicture
																							className='video-feed-profile-pic'
																							firstName={item.firstName}
																							lastName={item.lastName}
																							profilePicture=''
																						/>
																						<p>
																							{item.firstName} {item.lastName}
																						</p>
																					</li>
																				</span>
																			))}
																	</ul>
																)}
															</VideoFeedSubOption>
														</div>
													)}
												</div>
											)}

											{showCallPatient && (
												<div className='warning-options-item' onClick={this.props.callHelloPatient}>
													<img src={`${this.monitoringIconLink}video-feed/call-patient.svg`} alt='icon' />
													<p className='--green-color'>{translate('callPatient')}</p>
												</div>
											)}
											{showWalkieTalkie && (
												<div
													onClick={this.toggleWalkieTalkie}
													className={classNames(
														'warning-options-item',
														this.state.isSkelisWalkieTalkieOnetonOn ? ' walkie-talkie-background' : '',
														!this.state.isWalkieTalkieOn && this.state.participantTalking ? ' is-participant-talking-border' : '',
														this.props.feed.isPttAvailable ? '' : 'disabled'
													)}
													ref={ref => {
														this.walkieTalkieRef = ref;
													}}>
													<img
														src={`${this.monitoringIconLink}video-feed/walkie-talkie${
															this.state.isWalkieTalkieOn ? '-active' : ''
														}.svg`}
														alt='icon'
													/>
													<p className='--blue-color'>
														{this.state.isWalkieTalkieOn ? translate('stopTalking') : 'Walkie Talkie'}
													</p>
													{!this.state.isWalkieTalkieOn && (
														<span
															onClick={e => {
																e.stopPropagation();
																this.toggleWKSeakers();
															}}
															className='material-icons walkie-talkie-volume'>
															{this.state.isWalkieTalkieVolumeOn ? 'volume_up' : 'volume_mute'}
														</span>
													)}
													{!this.state.isWalkieTalkieOn && this.state.participantTalking && (
														<div className='participant-talking'>{this.state.participantTalking} is talking</div>
													)}
													{this.state.isWalkieTalkieOn && (
														<div className='audio-level-badge'>
															{this.audioLevels.map(item => (
																<span
																	key={item.id}
																	style={{
																		height: `${this.state.myMicrophoneAudioLevel * item.value}px`,
																	}}
																/>
															))}
														</div>
													)}
												</div>
											)}
											{showMicrosoftTeams && (
												<div
													onClick={this.toggleMicrosoftTeams}
													className='warning-options-item position-relative'
													ref={ref => {
														this.microsoftTeamsRef = ref;
													}}>
													<img src={`${this.monitoringIconLink}video-feed/microsoft-teams.svg`} alt='icon' />
													<p className='teams-color'>Microsoft Teams</p>
													{this.state.isMicrosoftTeamsOpen && (
														<div
															className={classNames(
																'video-feed-sub-options-wrapper',
																footerShownEmergencyButtonsLength > 3 ? 'right' : ''
															)}>
															<VideoFeedSubOption
																isReports={false}
																className='position-relative'
																onSelectSpecificNurse={this.selectSpecificNurse}
																onSendGroupMessage={this.toggleSendGroupMessage}
																onForwardToNurses={alertId => this.sendManualAlert(alertId)}>
																{this.state.isSpecificNurseOpen && (
																	<ul
																		className={`list-group workflow-list-group monitoring-nurses ${
																			this.state.teamMembers.length === 0 && 'flex-justify-center'
																		}`}>
																		{this.state.teamMembers.length === 0 && <p>{translate('noTeamMembers')}</p>}
																		{this.state.teamMembers.map(item => (
																			<span onClick={() => this.callMSTeamsSpecificNurse(this.props.channelId, item)}>
																				<li className='flex flex-align-center video-feed-list-item'>
																					<ProfilePicture
																						className='video-feed-profile-pic'
																						fullName={item.name}
																						profilePicture=''
																					/>
																					<p>{item.name}</p>
																				</li>
																			</span>
																		))}
																	</ul>
																)}
															</VideoFeedSubOption>
														</div>
													)}
												</div>
											)}
											{showCall911 && (
												<div onClick={this.props.startEmergencyCall} className='warning-options-item position-relative'>
													<img src={`${this.monitoringIconLink}video-feed/call-patient-red.svg`} alt='icon' />
													<p className='--red-color'>{translate('call911')}</p>
												</div>
											)}
										</div>
									)}
								<ToastMessage
									showToast={this.state.isMoreThanOneAi}
									onClose={() => this.setState({ isMoreThanOneAi: false })}
									className={classNames(
										'feed-toast-message position-absolute',
										!showHideEmergencyButtonsBtn ? 'video-feed-toast-message' : ''
									)}>
									<span>{translate('noMoreThanOneAiType')}</span>
								</ToastMessage>
								{this.state.isRaiseAlertOpen && (
									<ManualReportModal
										onForwardToNurses={(alertId, comment) => this.sendManualAlert(alertId, comment)}
										onReportClose={() => this.setState({ isRaiseAlertOpen: false })}
										className='position-relative'
										hierarchyNaming={this.props.hierarchyNaming}
										isManualReportLoading={this.state.isManualReportLoading}
										onForwardToMSNurses={(alertId, comment) => this.sendManualAlertToMSNurses(alertId, comment)}
										isForwardMSTeamsLoading={this.state.isForwardMSTeamsLoading}
									/>
								)}
								{this.state.careEventsForNurses && (
									<CareEventsModal
										eventType={CareEventTypes.FrontLineNurses}
										onForwardToNurses={(alertId, comment) => this.sendManualAlert(alertId, comment)}
										onReportClose={() => this.setState({ careEventsForNurses: false })}
										className='position-relative'
										hierarchyNaming={this.props.hierarchyNaming}
										onCareEventSave={(alertId, comment) => this.onCareEventSave(alertId, comment)}
										isManualReportLoading={this.state.isManualReportLoading}
									/>
								)}
								{this.state.careEventsForSafetyCompanions && (
									<CareEventsModal
										eventType={CareEventTypes.SafetyCompanions}
										onForwardToNurses={(alertId, comment) => this.sendManualAlert(alertId, comment)}
										onReportClose={() => this.setState({ careEventsForSafetyCompanions: false })}
										className='position-relative'
										hierarchyNaming={this.props.hierarchyNaming}
										onCareEventSave={(alertId, comment) => this.onCareEventSave(alertId, comment)}
										isManualReportLoading={this.state.isManualReportLoading}
									/>
								)}
								{this.state.isSendGroupMessageOpen && (
									<SendMessageModal
										isSendGroupMessageOpen={this.state.isSendGroupMessageOpen}
										sendGroupMessage={this.sendGroupMessage}
										closeSendGroupMessage={this.closeSendGroupMessage}
										selectedFloor={this.props.floorName}
										isSendMessageLoading={this.state.isSendMessageLoading}
									/>
								)}
								{this.state.isSendTeamsMessageOpen && (
									<SendMessageModal
										isSendGroupMessageOpen={this.state.isSendTeamsMessageOpen}
										sendGroupMessage={this.sendTeamsMessage}
										closeSendGroupMessage={() => this.setState({ isSendTeamsMessageOpen: false })}
										selectedFloor={this.props.floorName}
										isSendMessageLoading={this.state.isSendMessageLoading}
									/>
								)}
								<PopUpAlert
									alertType={AlertTypes.SUCCESS}
									display={this.state.showRecordingSavedAlert}
									onAlertClose={this.closeRecordingAlerts}
									contentText={`${this.props.intl.formatMessage({ id: 'videoRecordingSaving' })}...`}
									isSilent={true}
									centerTop={true}
								/>
								<PopUpAlert
									alertType={AlertTypes.SUCCESS}
									display={this.props.feed.voiceOverAlarmConfirmation}
									onAlertClose={this.props.closeVoiceOverAlarmConfirmation}
									contentText={this.props.feed.voiceOverAlarmConfirmation}
									isSilent={true}
									centerTop={true}
									selfCloseTimeOut={2000}
								/>
								<PopUpAlert
									alertType={AlertTypes.SUCCESS}
									display={this.props.feed.statAlarmConfirmation}
									onAlertClose={this.props.closeStatAlarmConfirmation}
									contentText={this.props.feed.statAlarmConfirmation}
									isSilent={true}
									centerTop={true}
									selfCloseTimeOut={2000}
								/>
								<PopUpAlert
									alertType={AlertTypes.DANGER}
									display={this.state.showRecordingFailedAlert}
									onAlertClose={this.closeRecordingAlerts}
									contentText={this.props.intl.formatMessage({ id: 'somethingWentWrong' })}
									isSilent={true}
									centerTop={true}
								/>
							</>
						)}
						<div
							id='close-button'
							className='call-button flex-align-center flex-justify-center cursor-pointer'
							onClick={this.props.onCloseClick}
							data-position='bottom'
							data-tooltip={this.props.intl.formatMessage({
								id: 'close',
							})}>
							<i className='material-icons'>close</i>
						</div>

						<div
							className={classNames(
								'video-feed-title',
								this.props.feed.privacyTimerMilliseconds > 0 && this.props.feed.privacyTimerMilliseconds
									? ' title-privacy-on'
									: '',
								this.state.running || this.state.time > 0 ? 'title-recording-on' : '',
								showDisableAi ? 'title-disable-ai-feed' : '',
								showPrivacy ? 'title-privacy-feed' : '',
								this.state.disabledAiTime.isDisabled ? 'title-ai-disabled-on' : ''
							)}>
							<p>
								{this.props.feed.roomName}
								{!this.props.feed.deviceOwner?.isVirtualPatient && this.props.feed.deviceOwner?.patientFormattedName
									? ' - '
									: ' '}
								{!this.props.feed.deviceOwner?.isVirtualPatient && this.props.feed.deviceOwner?.patientFormattedName}
							</p>
						</div>
						<div className='monitoring-nurses-badge'>
							<RiskAssessmentBadge feed={this.props.feed} />

							{this.state.nameOfNurseAlsoMonitoring && (
								<span
									className='material-icons'
									data-position='bottom'
									data-tooltip={`${this.props.intl.formatMessage({ id: 'beingMonitoredBy' })} ${
										this.state.nameOfNurseAlsoMonitoring
									}`}>
									plus_one
								</span>
							)}
						</div>

						{showButtons && (
							<>
								<div className='monitoring-control-buttons-wrapper'>
									<div
										className={classNames(
											'monitoring-control-buttons flex',
											this.state.isAiVisible ? 'open-ai-dropdown' : '',
											showStatAlarm ? '' : 'stat-alarm-hidden'
										)}>
										<>
											{showNightVision && this.props.feed.cameraType === CameraTypes.HELLO && (
												<div
													id='night-vision-icon'
													onClick={this.props.onToggleNightVision}
													className={classNames('call-button', this.props.feed.isNightVisionActive ? 'active' : '')}
													data-position='top'
													data-tooltip={this.props.intl.formatMessage({
														id: this.props.feed.isNightVisionActive ? 'nightVisionOn' : 'nightVisionOff',
													})}
												/>
											)}
											{showEHR && !this.props.feed.isDefaultOwner && (
												<div
													id='health-data-icon'
													className={classNames('call-button', this.props.feed.isVitalSignsVisible ? 'active' : '')}
													onClick={this.props.toggleVitalSigns}
													data-position='top'
													data-tooltip='EHR'
												/>
											)}
											<VoiceOverButton
												feed={this.props.feed}
												isVoiceOverOptionsVisible={this.state.isVoiceOverOptionsVisible}
												toggleVoiceOver={this.props.toggleVoiceOver}
												toggleVoiceOverOptions={() =>
													this.setState(prevState => ({
														isVoiceOverOptionsVisible: !prevState.isVoiceOverOptionsVisible,
													}))
												}
												voiceOverLanguages={this.props.voiceOverLanguages}
											/>
											{(this.props.feed.isFeedExpanded || this.props.numberOfFeeds === 1) && showHideEmergencyButtonsBtn && (
												<div
													id='emergency-buttons-icon'
													className={classNames('call-button', this.props.feed.areEmergencyButtonsHiden ? '' : 'active')}
													onClick={() => this.props.onToggleEmergencyButtons(this.props.feed)}
													data-position='top'
													data-tooltip={this.props.intl.formatMessage({
														id: !this.props.feed.areEmergencyButtonsHiden ? 'hideEmergencyButtons' : 'showEmergencyButtons',
													})}
												/>
											)}
											{showPTZCam && (this.props.feed.isFeedExpanded || this.props.numberOfFeeds === 1) && (
												<div
													id='ptz-icon'
													className={classNames('call-button', this.props.feed.isPtzActive ? 'active' : '')}
													onClick={() => this.props.onTogglePtz(this.props.feed)}
													data-position='top'
													data-tooltip={this.props.intl.formatMessage({
														id: this.props.feed.isPtzActive ? 'hidePTZ' : 'showPTZ',
													})}
												/>
											)}
											<div className='flex flex-basis-100 flex-justify-center'>
												{showDisableAi && !this.state.disabledAiTime.isDisabled && !showSilentMode && (
													<Button
														className='rec-label alert-timeline-btn time-button'
														position='top'
														tooltip={this.props.intl.formatMessage({ id: 'disableAi' })}
														onClick={() =>
															this.setState(prevState => ({
																isDisableAiOpen: !prevState.isDisableAiOpen,
															}))
														}
														imgIcon={`${healthCareCdnUrl}monitoring/disable-ai-1.svg`}
														alt={this.props.intl.formatMessage({
															id: 'disableAi',
														})}
													/>
												)}
												{showDisableAi && this.state.disabledAiTime.isDisabled && !showSilentMode && (
													<Button
														className={`alert-timeline-btn time-button${this.state.disabledAiTime ? ' privacy-on' : ''}`}
														onClick={this.handleEnableAi}
														data-position='bottom'
														data-tooltip={this.props.intl.formatMessage({
															id: this.state.disabledAiTime ? 'enableAi' : 'disableAi',
														})}
														imgIcon={`${healthCareCdnUrl}monitoring/disable-ai.svg`}
														alt={this.props.intl.formatMessage({ id: 'enableAi' })}
														svgIcon={
															<div>
																<DisabledCountDown
																	disabledTime={this.state.disabledAiTime}
																	setDisabledTime={() =>
																		this.setState(
																			prevState => ({
																				disabledAiTime: { ...prevState.disabledAiTime, isDisabled: false },
																			}),
																			() => this.emitDisableAiEvent(false)
																		)
																	}
																/>
															</div>
														}
													/>
												)}
												{showNightVision && this.props.feed.cameraType === CameraTypes.HELLO && (
													<div
														id='night-vision-icon'
														onClick={this.props.onToggleNightVision}
														className={classNames(
															'alert-timeline-btn time-button ai-button-bottom',
															this.props.feed.isNightVisionActive ? 'active' : ''
														)}
														data-position='top'
														data-tooltip={this.props.intl.formatMessage({
															id: this.props.feed.isNightVisionActive ? 'nightVisionOn' : 'nightVisionOff',
														})}
													/>
												)}
											</div>
										</>
									</div>
								</div>
								<div className='static-buttons'>
									{showStatAlarm && (
										<div
											id='stat-alarm-icon'
											onClick={this.props.toggleStatAlarm}
											className={classNames(
												'call-button',
												this.props.feed.isStatAlarmActive ? 'active' : '',
												this.props.feed.voiceOverAlert ? 'disabled' : ''
											)}
											data-tooltip={this.props.intl.formatMessage({
												id: this.props.feed.isStatAlarmActive ? 'disableStatAlarm' : 'enableStatAlarm',
											})}
											data-position='top'
										/>
									)}
									<div
										id='mic-icon'
										className={classNames('call-button', this.props.feed.isMyMicActive ? 'active' : '')}
										onClick={this.props.onToggleMyMicrophone}
										data-position='top'
										data-tooltip={this.props.intl.formatMessage({
											id: this.props.feed.isMyMicActive ? 'microphoneOn' : 'microphoneMuted',
										})}
									/>
									<div
										id='speaker-icon'
										onClick={this.props.onTogglePeerSpeaker}
										className={classNames('call-button', this.props.feed.isPeerSpeakerActive ? 'active' : '')}
										data-position='top'
										data-tooltip={this.props.intl.formatMessage({
											id: this.props.feed.isPeerSpeakerActive ? 'soundOn' : 'soundOff',
										})}
									/>
								</div>
								<VideoFeedColorsWrapper>
									{this.state.precautions?.map(item => (
										<>{item.active && <div style={{ backgroundColor: item?.boxColor }} />}</>
									))}
								</VideoFeedColorsWrapper>
							</>
						)}
						{this.props.feed.isBioBeatActive && (
							<BioBeatMeasurements
								measurements={this.props.feed.bioBeatmesurements}
								isPtzActive={this.props.feed.isPtzActive}
								shouldShowAIVitals={shouldShowAIVitals()}
								shouldShowAllItems={this.props.numberOfFeeds === 1 || this.props.feed.isFeedExpanded}
								hasConditions={Object.keys(this.props.feed.lastCondition).length > 0}
								notesShowing={showNotes}
								numberOfFeeds={this.props.numberOfFeeds}
								measuredAt={this.props.feed.bioBeatLastMeasurement}
							/>
						)}
						{this.props.feed.isLifeSignalsActive && (
							<LifeSignalsMeasurements
								isHidden={this.props.feed.isLifeSignalsPopupOpen}
								isPtzActive={this.props.feed.isPtzActive}
								shouldShowAIVitals={shouldShowAIVitals()}
								shouldShowAllItems={this.props.numberOfFeeds === 1 || this.props.feed.isFeedExpanded}
								hasConditions={Object.keys(this.props.feed.lastCondition).length > 0}
								notesShowing={showNotes}
								numberOfFeeds={this.props.numberOfFeeds}
								showLifeSignalsIframe={this.props.showLifeSignalsIframe}
								deviceOwner={this.props.feed.deviceOwner}
							/>
						)}

						{isSettingEnabled(this.getFeedAiSettings(), PatientAiSetting.VITAL_SIGNS_AI) && !showSilentMode && (
							<VitalSignsAI
								showAllVitalSigns={this.props.numberOfFeeds === 1 || this.props.feed.isFeedExpanded}
								isPtzActive={this.props.feed.isPtzActive}
								emergencyButtonsHidden={this.props.feed.areEmergencyButtonsHiden}
								hasConditions={Object.keys(this.props.feed.lastCondition).length > 0}
								notesShowing={showNotes}
								numberOfFeeds={this.props.numberOfFeeds}
							/>
						)}
						{this.props.watchMeasurementsVisible?.length > 0 &&
							this.props.watchMeasurementsVisible?.some(
								item => item.value === true && item.deviceId === this.props.feed.deviceId
							) && (
								<WatchMeasurements
									isPtzActive={this.props.feed.isPtzActive}
									shouldShowAIVitals={shouldShowAIVitals()}
									shouldShowAllItems={this.props.numberOfFeeds === 1 || this.props.feed.isFeedExpanded}
									hasConditions={Object.keys(this.props.feed.lastCondition).length > 0}
									notesShowing={showNotes}
									numberOfFeeds={this.props.numberOfFeeds}
								/>
							)}
					</VideoFeedInnerWrapper>
					{showMonitoringNotes() && (
						<MonitoringNotes
							showDictationButton={this.props.numberOfFeeds === 1 || this.props.feed.isFeedExpanded}
							roomId={this.props.feed.roomId}
							height={newSetNotesHeight()}
						/>
					)}
					{!showFeed && (
						<div className='center-loader'>
							<p>{this.getDeviceStatus()}</p>
							{this.props.feed.status.type === ParticipantState.CONNECTING.type && <Loader />}
						</div>
					)}
					{this.state.error && (
						<Alert display={this.state.error} fixed={true} hideCloseButton={true} message={this.state.error} variant='dark' />
					)}
					{this.state.isDisableVisitsOpen && (
						<DisableVisitingHoursModal
							setIsDisableVisitsOpen={() =>
								this.setState(prevState => ({
									isDisableVisitsOpen: !prevState.isDisableVisitsOpen,
								}))
							}
							roomId={this.props.feed.roomId}
							setDisabledVisitTime={obj => {
								this.setState({ disabledVisitTime: obj });
							}}
						/>
					)}
					{this.state.isDisableAiOpen && (
						<DisableAiModal
							setIsDisableVisitsOpen={() =>
								this.setState(prevState => ({
									isDisableAiOpen: !prevState.isDisableAiOpen,
								}))
							}
							setDisabledVisitTime={obj => {
								this.setState({ disabledAiTime: obj });
							}}
							roomId={this.props.feed.roomId}
							disabledAiControls={this.state.disabledAiControls}
							emitDisableAiEvent={() => {
								this.emitDisableAiEvent(true);
							}}
						/>
					)}
					{this.state.isFallsPrecautionsModalOpen && (
						<FallPreventionModal
							onModalClose={() =>
								this.setState({
									isFallsPrecautionsModalOpen: false,
								})
							}
							deviceId={this.props.feed.deviceId}
							toggleFallPrevention={this.props.toggleFallPrevention}
							isDefaultOwner={this.props.feed.isDefaultOwner}
							adminAiSettingsConfigurations={this.state.adminAiSettingsConfigurations}
							owner={this.props.feed.deviceOwner}
							roomId={this.props.feed.roomId}
							deviceFamily={this.props.feed.deviceFamily}
						/>
					)}
					{this.state.isConfigureFeedButtonsOpen && (
						<ConfigurableButtonsMonitoring
							roomId={this.props.feed.roomId}
							setIsMonitoringSettingsOpen={() => this.setState({ isConfigureFeedButtonsOpen: false })}
							adminConfigurations={this.state.adminConfigurations}
							updateMonitoringSetting={this.updateMonitoringSetting}
							adminAiSettingsConfigurations={this.state.adminAiSettingsConfigurations}
							deviceId={this.props.feed.deviceId}
						/>
					)}
				</VideoFeedWrapper>
				<CameraControls
					feed={this.props.feed}
					conferenceInfo={this.props.conferenceInfo}
					sendCameraEvent={this.props.sendCameraEvent}
					showPTZ={this.props.shouldShowPTZ && this.state.isPtzAdminConfig}
					showDeviceControlsLockedModal={this.props.showDeviceControlsLockedModalState}
					setActiveDevice={(deviceId, cameraType) => this.props.setActiveDevice(deviceId, cameraType)}
					isDarkMode={this.props.isDarkMode}
					isInDoubleCall={this.state.nameOfNurseAlsoMonitoring}
				/>
			</>
		);
	}
}

const VideoFeedWrapper = styled.div`
	background: var(--gray-5);
	box-shadow: 0 1px 2px var(--gray-8-o10);
	position: relative;

	header {
		width: 100%;
		background: var(--gray-4);
		display: flex;
		align-items: center;
		padding: var(--spacing-m);
	}

	h1 {
		font-size: 14px;
		color: var(--gray-0);
		padding: 0;
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		width: calc(100% - 100px);
		text-align: left;
		text-overflow: ellipsis;
	}

	ul {
		display: flex;
		margin-left: auto;
	}

	ul li i {
		color: var(--gray-0);
		cursor: pointer;
		font-size: 18px;
		margin-right: var(--spacing-s);
	}

	ul li:last-of-type i {
		margin-right: 0;
	}

	.dropdown__items a > i {
		margin: 0;
	}

	video {
		${props =>
			props.$isFitScreen &&
			css`
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			`}

		${props =>
			!props.$isFitScreen &&
			css`
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto;
				overflow: hidden;
				object-fit: cover;
			`}
	}

	.my-microphone {
		background: var(--gray-0);
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		font-size: 0;
		text-decoration: none;

		i {
			color: var(--blue-2);
			font-size: 16px;
		}
	}

	.my-microphone.active {
		background: var(--blue-2);
	}

	.my-microphone.active i {
		color: var(--gray-0);
	}

	.dropdown {
		padding: 0;
	}

	.center-loader p {
		color: var(--gray-0);
	}
`;

const VideoFeedColorsWrapper = styled.div`
	display: flex;
	height: 5px;
	width: 100%;
	position: absolute;
	z-index: 1;
	bottom: 0;

	div {
		flex: 1;
		height: 100%;
	}
`;

const VideoFeedInnerWrapper = styled.div`
	position: relative;
	height: ${props => `calc(100% - ${props.$notesHeight || 0}px)`};
	overflow: hidden;

	${props =>
		props.$isFullHeight &&
		css`
			height: 100%;
		`}
`;

VideoFeed.propTypes = {
	feed: PropTypes.shape({}).isRequired,
	onCloseClick: PropTypes.func.isRequired,
};

VideoFeed.contextType = SocketContext;

export default injectIntl(withSocketFunctionsContext(VideoFeed));
